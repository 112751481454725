import cn from "clsx";
import React, { useState, useEffect } from "react";
import floatingToolBtnStyle from "@/common-components-src/js/floatingToolbar/components/FloatingToolBarBtn.module.scss";
import VectorUp  from "@/common-components-src/assets/icons/vector-up-icon.svg";

const JDIBackTopBtn = () => {
  const [isScrollTopBtnVisible, setIsScrollTopBtnVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    setIsScrollTopBtnVisible(scrollTop > 500);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div>
      {isScrollTopBtnVisible ? (
        <button className={cn(floatingToolBtnStyle.floatingBtn, floatingToolBtnStyle.icon)} onClick={scrollToTop}>
          <VectorUp className={floatingToolBtnStyle.hoverIcon}/>
        </button>
      ) : null}
    </div>
  );
};

export default JDIBackTopBtn;
