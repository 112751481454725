import cn from "clsx";
import React from "react";
import callAfterLogin from "@/common-components-src/js/utils/callAfterLogin";
import floatingToolBtnStyle from "@/common-components-src/js/floatingToolbar/components/FloatingToolBarBtn.module.scss";
import CustomerSupportIcon  from "@/common-components-src/assets/icons/customer-support-icon.svg";
import { sendClickTrack, EXPOSURE_RECORD } from "@/tracks/25630"
import { EXPCONFIG_FAST } from "@/common-components-src/const/point";

const JDIBackTopBtn = () => {
  const handleCustomerSupport = () => {
    console.log("djh open handleCustomerSupport");
    (window as any)?.zE("messenger", "open");
    sendClickTrack.Sidebar_Customer_Click();
  };

  return (
    <div
      exptag={"exp|" + EXPOSURE_RECORD.Sidebar_Customer_Expo}
      data-exptag-config={EXPCONFIG_FAST}
    >
      <button className={cn(floatingToolBtnStyle.floatingBtn, floatingToolBtnStyle.heightLarger)} onClick={handleCustomerSupport}>
        <div className={floatingToolBtnStyle.iconTextWrap}>
          <CustomerSupportIcon className={floatingToolBtnStyle.hoverIcon} />
        </div>
        <div>Customer service</div>
      </button>
    </div>
  );
};

export default JDIBackTopBtn;
