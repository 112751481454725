import newColorAxiosInstance from "../new-color-axiosInstance";
import {
  FooterMenuRequest,
  FetchResponse,
  SubmieEmailRequest,
} from "./footer.interface";

// 获取底部FAQ入口菜单
export const fetchFooterMenu = (requestData: FooterMenuRequest): Promise<FetchResponse> => {
  return newColorAxiosInstance
    .post<FetchResponse>(`user_help_getQuestionList`, undefined, {
      params: requestData,
    })
    .then((response: any) => response.data)
    .catch((error: any) => error);
};

// 提交邮箱
export const fetchSubmitEmail = (requestData: SubmieEmailRequest): Promise<FetchResponse> => {
  return newColorAxiosInstance
    .post<FetchResponse>(`user_account_subscribe`, undefined, {
      params: requestData,
    })
    .then((response: any) => response.data)
    .catch((error: any) => error);
};