/* eslint-disable @typescript-eslint/no-explicit-any */

export interface AttrItemVo {
  title?: string;
  dim?: number;
  currentValue?: string;
  itemList?: AttrValueVo[];
}

// 属性值对象
export interface AttrValueVo {
  parentTitle?: string;
  key: string;
  label: string;
  serialNumber?: number;
  stockState?: number;
  hasStock?: boolean;
  imgUrl?: string;
  skuList?: string[];
  checked?: boolean;
  // 是否禁用
  disabled?: boolean;
}
import { DimensionButtonObject, ColorSizePart } from "@/common-components-src/api/cart/cart.interface";

function allLableAreChecked(checkedLabelMap: Record<string, string>) {
  return Object.values(checkedLabelMap).every((name) => {
    return name && name.length > 0;
  });
}
/**
 * 获取多个sku数据交集
 * 从[[sku1,sku2],[sku3,sku4],...]中取交集
 */
function getSkuIntersect(arrayList: string[][]): string[] {
  let interSectArray: string[] = [];
  (arrayList || []).forEach((subList: string[]) => {
    if (interSectArray.length === 0) {
      interSectArray = subList;
    } else {
      interSectArray = interSectArray.filter((sku) => {
        return subList.includes(sku);
      });
    }
  });
  return Array.from(new Set(interSectArray));
}

/**
 * 如果skuList和unionSkuList有交集，则可以勾选
 * @param skuList
 * @param otherSkuList
 */
function isDisabled(skuList: string[], otherSkuList: string[]): boolean {
  if (otherSkuList && otherSkuList.length === 0) {
    return false;
  }
  const intersectList = getSkuIntersect([skuList, otherSkuList]);
  return intersectList && intersectList.length === 0;
}

/**
 * 从buttons列表里面获取当前选择的属性值
 * @param buttons
 * @param skuId
 */
function getCheckedValueBySkuId(buttons: DimensionButtonObject[], skuId: string): string {
  const values = (buttons || []).filter((vo) => {
    return (
      vo &&
      vo["C-P#dimensionButton&dimensionButton"].skuList &&
      vo["C-P#dimensionButton&dimensionButton"].skuList.includes(skuId.toString())
    );
  });
  console.log("values 000", values, skuId);
  return values.length > 0 ? values[0]["C-P#dimensionButton&dimensionButton"].text || "" : "";
}

/**
 * 从buttons列表里面获取当前选择的属性值对应的skuList
 * @param buttons
 * @param skuId
 */
function getCheckedSkuListBySkuId(buttons: DimensionButtonObject[], skuId: string): string[] {
  const values = (buttons || []).filter((vo) => {
    return (
      vo &&
      vo["C-P#dimensionButton&dimensionButton"].skuList &&
      vo["C-P#dimensionButton&dimensionButton"].skuList.includes(skuId.toString())
    );
  });
  return values.length > 0 ? values[0]["C-P#dimensionButton&dimensionButton"].skuList || [] : [];
}

/**
 * 转换itemList
 * @param buttons
 * @param otherSkuList
 * @returns {any[]}
 */
function getItemList(
  parent: ColorSizePart,
  buttons: DimensionButtonObject[],
  otherSkuList: string[],
): any {
  return (buttons || []).map((vo) => {
    return {
      ...vo,
      hasStock: String(vo["C-P#dimensionButton&dimensionButton"].stockState) !== "34",
      parentTitle: parent["C-P#dimension&dimension"].dimTitle,
      key: vo["C-P#dimensionButton&dimensionButton"].text,
      label: vo["C-P#dimensionButton&dimensionButton"].text,
      imgUrl: vo["C-P#dimensionButton&dimensionButton"].imgUrl || "",
      disabled: isDisabled(vo["C-P#dimensionButton&dimensionButton"].skuList || [], otherSkuList),
      skuList: vo["C-P#dimensionButton&dimensionButton"].skuList || [],
      // checked: vo["C-P#dimensionButton&dimensionButton"].checked,
    };
  });
}

/**
 * 获取其他维度属性勾选的skuList交集
 * @param {*} title
 * @param {*} checkedSkuListMap
 */
function getOtherDimCheckSkus(
  title: string,
  checkedSkuListMap: Record<string, string[]>,
): string[] {
  const otherSkuList: string[][] = [];
  Object.keys(checkedSkuListMap).forEach((k) => {
    if (title !== k) {
      checkedSkuListMap[k] && otherSkuList.push(checkedSkuListMap[k]);
    }
  });
  // 如果只有一个维度有数据，则直接返回这维，如果大于1维有数据，获取交集
  const hasDataList = (otherSkuList || []).filter((list) => {
    return list && list.length > 0;
  });
  if (hasDataList && hasDataList.length === 1) {
    return hasDataList[0];
  }
  return getSkuIntersect(otherSkuList);
}

export const AttrUtils = {
  /**
   * 初始化属性列表,主要根据skuId，计算出某个属性当前选中哪个属性值，以及某个属性值属是否禁用标识
   * @param colorSizePartList
   * @param skuId
   */
  initAttrListBySkuId: (colorSizePartList: ColorSizePart[], skuId: string): AttrItemVo[] => {
    const { checkedLabelMap, checkedSkuListMap } = AttrUtils.getAttrSelectMap(
      colorSizePartList,
      skuId,
    );
    console.log("checkedLabelMap", checkedLabelMap, checkedSkuListMap);
    return AttrUtils.convertAttrList(colorSizePartList, checkedLabelMap, checkedSkuListMap);
  },

  /**
   * 获取属性对应的勾选值map
   * attrCheckLabelMap: {attr1: checkedValue1;  attr2: checkedValue2; ...}
   * attrCheckLabelList: [checkedValue1, checkedValue2 ...] // 解决乱序问题
   * attrCheckSkuListMap: {attr1: [sku1,sku2,sku3];  attr2: [sku2,sku4]; ...}
   * @param colorSizePartList
   * @param skuId
   */
  getAttrSelectMap: (colorSizePartList: ColorSizePart[], skuId: string): any => {
    const attrCheckLabelMap: unknown = {};
    const attrCheckLabelList: string[] = [];
    const attrCheckSkuListMap: any = {};
    (colorSizePartList || []).forEach((dimInfo) => {
      const checkLabel = getCheckedValueBySkuId(dimInfo.buttonPartList || [], skuId);
      console.log("checkLabel 123", dimInfo, checkLabel);
      attrCheckLabelList.push(checkLabel);
      if (
        dimInfo["C-P#dimension&dimension"].dimTitle &&
        dimInfo.buttonPartList &&
        dimInfo.buttonPartList.length
      ) {
        (attrCheckLabelMap as any)[dimInfo["C-P#dimension&dimension"].dimTitle] = checkLabel;
        (attrCheckSkuListMap as any)[dimInfo["C-P#dimension&dimension"].dimTitle] =
          getCheckedSkuListBySkuId(dimInfo.buttonPartList, skuId);
      }
    });
    return {
      checkedLabelMap: attrCheckLabelMap,
      attrCheckLabelList: attrCheckLabelList,
      checkedSkuListMap: attrCheckSkuListMap,
    };
  },

  /**
   * 转换属性组
   * @param [*] colorSizePartList
   * @param {*} checkedAttrMap
   * @param {*} checkedSkuListMap
   */
  convertAttrList: (
    colorSizePartList: ColorSizePart[],
    checkedAttrMap: Record<string, string>,
    checkedSkuListMap: Record<string, string[]>,
  ) => {
    return (colorSizePartList || []).map((dimInfo: ColorSizePart) => {
      console.log(
        "213212",
        dimInfo["C-P#dimension&dimension"].dimTitle
          ? (checkedAttrMap as any)[dimInfo["C-P#dimension&dimension"].dimTitle]
          : "",
        dimInfo,
      );
      return {
        title: dimInfo["C-P#dimension&dimension"].dimTitle,
        dim: dimInfo["C-P#dimension&dimension"].dimensionNumber,
        currentValue: dimInfo["C-P#dimension&dimension"].dimTitle
          ? (checkedAttrMap as any)[dimInfo["C-P#dimension&dimension"].dimTitle]
          : "",
        itemList: dimInfo["C-P#dimension&dimension"].dimTitle
          ? getItemList(
              dimInfo,
              dimInfo.buttonPartList || [],
              getOtherDimCheckSkus(dimInfo["C-P#dimension&dimension"].dimTitle, checkedSkuListMap),
            )
          : [],
      };
    });
  },
  onAttrChecked: (
    currentItem: AttrValueVo,
    colorSizePartList: ColorSizePart[],
    curSkuId: string,
    // checkedLabelMap: Record<string, string>,
    // checkedSkuListMap: Record<string, string[]>,
  ) => {
    const result: {
      attrList: {
        title: string | undefined;
        dim: number | undefined;
        currentValue: any;
        itemList: Array<AttrValueVo>;
      }[];
      skuId: string;
    } = {
      attrList: [],
      skuId: "",
    };
    const { checkedLabelMap, checkedSkuListMap } = AttrUtils.getAttrSelectMap(
      colorSizePartList,
      curSkuId,
    );
    if (currentItem.parentTitle) {
      if (currentItem.checked) {
        checkedLabelMap[currentItem.parentTitle] = currentItem.key;
        checkedSkuListMap[currentItem.parentTitle] = currentItem.skuList || [];
      } else {
        checkedLabelMap[currentItem.parentTitle] = "";
        checkedSkuListMap[currentItem.parentTitle] = [];
      }
    }

    result.attrList = AttrUtils.convertAttrList(
      colorSizePartList,
      checkedLabelMap,
      checkedSkuListMap,
    );

    // 如果属性切换定位到一个确定的SKU/所有的属性都勾选了，则触发change
    if (currentItem.checked && allLableAreChecked(checkedLabelMap)) {
      const checkSkus = AttrUtils.getCheckSkusIntersect(checkedSkuListMap);
      if (checkSkus && checkSkus.length === 1) {
        result.skuId = checkSkus[0];
      }
    }
    return result;
  },

  /**
   * 获取所有已选属性支持的sku交集
   * @param {*} title
   * @param {*} checkedSkuListMap
   */
  getCheckSkusIntersect: (checkedSkuListMap: Record<string, string[]>): string[] => {
    const otherSkuList: string[][] = [];
    Object.keys(checkedSkuListMap).forEach((k) => {
      checkedSkuListMap[k] && otherSkuList.push(checkedSkuListMap[k]);
    });
    return getSkuIntersect(otherSkuList);
  },

  /**
   * 获取多个sku数据交集
   * 从[[sku1,sku2],[sku3,sku4],...]中取交集
   */
  getSkuIntersect: getSkuIntersect,
};
