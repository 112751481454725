import style from './style.module.scss'
import { imageUtil } from '@/common-components-src/js/utils/imageUtil'
import JDIPrice from '@/common-components-src/js/price'
import AddCart  from "@/common-components-src/assets/icons/AddCart.svg"
import Star  from "@/common-components-src/assets/icons/Star.svg"

import { formatStyle, imgTipsStyle, comparePrice } from '../../common/utils'
import { formatProductLink } from '@/common-components-src/js/utils/formatProductLink'

import type { productData_2 } from '../../index.interface'

interface PropsType {
  productData: productData_2
}

export default function ProductCard_2({productData}:PropsType) {

  //点击商卡事件
  const handleProductClick = (e: any) => {
    // 检查 onClickCart 是否存在并且是一个函数
    if (onClickProduct && typeof onClickProduct === 'function') {
      onClickProduct(productData);
      e.preventDefault()
    }
  }

  //点击购物车事件
  const handleCartClick = (productData: productData_2) => {
    // 检查 onClickCart 是否存在并且是一个函数
    if (onClickCart && typeof onClickCart === 'function') {
      onClickCart(productData);
    }
  };

  let {
    width,
    height,
    containerStyle,
    skuName,
    skuNameStyle,
    skuImgKeyParams,
    skuImg,
    skuImgStyle,
    skuId,
    realPrice,
    realPriceJDIPriceParams,
    originalPrice,
    originalPriceJDIPriceParams,
    currency,
    shouldShowScore,
    score,
    imgTips,
    scoreRightTips,
    shouldShowCart,
    onClickCart,
    jumpTarget,
    onClickProduct,
    saleTipesDom,
    productLabelDom,
    marketingArray
  } = productData;

  containerStyle = Object.assign({}, containerStyle, 
    { 
      width: width && formatStyle(width), 
      height: height && formatStyle(height) 
    })

  return (
    <div className={style.UK_product_card}
      style={containerStyle}
      key={skuId}>
      <a href={formatProductLink(skuId, skuName)} target={jumpTarget}
        onClick={(e) => handleProductClick(e)}>
        {/* 商品图片 */}
        <div className={style.UK_image_wrapper} >
          <img className={style.UK_skuImg}
            src={skuImgKeyParams ? imageUtil.concatImgUrlWithoutForceHttps(
              skuImgKeyParams.imgUrlKey,
              skuImgKeyParams?.width,
              skuImgKeyParams?.height,
              skuImgKeyParams?.quality,
              skuImgKeyParams?.domains,
            ) : skuImg}
            style={skuImgStyle} alt="" />
          <div className={style.UK_mask}></div>
          {
            imgTips && imgTips.tipDom ?
              <span className={style.UK_imgTips} style={imgTipsStyle(imgTips.position)}>
                {
                  imgTips.tipDom
                }
              </span> : null
          }
        </div>
        {/* 商品名称 */}
        <div className={style.UK_name_wrapper} style={skuNameStyle}>
          <div className={style.UK_name_box}>
            {/* 商品标签 */}
            {
              productLabelDom ?
                <span className={style.UK_productLabelDom}>
                  {
                    productLabelDom
                  }
                </span> : null
            }
            <span className={style.UK_name}>{skuName}</span>
          </div>
        </div>
      </a>

      {/* 商品价格+ 营销标签 + 购物车 */}
      <div className={style.UK_price_and_market_wrapper} >
        <div className={style.UK_left}>
          {/* 优惠价格 */}
          <div className={style.UK_realPrice} style={{marginRight: shouldShowCart ? '48px' : '0px'}}>
            <JDIPrice
              {...realPriceJDIPriceParams}
              symbol={currency}
              price={realPrice}
              uiType={1}
              containerStyle={{ marginRight: '8px' }}
            />
          </div>
          {/* 划线价 */}
          <div className={style.UK_originalPrice} style={{marginRight: shouldShowCart ? '48px' : '0px'}}>
            {originalPrice && comparePrice(originalPrice, realPrice) && (
              <JDIPrice
                {...originalPriceJDIPriceParams}
                symbol={currency}
                containerStyle={{ lineHeight: '16px' }}
                price={originalPrice}
                uiType={2}
                lineThrough
              />
            )}
            {
              saleTipesDom ?
                <span className={style.UK_saleTipesDom}>
                  {
                    saleTipesDom
                  }
                </span> : null

            }
          </div>
          {/* 评分、库存、采销推荐 */}
          <div className={style.UK_score}>
            {
              shouldShowScore ?
                <span className={style.UK_star}>
                  <Star className={style.UK_starIcon}></Star>
                  {score}
                </span> : null
            }
            {
              scoreRightTips ? <span className={style.UK_tips}>{scoreRightTips}</span> : null
            }
          </div>
          {/* 活动 */}
          {
            marketingArray && marketingArray.length > 0 ? marketingArray.map((rowTips, i) => {
              return (
                <div className={style.UK_activityRow} key={i}>
                  {
                    rowTips && rowTips.length > 0 ? rowTips.map((item: any, ii: number) => {
                      return (
                        <span className={`${style.UK_activity} ${ii === 1 && style.UK_Divider}`} key={ii}>
                          {item}
                        </span>
                      )
                    }) : null
                  }
                </div>
              )
            }) : null
          }
        </div>
        {
          shouldShowCart ?
            <div className={style.UK_right}>
              <AddCart className={style.UK_cart} onClick={() => handleCartClick(productData)}></AddCart>
            </div> : null
        }
      </div>

    </div>
  )
}
