/* eslint-disable @next/next/no-img-element */
import "../index.scss"
import "./messageItem.scss"
import MenuOpts from "./menuOpts";
import LineEllipsisSpan from "./ellipsSpan";
import WhiteIcon from "../assets/white-icon.png"
import { imageUtil } from "@/common-components-src/js/utils/imageUtil";
import UKToast from '@/common-components-src/components/UKToast/index';
import PromotionWhiteIcon from "../assets/promotion-white.png"
import { MessageItem } from "@/common-components-src/api/message/message.interface";
import { fetchOrderDetail, fetchUserReviewDetail } from "@/common-components-src/api/message/message.api"
import { MessageTypeEnum, MessageSubTypeEnum } from "@/common-components-src/api/message/message.interface";
import PriceDropIcon from "../assets/pricedrop-logo.svg";
import IntlDialog from "@/common-components-src/js/reviews/dialog";
import { useState } from "react";
import { ReviewDetailItem } from "@/common-components-src/api/reviews/review.interface";
import ReveiwDetailList from "@/common-components-src/js/reviews/reviewDetailList";
import { sendClickTrack, EXPOSURE_RECORD } from "@/tracks/25630"
import { EXPCONFIG_FAST } from "@/common-components-src/const/point";

interface ItemProps {
  params: any;
  type: number;
  item: MessageItem;
  onDelete?: (msgId: string) => void;
}

interface PointProps {
  params: any;
  className: string;
  children: React.ReactNode;
  onClickItem: (event: any) => void;
}

function PointWrapper({ className, params, children, onClickItem }: PointProps){

  return (
    <div
      className={className}
      onClick={onClickItem}
      exptag={"exp|" + EXPOSURE_RECORD.Message_MsgDetail_MsgExpo}
      data-exptag-config={EXPCONFIG_FAST}
      data-exptag-json-param={JSON.stringify(params)}
    >
      {children}
    </div>
  );
}

export function CommonItem({data}: {data: ItemProps}){

  const { item, params, type, onDelete } = data;
  const imgPath = item?.extraAttribute?.sImgPath
  const msgType = item?.extraAttribute?.msgSubType;
  const [skuID, setSkuID] = useState<string>(""); // 评价详情的skuId;
  const [isOpenReviewDetail, setIsOpenReviewDetail] = useState<boolean>(false); // 打开评价详情弹框
  const [reviewDetailData, setReviewDetailData] = useState<ReviewDetailItem | null>(null); // 评价详情数据

  const onDeleteItem = () => {
    onDelete?.(item?.msgId);
    sendClickTrack.Message_MsgDetail_DeleteMsg(params);
  };

  const onClickItem = (event: any) => {
    if(isOpenReviewDetail){
      return
    }
    if (!event.target.closest(".MuiPopover-root")) {
      handleClick()
    }
  }

  const handleClick = () => {
    sendClickTrack.Message_MsgDetail_Msg(params);
    if(msgType){
      switch(msgType){
        case MessageSubTypeEnum.ORDER_PAY_COMPLETED:
        case MessageSubTypeEnum.ORDER_SHIPPED:
        case MessageSubTypeEnum.ORDER_COMPLETE:
        case MessageSubTypeEnum.ORDER_CANCEL:
        case MessageSubTypeEnum.ORDER_CANCEL_FAILED:
        case MessageSubTypeEnum.PRESALE_CANCEL_SUCCESS:
        case MessageSubTypeEnum.AFTERSALE_CANCEL_SUCCESS:
        case MessageSubTypeEnum.AFTERSALE_APPLY_SUCCESS:
        case MessageSubTypeEnum.AFTERSALE_APPLY_FAIL:
          gotoOrderDetail();
          break;
        case MessageSubTypeEnum.REVIRE:
          gotoReviewDetail();
          break;
        default:
          gotoLandpage();
          break;
      }
    }
    else{
      switch(type){
        case MessageTypeEnum.orders:
        case MessageTypeEnum.aftersale:
          gotoOrderDetail();
          break;
        case MessageTypeEnum.mentions:
          gotoReviewDetail();
          break;
        default:
          gotoLandpage();
          break;
      }
    }
  }

  const gotoOrderDetail = async () => {
    const orderId = item?.extraAttribute?.orderId || 0;
    const requestData = { orderId: String(orderId) };
    const orderDetailData = await fetchOrderDetail(requestData);
    if (orderDetailData?.orderStatusDTO?.recycle) {
      UKToast.show({
        content: "The order has been deleted and is no longer available for viewing.",
        icon: "warning",
      });
    } else {
      location.href = "/profile/my-orders/order-details?orderId=" + orderId.toString();
    }
  }

  const gotoReviewDetail = async () => {
    const attribute = item?.extraAttribute;
    if (attribute && !!attribute.skuId && !!attribute.commentId) {
      const params = {
        skuId: attribute.skuId,
        commentId: attribute.commentId,
        parentReplyId: attribute.parentReplyId || 0,
      }
      const res = await fetchUserReviewDetail(params);
      if (res && res.code === "200") {
        setSkuID(params.skuId);
        setReviewDetailData(res.data || null);
        setIsOpenReviewDetail(true);
      }
      else{
        UKToast.show({
          content: res?.msg,
          icon: "warning",
        });
      }
    }
  }

  const gotoLandpage = () => {
    if(item && item.extraAttribute && item.extraAttribute.landPageUrlForM){
      window.location.href = item.extraAttribute.landPageUrlForM;
    }
  }

  return (
    <PointWrapper className="common cursor" key={type} onClickItem={onClickItem} params={params} >
      <div className="date">{item.formatTime}</div>
      <div className="frame">
        <div className="header">
          <div className="title">{item.title}</div>
          <MenuOpts onDelete={onDeleteItem} />
        </div>
        <div className="content">
          { imgPath && (
            <img
              src={imageUtil.concatImgUrlWithoutForceHttps(imgPath)}
              alt={type.toString()}
              onError={(e: any) => {
                e.target.src = WhiteIcon
              }}
            />
          )}
          {/* <LineEllipsisSpan content={item.content} endStr=" To view>>"/> */}
          <span>{item.content}</span>
        </div>
      </div>
      <IntlDialog
        width={800}
        title="Review"
        open={isOpenReviewDetail}
        onClose={() => setIsOpenReviewDetail(false)}
        sx={{zIndex: 2300}}
        content={
          <ReveiwDetailList
            skuId={skuID}
            lists={reviewDetailData ? [reviewDetailData] : []}
            onClose={() => setIsOpenReviewDetail(false)}
          />
        }
      />
    </PointWrapper>
  );
};

export function PriceDropItem({data}: {data: ItemProps}){

  const { item, params, type, onDelete } = data;
  const { sImgPath, icon, tipHtml, skuId, skuName }: any = item?.extraAttribute;

  const onDeleteItem = () => {
    onDelete?.(item?.msgId);
    sendClickTrack.Message_MsgDetail_DeleteMsg(params);
  };

  const onClickItem = (event: any) => {
    if (!event.target.closest(".MuiPopover-root")) {
      location.href = skuName ? `/dp/${skuName}/${skuId}` : `/dp/${skuId}`;
      sendClickTrack.Message_MsgDetail_Msg(params);
    }
  }

  return (
    <PointWrapper className="pricedrop cursor" key={type} onClickItem={onClickItem} params={params}>
      <div className="date">{item.formatTime}</div>
      <div className="frame">
        <div className="header">
          <div className="title">{item.title}</div>
          <MenuOpts onDelete={onDeleteItem} />
        </div>
        <div className="content">
          { sImgPath && (
            <img
              src={imageUtil.concatImgUrlWithoutForceHttps(sImgPath)}
              alt={type.toString()}
              onError={(e: any) => {
                e.target.src = WhiteIcon
              }}
            />
          )}
          <div className="content-frame">
            <div className="sku-name">{item.content}</div>
            <div className="price-drop">
              <img
                alt={"pricedrop"+type.toString()}
                src={icon ? imageUtil.concatImgUrlWithoutForceHttps(icon) : PriceDropIcon}
              />
              <span dangerouslySetInnerHTML={{__html: tipHtml}}/>
            </div>
          </div>
        </div>
      </div>
    </PointWrapper>
  );
};

export function CampaignItem({data}: {data: ItemProps}){

  const { item, params, type, onDelete } = data;

  const onDeleteItem = () => {
    onDelete?.(item?.msgId);
    sendClickTrack.Message_MsgDetail_DeleteMsg(params);
  };

  const onClickItem = (event: any) => {
    if (!event.target.closest(".MuiPopover-root")) {
      if(item?.extraAttribute?.landPageUrlForM){
        window.location.href = item.extraAttribute.landPageUrlForM;
      }
      sendClickTrack.Message_MsgDetail_Msg(params);
    }
  }

  return (
    <PointWrapper className="promotion cursor" key={type} onClickItem={onClickItem} params={params}>
      <div className="date">{item.formatTime}</div>
      <div className="content">
        <div className="promotion-title">
          <span>{item.title}</span>
          <MenuOpts onDelete={onDeleteItem} />
        </div>
        { !!item.content && <em>{item.content}</em> }
        <img
          alt={type.toString()}
          onError={(e: any) => {
            e.target.src = PromotionWhiteIcon
          }}
          src={imageUtil.concatImgUrlWithoutForceHttps(item?.extraAttribute?.sImgPath as string)}
        />
      </div>
    </PointWrapper>
  );
};
