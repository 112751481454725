import JDILogin from "@/common-components-src/js/newlogin/index.newlogin";

/**
 登录后执行回调函数，如未登录，则自动打开登录框，登录后继续执行回调函数
 用法:
 callAfterLogin(() => {
   // 函数内部编写跳转订单页 或者 其他需要登录的页面或者必须依赖登录后的操作
 }, props?:{isRefresh : boolean})
 isRefresh 传入该参数如果为false 登陆后不会强制刷新页面
*/
export default async function callAfterLogin(callbackFunc: () => void, props?: {
  isRefresh: boolean
}) {
  const userInfo = await JDILogin.getInstance().getUserInfo();
  if (userInfo.isLoggedIn) {
    typeof callbackFunc === 'function' && callbackFunc();
  } else {
    JDILogin.getInstance().openLoginDialog((res: any) => {
      if (res && res.login_type_name === "login_success") {
        typeof callbackFunc === 'function' && callbackFunc();
      }
    },{ isRefresh: props ? props.isRefresh : true });
  }
}
