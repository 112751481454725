// "use client";
import { ReactNode } from 'react';
import JDIPrice from '@/common-components-src/js/price'
import { imageUtil } from '@/common-components-src/js/utils/imageUtil'
import style from '@/common-components-src/js/productCard/style.module.scss'
import AddCart  from "@/common-components-src/assets/icons/AddCart.svg"
import Star  from "@/common-components-src/assets/icons/Star.svg"

import { mockProps } from './mock'

// console.log('Imported styles:', style);
interface imgTipsData {
  position: string //顶部标签定位位置el: topLeft topRight bottomLeft bottomRight
  tipDom: ReactNode //标签内容：只提供一个插槽位置，具体标签需要渲染什么东西需要直接传进来
}

interface bottomTipsData {
  color?: string  // 展示文案颜色
  text: string // 展示文案
}

interface JDIPriceParams {
  symbol: string;
  price: string;
  symbolStyle?: React.CSSProperties;
  integerStyle?: React.CSSProperties;
  decimalPointStyle?: React.CSSProperties;
  decimalPlaceStyle?: React.CSSProperties;
  lineThrough?: boolean;
  containerStyle?: React.CSSProperties;
  uiType?: number; // 内置ui类型
}

interface productData {
  width?: number //宽度 单位是px(默认240),
  height?: number //宽度 单位是px(默认240),
  containerStyle?: React.CSSProperties //组件整体最外层样式
  skuName: string //商品名称
  skuNameStyle?: React.CSSProperties //商品名称样式
  titleTipsDom?: ReactNode //标题标签
  skuImgKey?: string //商品图片key
  skuImg?: string //商品图片url
  skuImgStyle?: React.CSSProperties //商品图片样式
  skuId: string //skuId
  realPrice: string //商品优惠价格
  realPriceJDIPriceParams?: JDIPriceParams //商品优惠价格样式
  originalPrice?: string //商品划线价格
  originalPriceJDIPriceParams?: JDIPriceParams //划线价样式
  currency?: string //货币单位
  imgTips?: imgTipsData //顶部标签,
  shouldShowScore?: boolean //是否显示评分 
  score?: string //商品评分 (如果显示评分，但没有传评分时则默认配置4.9分)
  bottomTips?: bottomTipsData //底部标签
  shouldShowCart?: boolean //是否显示加入购物车按钮
  onClickCart?: (skuId: string) => void //点击加入购物车按钮
  jumpTarget?: string //跳转方式
  onClickProduct?: (props: any) => void //点击商卡事件
}

interface PropsType {
  productData: productData
}

export default function ProductCard(props: PropsType) {
  const { productData } = props
  //处理传入的宽高
  const formatStyle = (style: string | number): string => {
    if (!style) {
      return ''
    }
    if (typeof style === 'number') {
      return `${style}px`;
    }

    if (typeof style === 'string') {
      // 如果已经是有效的 CSS 宽度格式，直接返回
      if (style.endsWith('px') || style.endsWith('%') || style === 'auto') {
        return style;
      }

      // 尝试将字符串转换为数字
      const numstyle = parseFloat(style);
      if (!isNaN(numstyle)) {
        return `${numstyle}px`;
      }
    }

    // 如果无法处理，返回默认值或抛出错误
    throw new Error('Invalid width format');
  }

  let {
    width,
    height,
    containerStyle,
    skuName,
    skuNameStyle,
    skuImgKey,
    skuImg,
    skuImgStyle,
    skuId,
    realPrice,
    realPriceJDIPriceParams,
    originalPrice,
    originalPriceJDIPriceParams,
    currency,
    shouldShowScore,
    score,
    imgTips,
    bottomTips,
    shouldShowCart,
    onClickCart,
    jumpTarget,
    onClickProduct,
    titleTipsDom,
  } = productData;

  containerStyle = Object.assign({}, containerStyle, { width: formatStyle(width), height: formatStyle(height) })

  //处理标签样式
  const imgTipsStyle = (position: string) => {
    let style = {}
    switch (position) {
      case 'topLeft': style = { top: 0, left: 0 }
        break;
      case 'topRight': style = { top: 0, right: 0 }
        break;
      case 'bottomLeft': style = { bottom: 0, left: 0 }
        break;
      case 'bottomRight': style = { bottom: 0, right: 0 }
        break;
    }
    return style
  };

  //处理商卡跳转商详链接
  const formatProductLink = (skuId: string, skuName?: string, inner: boolean = true, limit = 4) => {
    (skuName = skuName?.split(" ")?.slice(0, limit)?.join('-'))
    return skuName ? `/dp/${skuName}/${skuId}` : `/dp/${skuId}`
  }

  //判断如果划线价小于等于优惠价格则不展示
  const comparePrice = (originalPrice: string, realPrice: string): boolean => {
    return Number(realPrice) < Number(originalPrice);
  };

  const handleCartClick = (skuId: string) => {
    // 检查 onClickCart 是否存在并且是一个函数
    if (onClickCart && typeof onClickCart === 'function') {
      onClickCart(skuId);
    }
  };

  const handleProductClick = (e: any) => {
    // 检查 onClickCart 是否存在并且是一个函数
    if (onClickProduct && typeof onClickProduct === 'function') {
      onClickProduct(productData);
      e.preventDefault()
    }
  }

  return (
    <div className={style.UK_product_card}
      style={containerStyle}
      key={skuId}>
      <a href={formatProductLink(skuId, skuName)} target={jumpTarget}
        onClick={(e) => handleProductClick(e)}>
        {/* 商品图片 */}
        <div className={style.UK_image_wrapper} >
          <img className={style.UK_skuImg}
            src={skuImgKey ? imageUtil.concatImgUrlWithoutForceHttps(skuImgKey, width, width, 100) : skuImg}
            style={skuImgStyle} alt="" />
          <div className={style.UK_mask}></div>
          {
            imgTips && imgTips.tipDom ?
              <span className={style.UK_imgTips} style={imgTipsStyle(imgTips.position)}>
                {
                  imgTips.tipDom
                }
              </span> : null
          }
        </div>
        {/* 商品名称 */}
        <div className={style.UK_name_wrapper} style={skuNameStyle}>
          <div className={style.UK_name_box}>
            {
              titleTipsDom ?
                <span className={style.UK_titleTipsDom}>
                  {
                    titleTipsDom
                  }
                </span> : null

            }
            <span className={style.UK_name}>{skuName}</span>
          </div>
        </div>
      </a>

      {/* 商品价格+ 营销标签 + 购物车 */}
      <div className={style.UK_price_and_market_wrapper}>
        <div className={style.UK_left}>
          <div className={style.UK_priceFloor}>
            <div className={style.UK_realPrice}>
              <JDIPrice
                {...realPriceJDIPriceParams}
                symbol={currency}
                price={realPrice}
                uiType={1}
                containerStyle={{ marginRight: '8px' }}
              />
              {originalPrice && comparePrice(originalPrice, realPrice) && (
                <JDIPrice
                  {...originalPriceJDIPriceParams}
                  symbol={currency}
                  price={originalPrice}
                  uiType={2}
                  lineThrough
                />
              )}
            </div>
            {/* <div className={style.UK_originalPrice}>
             
            </div> */}
          </div>
        </div>
        {
          shouldShowCart ?
            <div className={style.UK_right}>
              <AddCart className={style.UK_cart} onClick={() => handleCartClick(skuId)}></AddCart>
            </div> : null
        }
      </div>

    </div>
  )
}
