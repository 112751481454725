import { v4 } from "uuid";
import cookieUtil from "./cookieUtils";
import { VISITOR_UUID, CURRENCY, LANG, COUNTRY, TIMEZONE } from "@/utils/const";

/**
 * 从cookie中获取用户/访客uuid
 */
export function getUserUUIDFromCookie() {
  let visitorUuid = cookieUtil.getCookie(VISITOR_UUID);
  if (!visitorUuid) {
    visitorUuid = v4();
    cookieUtil.setCookie(
      VISITOR_UUID,
      visitorUuid,
    );
  }
  return visitorUuid;
}

export function getBaseCookie() {
  const _currency = cookieUtil.getCookie(CURRENCY);
  const _lang = cookieUtil.getCookie(LANG);
  const _country = cookieUtil.getCookie(COUNTRY);
  const _timezone = cookieUtil.getCookie(TIMEZONE);
  // 获取系统时区方法，目前未启用
  function getUTCTimezoneOffset() {
    const now = new Date();
    const offset = now.getTimezoneOffset();
    const sign = offset > 0 ? "-" : "+";
    const hours = Math.floor(Math.abs(offset) / 60);
    return `UTC${sign}${String(hours)}`;
  }

  // console.log(getUTCTimezoneOffset());

  if (!_currency) {
    cookieUtil.setCookie(
      CURRENCY,
      "GBP",
    );
  }
  if (!_lang) {
    cookieUtil.setCookie(
      LANG,
      "en_GB",
    );
  }
  if (!_country) {
    cookieUtil.setCookie(
      COUNTRY,
      "UK",
    );
  }
  if (!_timezone) {
    cookieUtil.setCookie(
      TIMEZONE,
      "UTC+0",
    );
  }
}
