import JDICurrencySymbol from "../currencySymbol";
import { formatPriceWithoutSymbol } from "../utils/priceUtils";

interface props {
  symbol: string;
  price: string;
  symbolStyle?: React.CSSProperties;
  integerStyle?: React.CSSProperties;
  decimalPointStyle?: React.CSSProperties;
  decimalPlaceStyle?: React.CSSProperties;
  lineThrough?: boolean;
  containerStyle?: React.CSSProperties;
  uiType?: number; // 内置ui类型
}

const JDIPrice = (props: props) => {
  let {
    price,
    symbol,
    containerStyle = {},
    symbolStyle = {},
    integerStyle = {},
    decimalPlaceStyle = {},
    decimalPointStyle = {},
    lineThrough = false,
    uiType = 1,
  } = props;

  if (price === '-1.00') {
    return null
  }

  containerStyle = Object.assign({ fontFamily: "JDZhengHT-EN" }, containerStyle);

  if (lineThrough) {
    Object.assign(containerStyle, { textDecoration: "line-through" });
  }
  if (uiType === 1) {
    containerStyle = Object.assign(
      {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "32px",
        color: "#1a1a1a",
      },
      containerStyle,
    );
    symbolStyle = Object.assign({}, symbolStyle);
    integerStyle = Object.assign({ fontSize: "28px" }, integerStyle);
    decimalPlaceStyle = Object.assign({}, decimalPlaceStyle);
    decimalPointStyle = Object.assign({}, decimalPointStyle);
  } else if (uiType === 2) {
    containerStyle = Object.assign({ height: "28px", lineHeight: "28px", color: "#888b94" }, containerStyle);
    symbolStyle = Object.assign({}, symbolStyle);
    integerStyle = Object.assign({}, integerStyle);
    decimalPlaceStyle = Object.assign({}, decimalPlaceStyle);
    decimalPointStyle = Object.assign({}, decimalPointStyle);
  }

  price = formatPriceWithoutSymbol(parseFloat(price));
  let integerPart, decimalPlace;
  if (price.includes(".")) {
    [integerPart, decimalPlace] = price.split(".");
  } else {
    integerPart = price;
    decimalPlace = "00";
  }

  return (
    price && (
      <span style={containerStyle}>
        <JDICurrencySymbol symbol={symbol} style={symbolStyle} />
        <span style={integerStyle}>{integerPart}</span>
        <span style={decimalPointStyle}>.</span>
        <span style={decimalPlaceStyle}>{decimalPlace}</span>
      </span>
    )
  );
};

export default JDIPrice;
