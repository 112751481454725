import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import FooterData from "@/common-components-src/const/cookieRoot";
import { Switch } from "@mui/material";
import { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import cookieUtils from "@/utils/cookieUtils";
import styles from "./index.module.scss";

interface UseCookie {
  [key: string]: any; 
}

const SettingList = forwardRef((props, ref) => {
  const { CookiesSettingList, defaultUserCookie } = FooterData;
  const [useCookie, setUseCookie] = useState<UseCookie>(defaultUserCookie);

  useEffect(() => {
    const cookie_consent = cookieUtils.getCookie("cookie_consent") || JSON.stringify(defaultUserCookie);
    setUseCookie(JSON.parse(cookie_consent));
  }, []);

  const handleSwitchChange = (checked: boolean, item: any) => {
    setUseCookie({ ...useCookie, ...{ [item.key]: Number(checked) } });
  };

  useImperativeHandle(ref, () => {
    return {
      getUseCookie: () => {
        return useCookie;
      },
    };
  });

  return (
    <div className={styles.cookiesSettingWrapper}>
      {CookiesSettingList.map((item) => (
        <div key={item.key}>
          <div className={styles.title}>
            {item.title}
            {item.isControl ? (
              <CustomerSwitch checked={Boolean(useCookie[item.key])} onChange={(e) => handleSwitchChange(e.target.checked, item)} />
            ) : (
              <span className={styles.status}>Always active</span>
            )}
          </div>
          <div className={styles.content}>{item.content}</div>
        </div>
      ))}
    </div>
  );
});

const CustomerSwitch = styled((props: SwitchProps) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
  width: "42px !important",
  height: "26px !important",
  padding: "0 !important",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#CC0C1C",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "#CC0C1C",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#CC0C1C",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#fff",
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));

export default SettingList;
