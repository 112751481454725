import React, { useState, useEffect, useRef } from "react";
import styles from "./footer2.module.scss";
import LangSVG from "@/common-components-src/assets/icons/lang.svg";
import CurrencySVG from "@/common-components-src/assets/icons/currency.svg";
import UKFlagSVG from "@/common-components-src/assets/icons/ukflag.svg";
import { Button, TextField } from "@mui/material";
import LogoSVG from "@/common-components-src/assets/icons/newLogoRed.svg";
import { fetchFooterMenu, fetchSubmitEmail } from "@/common-components-src/api/footer/footer.api";
import { SceneFacadeVO, FooterMenuVo } from "@/common-components-src/api/footer/footer.interface";
import BrandFacebook from "@/common-components-src/assets/icons/brand-facebook.svg";
import BrandInstagram from "@/common-components-src/assets/icons/brand-instagram.svg";
import BrandYoutube from "@/common-components-src/assets/icons/brand-youtube.svg";
import BrandTiktok from "@/common-components-src/assets/icons/brand-tiktok.svg";
import BrandLinkTree from "@/common-components-src/assets/icons/brand-linktree.svg";
import FooterData from "@/common-components-src/const/footer";
import UKToast from "@/common-components-src/components/UKToast/index.tsx";
import CookiesManage from "./cookiesManage/index.tsx";
import PersonalisedManage from "./personalisedManage/index.tsx";
import { EXPOSURE_RECORD, sendClickTrack } from '@/tracks/25421'

interface FooterVo {
  id: number;
  title: string;
  url?: string;
}

const JDIFooter2 = () => {
  const { AboutBasicList, LegalBasicList, ContactBasicList, Frequent_Ask_Questions, Instagram, Tiktok, Facebook, Youtube, Linktree, googleDownload, AppDownload  } = FooterData;
  const [aboutList, setAboutList] = useState<FooterVo[]>(AboutBasicList); // Get to know us
  const [legalList, setLegalList] = useState<FooterVo[]>(LegalBasicList); // Legal term & policies
  const [contactList, setContactList] = useState<FooterVo[]>(ContactBasicList); // Let us help you
  const [emailValue, setEmailValue] = useState<string>(""); // email
  const [error, setError] = useState<string>("");
  const cookiesManageRef = useRef<{ setIsOpen: (isOpen: boolean) => void }>();
  const personalisedManageRef = useRef<{ setIsOpen: (isOpen: boolean) => void }>();
  useEffect(() => {
    getFooterMenu();
  }, []);

  const getFooterMenu = async () => {
    try {
      const { data } = await fetchFooterMenu({ sceneList: ["About", "Legal", "Contact", "ProductDetailContact"] });
      const aboutRes: FooterMenuVo[] = [];
      const legalRes: FooterMenuVo[] = [];
      const contactRes: FooterMenuVo[] = [];

      if (data?.length) {
        data.forEach((item) => {
          if (item.scene === "About") {
            aboutRes.push(item);
          } else if (item.scene === "Legal") {
            legalRes.push(item);
          } else if (item.scene === "ProductDetailContact") {
            contactRes.push(item);
          }
        });
        if (aboutRes.length) {
          const sceneFacadeVOList = aboutRes[0].sceneFacadeVOList || [];
          const list = sceneFacadeVOList.map((item: SceneFacadeVO) => ({
            id: item.id,
            title: item.facadeName,
            url: `/discover/${getTitle(item.facadeName)}/${item.id}`,
          }));
          setAboutList(list.splice(0, 4));
        }
        if (legalRes.length) {
          const sceneFacadeVOList = legalRes[0].sceneFacadeVOList || [];
          const list = sceneFacadeVOList.map((item: SceneFacadeVO) => ({
            id: item.id,
            title: item.facadeName,
            url: `/help/${getTitle(item.facadeName)}/${item.id}`,
          }));
          setLegalList(list.splice(0, 4));
        }
        if (contactRes.length) {
          const list: any = contactRes
            .map((item) => {
              if (item.sceneFacadeVOList?.length) {
                return {
                  id: item.id,
                  title: `${item.categoryName} ${item.sceneFacadeVOList[0].facadeName}`,
                };
              }
              return null;
            })
            .filter((item) => item !== null);
          setContactList(list);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getTitle = (title: string) => {
    return title
      .toLowerCase()
      .replace(/[^\w-]+|_/g, "-") // 替换非字母数字、下划线和连字符为-
      .replace(/^-+|-+$/g, "") // 去除首尾的-
      .replace(/-+/g, "-"); // 确保连续-合并为一个
  };

  const handleSubmitEmail = async () => {
    sendClickTrack.Footer_Email_Subscription();
    if (!emailValue) {
      setError("Please input a valid email address.");
    } else if (!validateEmail(emailValue)) {
      setError("Please input a valid email address.");
    } else {
      const res = await fetchSubmitEmail({ email: emailValue });
      if (res.code === "200") {
        UKToast.show({
          icon: "success",
          content: "Submit successful",
        });
        setEmailValue("");
      } else {
        UKToast.show({
          icon: "error",
          content: res.message || "Submit failed",
        });
      }
    }
  };

  // 邮箱校验的正则表达式
  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // 处理输入框变化
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEmailValue(value);

    // 实时校验邮箱格式
    if (value && !validateEmail(value)) {
      setError("Please input a valid email address.");
    } else {
      setError("");
    }
  };

  const handleOpenPersonalised = () => {
    if (personalisedManageRef.current) {
      personalisedManageRef.current.setIsOpen(true);
    }
  };

  const handleOpenCookies = () => {
    if (cookiesManageRef.current) {
      cookiesManageRef.current.setIsOpen(true);
    }
  };

  return (
    <footer className={styles["jdi-footer"]}>
      <div className={styles["footer-top-wrap"]}>
        <div className={styles.w}>
          <div className={styles["footer-top"]}>
            {/* <div className={styles.logo} /> */}
            <div className={styles["left-box"]}>
              <LogoSVG color="#cc0c1c" width="102" height="28" />
              <span className={styles.slogan}>Don't just buy, Joybuy</span>
            </div>
            <div className={styles["international-wrap"]}>
              <div className={styles.item}>
                <div className={styles.ico}>
                  <LangSVG />
                </div>
                <div>English(UK)</div>
              </div>
              <div className={styles.item}>
                <div className={styles.ico}>
                  <CurrencySVG />
                </div>
                <div>GBP - Pounds</div>
              </div>
              <div className={styles.item}>
                <UKFlagSVG className={styles.ico} width={20} height={14} />
                <div>United Kingdom</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["footer-main-wrap"]}>
        <div className={styles.w}>
          <div className={styles["footer-main"]}>
            <div className={styles["left-container"]}>
              <div className={styles.vertical}>
                <div className={styles.title}>Get to know us</div>
                <ul className={styles.links}>
                  {aboutList.map((item) => (
                    <li key={item.id}>
                      <a href={item.url} target="_self">
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={styles.vertical}>
                <div className={styles.title}>Legal term & policies</div>
                <ul className={styles.links}>
                  {legalList.map((item) => (
                    <li key={item.id}>
                      <a href={item.url} target="_self">
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={styles.vertical}>
                <div className={styles.title}>Let us help you</div>
                <ul className={styles.links}>
                  <li>
                    <a href={Frequent_Ask_Questions} target="_self">
                      Frequent ask questions
                    </a>
                  </li>
                  {contactList.map((item) => (
                    <li key={item.id}>{item.title}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className={styles["right-container"]}>
              <div className={styles.title}>Subscribe</div>
              <div className={styles.email}
                exptag={"exp|" + EXPOSURE_RECORD.Footer_Email_Subscription_Expo}
                data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
              >
                <TextField
                  className={styles["email-input"]}
                  label="Your email address"
                  variant="outlined"
                  size="small"
                  autoComplete="subscribe-email"
                  value={emailValue}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:focus-within fieldset": {
                        borderColor: "#990915", // 当输入框获得焦点时边框颜色变为红色
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#990915",
                    },
                    "& .MuiFormHelperText-filled": {
                      backgroundColor: "#f5f6fa",
                      margin: 0,
                      padding: "4px 14px 0px",
                    },
                  }}
                  onChange={handleEmailChange}
                  error={!!error}
                  helperText={error}
                  inputProps={{ maxLength: 100 }}
                />
                <Button
                  size="small"
                  variant="contained"
                  disableElevation
                  sx={{
                    color: "#FFF",
                    height: "40px",
                    backgroundColor: "#000",
                    "&:hover": {
                      backgroundColor: "#333",
                    },
                    "&:active": {
                      backgroundColor: "#222",
                    },
                    textTransform: "none",
                  }}
                  onClick={handleSubmitEmail}
                >
                  Submit
                </Button>
              </div>
              <div className={styles.tips}>Sign up for our newsletter to receive special offers, promos and product launch information！</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["footer-ad-wrap"]}>
        <div className={styles.w}>
          <div className={styles["footer-ad"]}>
            <div className={styles.download}>
              <div className={styles.title}>Download Joybuy</div>
              <div className={styles.logo}>
                <a href={googleDownload} target="_self"
                  exptag={"exp|" + EXPOSURE_RECORD.Footer_AppStore_Expo}
                  data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
                  onClick={() => {
                    sendClickTrack.Footer_AppStore({ button_name: "googleplay" });
                  }}
                >
                  <img width="84" height="28" src="//st.joy-sourcing.com/website/home/googleplay.png" alt="googleplay" />
                </a>
                <a href={AppDownload} target="_self"
                  exptag={"exp|" + EXPOSURE_RECORD.Footer_AppStore_Expo}
                  data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
                  onClick={() => {
                    sendClickTrack.Footer_AppStore({ button_name: "appstore" });
                  }}
                >
                  <img width="84" height="28" src="//st.joy-sourcing.com/website/home/appStore.png" alt="appstore" />
                </a>
              </div>
            </div>
            <div className={styles["find-us"]}>
              <div className={styles.title}>Find us on</div>
              <div className={styles.findLogo}>
                <a href={Instagram} target="_self"
                  exptag={"exp|" + EXPOSURE_RECORD.Footer_Social_Media_Expo}
                  data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
                  onClick={() => {
                    sendClickTrack.Footer_Social_Media({ button_name: "Instagram" });
                  }}
                >
                  <BrandInstagram />
                </a>
                <a href={Tiktok} target="_self"
                  exptag={"exp|" + EXPOSURE_RECORD.Footer_Social_Media_Expo}
                  data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
                  onClick={() => {
                    sendClickTrack.Footer_Social_Media({ button_name: "Tiktok" });
                  }}
                >
                  <BrandTiktok />
                </a>
                <a href={Facebook} target="_self"
                  exptag={"exp|" + EXPOSURE_RECORD.Footer_Social_Media_Expo}
                  data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
                  onClick={() => {
                    sendClickTrack.Footer_Social_Media({ button_name: "Facebook" });
                  }}
                >
                  <BrandFacebook />
                </a>
                <a href={Youtube} target="_self"
                  exptag={"exp|" + EXPOSURE_RECORD.Footer_Social_Media_Expo}
                  data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
                  onClick={() => {
                    sendClickTrack.Footer_Social_Media({ button_name: "Youtube" });
                  }}
                >
                  <BrandYoutube />
                </a>
                <a href={Linktree} target="_self"
                  exptag={"exp|" + EXPOSURE_RECORD.Footer_Social_Media_Expo}
                  data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
                  onClick={() => {
                    sendClickTrack.Footer_Social_Media({ button_name: "Linktree" });
                  }}
                >
                  <BrandLinkTree />
                </a>
              </div>
            </div>
            <div className={styles["support"]}>
              <div className={styles.title}>We support</div>
              <div className={styles.logo}>
                <img width="442" height="24" src="//st.joy-sourcing.com/website/other/footer-pay-lodo.png" alt="footer_payments" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.copyright}>
        <p>
          © 2025 Joybuy.com
          <a onClick={handleOpenPersonalised}>Personalised settings</a>
          <a onClick={handleOpenCookies}>Manage cookies</a>
        </p>
        <p>
          This site is protected by reCAPTCHA and the Google
          <a className={styles.line2} href="https://policies.google.com/privacy">
            Privacy Policy
          </a>
          &nbsp; and
          <a className={styles.line2} href="https://policies.google.com/terms">
            Terms of Service
          </a>
          &nbsp; apply.
        </p>
      </div>
      <CookiesManage ref={cookiesManageRef} />
      <PersonalisedManage ref={personalisedManageRef} />
    </footer>
  );
};

export default JDIFooter2;
