// 埋点页面链接：https://stream.jd.com/ddrx/#/requirement/25149
// WARNING!!! 请勿手动修改此文件，如需修改请在子午线平台变更后，执行npm run track-init 25149 
import { eventTrackingUtils } from '@/utils/eventTrackingUtils'
interface IExpoTrackParamsRecord {
  /** 搜索历史词曝光 */
Search_History_Expo: {
/** 实验位信息(搜索)  */
abver: string,
/** 搜索id  */
pvid: string,
/** 搜索请求标识  */
logid: string,
/** 渠道名称  */
channelName: string,
/** 关键词  */
keyWord: string}
  /** 搜索热词曝光 */
Search_Hotword_Expo: {
/** 实验位信息(搜索)  */
abver: string,
/** 搜索id  */
pvid: string,
/** 搜索请求标识  */
logid: string,
/** 渠道名称  */
channelName: string,
/** 位置  */
pos: number,
/** 关键词  */
keyWord: string}
  /** 搜索框曝光 */
Search_SearchBox_Expo: {
/** 渠道名称  */
channelName: string}
  /** 删除按钮曝光 */
Search_Delete_Expo: {
/** 渠道名称  */
channelName: string}
  /** 搜索按钮曝光 */
Search_SearchBtn_Expo: {
/** 渠道名称  */
channelName: string}
}

const EXPOSURE_RECORD: Readonly<Record<keyof IExpoTrackParamsRecord,string>> = Object.freeze({
/** 搜索历史词曝光 */
  Search_History_Expo:'Search_History_Expo',
/** 搜索热词曝光 */
  Search_Hotword_Expo:'Search_Hotword_Expo',
/** 搜索框曝光 */
  Search_SearchBox_Expo:'Search_SearchBox_Expo',
/** 删除按钮曝光 */
  Search_Delete_Expo:'Search_Delete_Expo',
/** 搜索按钮曝光 */
  Search_SearchBtn_Expo:'Search_SearchBtn_Expo',
})

interface IClickTrackParamsRecord {
  /** 搜索框点击 */
Search_SearchBox: {
/** 渠道名称  */
channelName: string}
  /** 搜索按钮点击 */
Search_SearchBtn: {
/** 实验位信息(搜索)  */
abver: string,
/** 搜索id  */
pvid: string,
/** 搜索请求标识  */
logid: string,
/** 渠道名称  */
channelName: string,
/** 关键词  */
keyWord: string}
  /** 搜索历史词点击 */
Search_History: {
/** 实验位信息(搜索)  */
abver: string,
/** 搜索id  */
pvid: string,
/** 搜索请求标识  */
logid: string,
/** 渠道名称  */
channelName: string,
/** 关键词  */
keyWord: string}
  /** 搜索热词点击 */
Search_Hotword: {
/** 实验位信息(搜索)  */
abver: string,
/** 搜索id  */
pvid: string,
/** 搜索请求标识  */
logid: string,
/** 渠道名称  */
channelName: string,
/** 位置  */
pos: number,
/** 关键词  */
keyWord: string}
  /** 删除按钮点击 */
Search_Delete: {
/** 渠道名称  */
channelName: string}
}

const sendClickTrack = {
  /** 点击事件上报-搜索框点击 */
Search_SearchBox: (json_param: IClickTrackParamsRecord['Search_SearchBox']) => {
    eventTrackingUtils.submitCL({event_id:'Search_SearchBox',json_param});
  },
  /** 点击事件上报-搜索按钮点击 */
Search_SearchBtn: (json_param: IClickTrackParamsRecord['Search_SearchBtn']) => {
    eventTrackingUtils.submitCL({event_id:'Search_SearchBtn',json_param});
  },
  /** 点击事件上报-搜索历史词点击 */
Search_History: (json_param: IClickTrackParamsRecord['Search_History']) => {
    eventTrackingUtils.submitCL({event_id:'Search_History',json_param});
  },
  /** 点击事件上报-搜索热词点击 */
Search_Hotword: (json_param: IClickTrackParamsRecord['Search_Hotword']) => {
    eventTrackingUtils.submitCL({event_id:'Search_Hotword',json_param});
  },
  /** 点击事件上报-删除按钮点击 */
Search_Delete: (json_param: IClickTrackParamsRecord['Search_Delete']) => {
    eventTrackingUtils.submitCL({event_id:'Search_Delete',json_param});
  },
}

export {
  EXPOSURE_RECORD,
  sendClickTrack,
}
export type {
  IExpoTrackParamsRecord,
}