import { useEffect, useState } from "react";
import { Button, ImageList, ImageListItem } from "@mui/material";
import { BodyParam, TypeDetailInfo, SystemInfo } from "@/common-components-src/api/cart/cart.interface";
import to from "await-to-js";
import { selectOtherProType } from "@/common-components-src/api/cart/cart.api";
import { getUserUUIDFromCookie } from "@/utils/userUtils";
import { AttrUtils, AttrItemVo, AttrValueVo } from "./utils/attrUtils";
import JDIPrice from "@/common-components-src/js/price";
import { imageUtil } from "@/common-components-src/js/utils/imageUtil";
import UKLoadingIcon from "../UKLoadingIcon";
import UKImg from "../UKImg";
import { sendClickTrack, EXPOSURE_RECORD, sendKeyActionTrack } from "@/tracks/26438";
import { observer } from "mobx-react";
import styles from "./index.module.scss";

interface ItemProps {
  skuId: string;
  handleClose?: () => void;
  updateItemData?: (targetSkuId: string) => void;
  setIsLoaded?: (val: boolean) => void;
  isBtnBottom?: boolean;
}

const ProTypeModal: React.FC<ItemProps> = observer(
  ({ skuId, handleClose, updateItemData, setIsLoaded, isBtnBottom }) => {
    const [typeDetailInfo, setTypeDetailInfo] = useState<TypeDetailInfo | null>(null); // 选择切换商品类型接口数据
    const [systemInfo, setSystemInfo] = useState<SystemInfo | null>(null); // 选择切换商品类型接口数据 system相关
    const [attrItemVo, setAttrItemVo] = useState<AttrItemVo[]>(); // 选择切换商品类型接口数据
    const [isLoading, setIsLoading] = useState(true);
    const [targetSkuId, setTargetSkuId] = useState(skuId);

    useEffect(() => {
      const requestData: BodyParam = {
        verticalTag: "cn_ybxt_b2c",
        cartuuid: getUserUUIDFromCookie(),
        extendParamMap: {
          stock: true,
        },
        skuId: skuId,
      };
      loadOtherProTypeDetails(requestData);
    }, [skuId]);

    const loadOtherProTypeDetails = async (bodyParam: BodyParam) => {
      const [err, result] = await to(selectOtherProType(bodyParam));
      if (err) {
        setTypeDetailInfo(null);
        setSystemInfo(null);
        handleClose && handleClose();
      } else {
        setTypeDetailInfo(result as TypeDetailInfo);
        setSystemInfo(result?.systemInfo as SystemInfo);
        const newData = AttrUtils.initAttrListBySkuId(
          (result as TypeDetailInfo)?.selectedModule?.colorSizePartList || [],
          (result as TypeDetailInfo)?.commonModule?.["C-M#viewCommonItem&commonCore"]?.skuId || "",
        );
        const isSelect = isSelectType(newData);
        console.log("result 11111", newData, isSelect);
        if (!isSelect) {
          handleClose && handleClose();
          updateItemData && updateItemData(targetSkuId);
          return;
        }
        setIsLoading(false);
        setIsLoaded && setIsLoaded(isSelect);
        setAttrItemVo(newData);
        sendKeyActionTrack.typeLayer_Expo({ skuid: targetSkuId, spuid: (result as TypeDetailInfo)?.commonModule?.["C-M#viewCommonItem&commonCore"]?.spuId || "" })
      }
    };

    // 是否可以选择其他商品
    const isSelectType = (attrItemVo: AttrItemVo[]) => {
      if (!attrItemVo || attrItemVo.length === 0) {
        return false;
      }
      return (attrItemVo.some(item => item.itemList && item.itemList.length > 2));
    };

    const handleClickType = (item: AttrValueVo, skuId?: string, currentChecked?: boolean) => {
      // 这里模拟调用接口，实际中应该使用 skuId 调用真实的接口
      if (!typeDetailInfo || !skuId) {
        return;
      }
      item.checked = !currentChecked;
      const result = AttrUtils.onAttrChecked(
        item,
        typeDetailInfo?.selectedModule?.colorSizePartList || [],
        skuId,
      );
      if (!result.skuId) {
        return;
      }
      setTargetSkuId(result.skuId);
      const requestData: BodyParam = {
        verticalTag: "cn_ybxt_b2c",
        cartuuid: getUserUUIDFromCookie(),
        extendParamMap: {
          stock: true,
        },
        skuId: result.skuId,
      };
      console.log("点击了，skuId:", skuId, item, result);
      loadOtherProTypeDetails(requestData);
    };

    const titleAndPriceFloor = () => {
      return (
        <div>
          <span className={styles["name"]}>
            {typeDetailInfo?.selectedModule?.["C-M#viewSelectedItem&core"]?.salesDesc}
          </span>
          <div className={styles["price-item"]}>
            <JDIPrice
              symbol={systemInfo?.currencyCode || "£"}
              price={typeDetailInfo?.selectedModule?.["C-M#viewSelectedItem&core"]?.mainPrice || ""}
              uiType={1}
              containerStyle={{
                fontSize: "16px",
                lineHeight: "20px",
              }}
              integerStyle={{
                fontSize: "24px",
                lineHeight: "24px",
                height: "24px",
              }}
            />
            {typeDetailInfo?.selectedModule?.["C-M#viewSelectedItem&core"]?.mainPrice !==
              typeDetailInfo?.selectedModule?.["C-M#viewSelectedItem&core"]?.crossOffPrice && (
                <JDIPrice
                  symbol={systemInfo?.currencyCode || "£"}
                  price={
                    typeDetailInfo?.selectedModule?.["C-M#viewSelectedItem&core"]?.crossOffPrice || ""
                  }
                  uiType={2}
                  containerStyle={{
                    marginLeft: "4px",
                    fontSize: "14px",
                    lineHeight: "18px",
                    color: "#888B94",
                  }}
                  lineThrough
                />
              )}
            {/* 折扣等标签 */}
            <div className={styles["text-container"]}>
              {typeDetailInfo?.priceModule?.["C-M#priceViewItem&priceInfo"]
                ?.percentReductionDesc && (
                  <span className={styles["tag"]}>
                    {typeDetailInfo?.priceModule?.["C-M#priceViewItem&priceInfo"]?.percentReductionDesc}
                  </span>
                )}
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className={styles["container"]}>
        {isLoading && (
          <div className={styles["load-page"]}>
            <UKLoadingIcon />
          </div>
        )}
        {typeDetailInfo && !isLoading && (
          <UKImg
            externalStyles={{
              width: "160px",
              height: "160px",
              objectFit: "contain",
            }}
            url={`${imageUtil.concatImgUrlWithoutForceHttps(typeDetailInfo?.selectedModule?.["C-M#viewSelectedItem&core"]?.imageUrl ? typeDetailInfo?.selectedModule["C-M#viewSelectedItem&core"]?.imageUrl : "", 320, 320)}`}
          />
        )}
        {!isLoading && (
          <div className={styles["right"]}>
            {/* title及价格楼层 */}
            {titleAndPriceFloor()}
            {attrItemVo?.map((item, index) => (
              <div key={index}>
                <div className={styles["color-space"]}>{item?.title}：
                  <span className={styles["color-item"]}>{item?.itemList?.find((p) => item?.currentValue === p?.key)?.label}</span>
                </div>
                {item?.itemList?.length === item?.itemList?.filter((p) => p.imgUrl).length ? (
                  <ImageList
                    cols={6}
                    rowHeight={62}
                    style={{
                      overflowY: "hidden",
                      gap: "10px",
                      marginTop: "8px",
                      marginBottom: "16px",
                    }}
                  >
                    {item?.itemList?.map((partItem, partIndex) => {
                      return (
                        <ImageListItem
                          key={partIndex}
                          style={{
                            borderWidth: item?.currentValue === partItem?.key ? "1.5px" : "1px",
                            borderStyle:
                              item?.currentValue === partItem?.key
                                ? "solid"
                                : partItem?.disabled
                                  ? "dashed"
                                  : "solid",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "62px",
                            cursor: partItem?.disabled ? "not-allowed" : "pointer",
                            borderColor:
                              item?.currentValue === partItem?.key
                                ? "#CC0C1C"
                                : partItem?.disabled
                                  ? "#E0E1E5"
                                  : "#E0E1E5",
                            opacity: partItem?.disabled ? 0.4 : 1,
                            borderRadius: "5px",
                          }}
                          onClick={
                            !partItem?.disabled
                              ? () =>
                                handleClickType(
                                  partItem,
                                  skuId,
                                  item?.currentValue === partItem?.key,
                                )
                              : () => { }
                          }
                        >
                          <UKImg
                            externalStyles={{
                              width: "60px",
                              height: "60px",
                              objectFit: "contain",
                            }}
                            url={`${imageUtil.concatImgUrlWithoutForceHttps(partItem.imgUrl ? partItem.imgUrl : "", 120, 120)}`}
                          />
                        </ImageListItem>
                      );
                    })}
                    {item?.itemList?.map(() => {
                      return null;
                    })}
                  </ImageList>
                ) : (
                  <div className={styles["content"]}>
                    {item?.itemList?.map((partItem, partIndex) => {
                      return (
                        <div
                          key={partIndex}
                          className={`${styles["item"]} ${item?.currentValue === partItem?.key ? styles["active"] : partItem?.disabled ? styles["disabled"] : ""}`}
                          onClick={
                            !partItem?.disabled
                              ? () =>
                                handleClickType(
                                  partItem,
                                  skuId,
                                  item?.currentValue === partItem?.key,
                                )
                              : () => { }
                          }
                        >
                          {partItem.label}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            ))}
            {isBtnBottom ? <div className={styles["button"]}>
              <Button
                className={styles["left"]}
                onClick={() => {
                  handleClose && handleClose();
                  updateItemData && updateItemData(targetSkuId);
                }}
              >
                Confirm
              </Button>
              <Button className={styles["right"]} onClick={handleClose}>
                Cancel
              </Button>
            </div> : <div className={styles.buttonContainer}>
              <Button className={styles["cancel"]} onClick={handleClose}>
                Cancel
              </Button>
              <Button
                className={styles["add"]}
                onClick={() => {
                  handleClose && handleClose();
                  updateItemData && updateItemData(targetSkuId);
                  sendClickTrack.ProductSpecLayer_MainBtn_Click({ skuid: targetSkuId, spuid: typeDetailInfo?.commonModule?.["C-M#viewCommonItem&commonCore"]?.spuId || "" });
                }}
              >
                Add to Cart
              </Button>
            </div>}
          </div>
        )}
      </div>
    );
  },
);

export default ProTypeModal;
