import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

// eslint-disable-next-line react/prop-types
function UKDialog({ open, onClose, showCloseBtn = false, children, className = "", sx = {} }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={className}
      sx={sx}
      PaperProps={{
        sx: {
          maxWidth: "none",
          position: 'relative'
        },
      }}
    >
      {showCloseBtn && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
      )}
      {children}
    </Dialog>
  );
}

export default UKDialog;
