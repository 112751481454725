import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import UKToast from '@/common-components-src/components/UKToast/index.tsx';

interface Props {
  text: string;
  children: React.ReactNode;
}

const UKCopy: React.FC<Props> = ({ text, children }) => {
  const copyHandler = () => {
    UKToast.show({
      content: 'Copy successful',
      icon: 'success',
      duration: 2000,
    });
  };

  return (
    <CopyToClipboard text={text} onCopy={copyHandler}>
      {children}
    </CopyToClipboard>
  );
};

export default UKCopy;
