import newColorAxiosInstance from "../new-color-axiosInstance";

interface Response<T> {
  code: string;
  success: boolean;
  message: string;
  data: T;
}

export const feedbackSubmitApi = (formData: any): Promise<Response<any>> => {
  return newColorAxiosInstance
    .post<any>(
      `user_feedback_add`,
      { body: formData },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    )
    .then((response: any) => response!.data)
    .catch((error: any) => {
      console.log(error);
    });
};