// 埋点页面链接：https://stream.jd.com/ddrx/#/requirement/25062
// WARNING!!! 请勿手动修改此文件，如需修改请在子午线平台变更后，执行npm run track-init 25062 
import { eventTrackingUtils } from '@/utils/eventTrackingUtils'
interface IExpoTrackParamsRecord {
  /** 利益点吸顶_Download曝光 */
BenefitHeader_Download_Expo: undefined
}

const EXPOSURE_RECORD: Readonly<Record<keyof IExpoTrackParamsRecord,string>> = Object.freeze({
/** 利益点吸顶_Download曝光 */
  BenefitHeader_Download_Expo:'BenefitHeader_Download_Expo',
})

export {
  EXPOSURE_RECORD,
}
export type {
  IExpoTrackParamsRecord,
}