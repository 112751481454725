import style from "@/common-components-src/js/floatingToolbar/style.module.scss";
import MessageBtn from "@/common-components-src/js/floatingToolbar/components/MessageBtn";
import BackTopBtn from "@/common-components-src/js/floatingToolbar/components/BackTopBtn";
import FeedbackBtn from "@/common-components-src/js/floatingToolbar/components/FeedbackBtn";
import CustomerSupport from "@/common-components-src/js/floatingToolbar/components/CustomerSupport";

const JDIFloatingToolbar = () => {
  return (
    <div id="jdi-floating-toolbar" className={style.floatingToolbar}>
      <MessageBtn />
      <CustomerSupport />
      <FeedbackBtn />
      <BackTopBtn />
    </div>
  );
};

export default JDIFloatingToolbar;
