export default class ContactInfoValidTool {

  static isPhone(str) {
    if(str && str.length > 0){
      if(str[0] == "+"){
        str = str.substring(1);
      }
      return !isNaN(str)
    }
    return false
  }

  static isPhonePattern(phone) {
    return phone ? phone.length >= 10 && phone.length <= 14 : false
  }
  
  static isEmailPattern(email) {
    if(!email){
      return false
    }
    if (email.length > 254) {
      return false
    }
    const allowedChars = /^[A-Za-z0-9._+-]+@[A-Za-z0-9._+-]+\.[A-Za-z0-9._+-]{2,}$/;
    return allowedChars.test(email);
  }
}
