/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";
import { Button } from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";

const buttonCommonStyle = {
  fontSize: "14px",
  padding: "7px 16px",
  borderRadius: "4px",
  lineHeight: "18px",
  textAlign: "center",
  fontWeight: 400,
};

export const SubmitButton = muiStyled(Button)(
  ({ theme }: any) =>
    ({
      ...buttonCommonStyle,
      // background: theme.palette.primary.btnBgColor,
      background: "linear-gradient(90deg, #FF475D 0%, #FF0F23 100%)",
      color: "#fff",
      // backgroundColor: '#999',
      "&:hover": {
        // background: theme.palette.primary.btnBgColor,
        background: "linear-gradient(90deg, #FF475D 0%, #FF0F23 100%)",
        boxShadow: "none",
      },
      "&.Mui-disabled": {
        backgroundColor: "#FF9FA7 !important",
        opacity: 0.5,
        cursor: "no-drop",
        color: "#fff",
      },
    }) as any,
);

export const CancelButton = muiStyled(Button)(
  ({ theme }: any) =>
    ({
      ...buttonCommonStyle,
      border: "1px solid rgba(224, 225, 229, 1)",
      color: "rgba(26, 26, 26, 1)",
      // backgroundColor: '#999',
      "&:hover": {
        background: "none",
        boxShadow: "none",
      },
      "&.Mui-disabled": {
        opacity: 0.5,
        cursor: "no-drop",
        color: "#fff",
      },
    }) as any,
);
