// 埋点页面链接：https://stream.jd.com/ddrx/#/requirement/25423
// WARNING!!! 请勿手动修改此文件，如需修改请在子午线平台变更后，执行npm run track-init 25423 
import { eventTrackingUtils } from '@/utils/eventTrackingUtils'
interface IExpoTrackParamsRecord {
  /** 保存并使用按钮曝光 */
NeworderAddressNewAdd_Save_Expo: {
/** 页面类型  */
page_type: string,
/** 来源页面  */
from_page: string}
  /** 地区选择曝光 */
NeworderAddressNewAdd_Region_Expo: {
/** 页面类型  */
page_type: string,
/** 来源页面  */
from_page: string}
  /** 搜索地址框曝光 */
NeworderAddressNewAdd_Search_Expo: {
/** 页面类型  */
page_type: string,
/** 来源页面  */
from_page: string}
  /** 设置默认地址按钮曝光 */
NeworderAddressNewAdd_Default_Expo: {
/** 页面类型  */
page_type: string,
/** 是否设置默认地址  */
isdefaultaddr: string,
/** 来源页面  */
from_page: string}
  /** 新增地址弹窗 */
NeworderAddressNewAdd_Expo: {
/** 页面类型  */
page_type: string,
/** 来源页面  */
from_page: string}
}

const EXPOSURE_RECORD: Readonly<Record<keyof IExpoTrackParamsRecord,string>> = Object.freeze({
/** 保存并使用按钮曝光 */
  NeworderAddressNewAdd_Save_Expo:'NeworderAddressNewAdd_Save_Expo',
/** 地区选择曝光 */
  NeworderAddressNewAdd_Region_Expo:'NeworderAddressNewAdd_Region_Expo',
/** 搜索地址框曝光 */
  NeworderAddressNewAdd_Search_Expo:'NeworderAddressNewAdd_Search_Expo',
/** 设置默认地址按钮曝光 */
  NeworderAddressNewAdd_Default_Expo:'NeworderAddressNewAdd_Default_Expo',
/** 新增地址弹窗 */
  NeworderAddressNewAdd_Expo:'NeworderAddressNewAdd_Expo',
})

interface IClickTrackParamsRecord {
  /** 保存并使用 */
NeworderAddressNewAdd_Save: {
/** 页面类型  */
page_type: string,
/** 来源页面  */
from_page: string}
  /** 地区选择 */
NeworderAddressNewAdd_Region: {
/** 页面类型  */
page_type: string,
/** 来源页面  */
from_page: string}
  /** 搜索地址框点击 */
NeworderAddressNewAdd_Search: {
/** 页面类型  */
page_type: string,
/** 来源页面  */
from_page: string}
  /** 设置默认地址 */
NeworderAddressNewAdd_Default: {
/** 页面类型  */
page_type: string,
/** 选中状态  */
chstatus: string,
/** 来源页面  */
from_page: string}
}

const sendClickTrack = {
  /** 点击事件上报-保存并使用 */
NeworderAddressNewAdd_Save: (json_param: IClickTrackParamsRecord['NeworderAddressNewAdd_Save']) => {
    eventTrackingUtils.submitCL({event_id:'NeworderAddressNewAdd_Save',json_param});
  },
  /** 点击事件上报-地区选择 */
NeworderAddressNewAdd_Region: (json_param: IClickTrackParamsRecord['NeworderAddressNewAdd_Region']) => {
    eventTrackingUtils.submitCL({event_id:'NeworderAddressNewAdd_Region',json_param});
  },
  /** 点击事件上报-搜索地址框点击 */
NeworderAddressNewAdd_Search: (json_param: IClickTrackParamsRecord['NeworderAddressNewAdd_Search']) => {
    eventTrackingUtils.submitCL({event_id:'NeworderAddressNewAdd_Search',json_param});
  },
  /** 点击事件上报-设置默认地址 */
NeworderAddressNewAdd_Default: (json_param: IClickTrackParamsRecord['NeworderAddressNewAdd_Default']) => {
    eventTrackingUtils.submitCL({event_id:'NeworderAddressNewAdd_Default',json_param});
  },
}

export {
  EXPOSURE_RECORD,
  sendClickTrack,
}
export type {
  IExpoTrackParamsRecord,
}