import { useState } from "react";
import DefaultImg  from "@/common-components-src/assets/icons/defaultImg.svg"

//图片组件，处理兜底图展示
export default function Image({src}: { src: string }) {
  const [imageError, setImageError] = useState(false);
  function handleImageError() {
    setImageError(true);
  }
  return (
    <>
      {
        imageError ?
          <DefaultImg /> :
          <img src={src} onError={handleImageError} />
      }
    </>

  )
}
