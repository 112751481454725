/* eslint-disable react/display-name */
"use client";
import React from "react";
import style from "./index.module.scss";
import Radio from '@mui/material/Radio';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import UKToast from '@/common-components-src/components/UKToast/index.tsx';
import UKDialog from '@/common-components-src/components/UKDialog/index.tsx'
import { useState, forwardRef, useEffect } from "react";
import ContactInfoValidTool from "./ContactInfoValidTool";
import UKUpload from "@/common-components-src/components/UKUpload";
import IMAGEAPI_TYPE from "@/common-components-src/const/imageApiType";
import { feedbackSubmitApi } from "@/common-components-src/api/feedback/index.api";
import RedClose  from "@/common-components-src/assets/icons/redClose.svg";
import { EXPCONFIG_DELAY } from "@/common-components-src/const/point";
import { sendClickTrack, EXPOSURE_RECORD } from "@/tracks/25630"
import { DialogContent, DialogTitle, Button, FormControl, FormControlLabel, OutlinedInput, FormHelperText } from "@mui/material";
import { DialogStyle, FlexRightStyle, SubjectTitleStyle, DescTitleStyle, ContactTitleStyle, SubjectFormStyle, DescFormStyle, ContactFormStyle, RadioLabelStyle, RadioColorStyle, SubjectInputStyle, DescInputStyle, EmailInputStyle, CancelBTnStyle, SubmitBTnStyle } from "./style";

const FeedbackDialog = forwardRef(({ open, closeFn }, ref) => {

  const textError = "This must include at least 10 characters.";
  const validMsg = "Please enter a valid email address or a valid phone number";
  const radioLabelDict = {
    1: "Order issues",
    2: "Payment issues",
    3: "Account issues",
    4: "Legal/security & privacy",
    5: "Feedback on the application's functionality",
    6: "Other",
  }

  const otherIssue = 6;//其他类型
  const maxImgCount = 5;//最大上传图片数量
  const textMinCount = 10;//最少输入字数
  const maxSubjectCount = 100;//最大输入字数
  const maxCharacterCount = 1400;//描述最大输入字数

  const [isOpen, setIsOpen] = useState(false); // 弹框控制器
  const [emailValue, setEmailValue] = useState(""); // email输入框的value值
  const [desValue, setDesValue] = useState(""); // desc输入框的value值
  const [subjectValue, setSubjectValue] = useState(""); // subject输入框的value值
  const [descCount, setDescCount] = useState(0);//desc输入框的文本个数
  const [subjectCount, setSubjectCount] = useState(0);//subject输入框的文本个数
  const [subjectRadio, setSubjectRadio] = useState(1);// 需要反馈的问题类型
  const [chooseOther, setChooseOther] = useState(false); // 是否显示其他问题输入框
  const [helperTextError, setHelperTextError] = useState(""); // 输入框错误提示
  const [descHelperTextError, setDescHelperTextError] = useState(""); // desc输入框错误提示
  const [subjectHelperTextError, setSubjectHelperTextError] = useState(""); // subject输入框错误提示
  const [selectedValue, setSelectedValue] = useState("0"); // 默认选中
  const [isSubmitting, setIsSubmitting] = useState(false); // 正在提交中
  const [mediaList, setMediaList] = useState([]); // 图片数组
  const [isLoading, setIsLoading] = useState(false); // 图片加载中

  useEffect(() => {
    setIsOpen(open);
  }, [open])

  useEffect(() => {
    if (!emailValue) {
      setHelperTextError("")
      return
    }
    let isPhone = ContactInfoValidTool.isPhone(emailValue)
    let valid = isPhone ? ContactInfoValidTool.isPhonePattern(emailValue) : ContactInfoValidTool.isEmailPattern(emailValue)
    setHelperTextError(valid ? "" : validMsg)
  }, [emailValue])

  useEffect(() => {
    let count = desValue ? desValue.length : 0
    setDescCount(count)
  }, [desValue])

  useEffect(() => {
    let count = subjectValue ? subjectValue.length : 0
    setSubjectCount(count)
  }, [subjectValue])

  useEffect(() => {
    setChooseOther(subjectRadio == otherIssue)
  }, [subjectRadio])

  const closeHandler = () => {
    setIsOpen(false);
    closeFn && closeFn()
  }

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setSubjectRadio(event.target.value);
  };

  const handleSubjectOnBlur = () => {
    setSubjectHelperTextError(subjectCount && subjectCount < textMinCount ? textError : "");
  };

  const handleDescOnBlur = () => {
    setDescHelperTextError(descCount && descCount < textMinCount ? textError : "");
  };

  const OnCancel = () => {
    closeHandler()
    sendClickTrack.Feedback_Pop({button_name: "Cancel"})
  };
  
  // 提交反馈
  const OnSubmit = async () => {
    sendClickTrack.Feedback_Pop({button_name: "Submit"})
    handleDescOnBlur()
    handleSubjectOnBlur()
    if(isSubmitting){
      return
    }
    if (!!helperTextError || !!descHelperTextError || !!subjectHelperTextError) {
      return
    }
    if(isLoading){
      UKToast.show({
        icon: 'fail',
        content: 'Oops! The image is still uploading, please try again later.',
      })
      return
    }
    let params = {
      subjectType: parseInt(subjectRadio),
      subject: chooseOther ? subjectValue : radioLabelDict[subjectRadio],
      feedbackDetails: desValue,
      feedbackAttachments: mediaList,
      email: emailValue,
      platform: 3,
      appVersion: ""
    }
    setIsSubmitting(true);
    feedbackSubmitApi(JSON.stringify(params)).then((res) => {
      if (res && res.success) {
        UKToast.show({
          icon: 'success',
          content: 'Submitted successful!',
        })
        closeHandler()
      }
      else{
        setIsSubmitting(false);
        UKToast.show({
          icon: 'fail',
          content: 'Oops! Something went wrong. Please try again',
        })
      }
    });
  };

  return (
    <UKDialog open={isOpen} onClose={closeHandler} showCloseBtn={false} sx={DialogStyle}>
        <DialogTitle className={style.dialogtitle__wrap}>Feedback</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeHandler}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <div 
            className={style.dialogcontent__wrap}
            exptag={"exp|" + EXPOSURE_RECORD.Feedback_Pop_Expo}
            data-exptag-config={EXPCONFIG_DELAY}
          >
            <div style={SubjectTitleStyle} className={style.form__title}><span>*</span><em> Subject</em></div>
            <div className={style.form__radio}>
              <FormControlLabel sx={RadioLabelStyle} value="1" label={radioLabelDict[1]} control={<Radio sx={RadioColorStyle} checked={selectedValue === '1'} onChange={handleChange} value="1" name="radio-buttons" />}/>
              <FormControlLabel sx={RadioLabelStyle} value="2" label={radioLabelDict[2]} control={<Radio sx={RadioColorStyle} checked={selectedValue === '2'} onChange={handleChange} value="2" name="radio-buttons" />}/>
              <FormControlLabel sx={RadioLabelStyle} value="3" label={radioLabelDict[3]} control={<Radio sx={RadioColorStyle} checked={selectedValue === '3'} onChange={handleChange} value="3" name="radio-buttons" />}/>
              <FormControlLabel sx={RadioLabelStyle} value="4" label={radioLabelDict[4]} control={<Radio sx={RadioColorStyle} checked={selectedValue === '4'} onChange={handleChange} value="4" name="radio-buttons" />}/>
              <FormControlLabel sx={RadioLabelStyle} value="5" label={radioLabelDict[5]} control={<Radio sx={RadioColorStyle} checked={selectedValue === '5'} onChange={handleChange} value="5" name="radio-buttons" />}/>
              <FormControlLabel sx={RadioLabelStyle} value="6" label={radioLabelDict[6]} control={<Radio sx={RadioColorStyle} checked={selectedValue === '6'} onChange={handleChange} value="6" name="radio-buttons" />}/>
            </div>
            {chooseOther && (
              <div style={FlexRightStyle} className={style.input__wrap}>
                <FormControl sx={SubjectFormStyle} id="outlined-subject" variant="outlined" error={!!subjectHelperTextError}>
                  <OutlinedInput
                    multiline
                    minRows={2}
                    maxRows={2}
                    type="text"
                    value={subjectValue}
                    style={SubjectInputStyle}
                    inputProps={{ maxLength: 100 }}
                    placeholder={"Your feedback subject is important! Please enter a minimum of 10 characters or more."}
                    onChange={(event) => setSubjectValue(event.target.value)}
                    onBlur={handleSubjectOnBlur}
                  />
                  {!!subjectHelperTextError && (
                    <FormHelperText>
                      <div className={style.error_text}>
                        <RedClose />
                        {subjectHelperTextError}
                      </div>
                    </FormHelperText>
                  )}
                </FormControl>
                <span className={style.input_text}>
                  <span className={style.input_text_color}>{subjectCount}</span>
                  <span>/{maxSubjectCount}</span>
                </span>
              </div>
            )}
            <div style={DescTitleStyle} className={style.form__title}><em>Detailed description</em></div>
            <div className={style.input__wrap}>
              <FormControl sx={DescFormStyle} id="outlined-basic" variant="outlined" fullWidth error={!!descHelperTextError}>
                <OutlinedInput
                  multiline
                  minRows={4}
                  maxRows={4}
                  type="text"
                  value={desValue}
                  style={DescInputStyle}
                  inputProps={{ maxLength: 1400 }}
                  placeholder={"Please describe your concern in detail with a minimum requirement of 10 characters."}
                  onChange={(event) => setDesValue(event.target.value)}
                  onBlur={handleDescOnBlur}
                />
                {!!descHelperTextError && (
                  <FormHelperText>
                    <div className={style.error_text}>
                      <RedClose />
                      {descHelperTextError}
                    </div>
                  </FormHelperText>
                )}
              </FormControl>
              <span className={style.input_text}>
                <span className={style.input_text_color}>{descCount}</span>
                <span>/{maxCharacterCount}</span>
              </span>
            </div>
            <div className={style.upload__footer}>Uploading an image will assist you in better describing the issue. You can upload up to 5 images.</div>
            <UKUpload
              type="image"
              limitNumber={maxImgCount}
              containStyle = {{gap:"4px"}}
              apiType={IMAGEAPI_TYPE.FeedBack}
              onChange={(media) => setMediaList(media)}
              onLoadingChange={(loading) => setIsLoading(loading)}
            />
            <div style={ContactTitleStyle} className={style.form__title}><span>*</span><em> Contact information</em></div>
            <FormControl sx={ContactFormStyle} error={!!helperTextError} id="outlined-basic" variant="outlined" fullWidth>
              <OutlinedInput
                type="text"
                multiline
                maxRows={2}
                value={emailValue}
                style={EmailInputStyle}
                sx={{padding:"11px 14px"}}
                placeholder={"Please enter your email address or phone number."}
                onChange={(event) => setEmailValue(event.target.value)}
              />
              {!!helperTextError && (
                <FormHelperText>
                  <div className={style.error_text}>
                    <RedClose />
                    {helperTextError}
                  </div>
                </FormHelperText>
              )}
            </FormControl>
            <div className={style.dialog__footer}>
              <Button sx={CancelBTnStyle} onClick={OnCancel}>Cancel</Button>
              <Button sx={SubmitBTnStyle} onClick={OnSubmit} disableElevation disabled={!emailValue || !!helperTextError || !!descHelperTextError || !!subjectHelperTextError || selectedValue == "0"}>Submit</Button>
            </div>
          </div>
        </DialogContent>
    </UKDialog >
  );
});

export default FeedbackDialog;