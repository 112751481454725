import PAGE_TYPE from "@/common-components-src/js/address/const/pageType";

export const pageTypeText = (pageType: string) => {
    if (pageType === PAGE_TYPE.SHIPPING) {
        return '2';
    }
    if (pageType === PAGE_TYPE.PERSON) {
        return '1';
    }
    if (pageType === PAGE_TYPE.BILLING) {
        return '3';
    }
    return '';
}

export const itemClickTrack = (index: number, item: any) => {
    return {
        index,
        addrid: `${item?.addressId}`,
        isdefaultaddr: item?.defAddress ? "是" : "否",
    };
}

export const createTrack = (pageType: string, isEdit: boolean) => {
    let from_page = "";
    if (pageType === PAGE_TYPE.SHIPPING) {
        from_page = '2';
    } else if (pageType === PAGE_TYPE.PERSON) {
        from_page = '1';
    } else if (pageType === PAGE_TYPE.BILLING) {
        from_page = '3';
    } else if (pageType === PAGE_TYPE.ADDRESS_LIB) {
        from_page = '4';
    }
    const page_type = isEdit ? '2' : '1';
    return {
        page_type,
        from_page,
    };
}

export const postTrack = (isLogin: boolean, addressList: any) => {
    return {
        page_type: loginTrack(isLogin, addressList),
        addqty: addressList?.length || "-100",
    };
}

export const loginTrack = (isLogin: boolean, addressList: any) => {
    let page_type = "";
    if (!isLogin) {
        page_type = '1';
    } else if (isLogin && addressList?.length === 0) {
        page_type = '2';
    } else if (isLogin && addressList?.length > 0) {
        page_type = '3';
    }
    return page_type;
}