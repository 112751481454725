import React, { useEffect, useRef, useState } from 'react';
import style from './style.module.scss'
import to from "await-to-js";
import { cms_rootCateListAll } from "@/common-components-src/api/header/index.api";
import { imageUtil } from '@/common-components-src/js/utils/imageUtil'
import { sleep } from '@/common-components-src/js/utils/index.ts'
import { EXPOSURE_RECORD, sendClickTrack } from '@/tracks/25373'

const CategoryDropdown = () => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const rightRef = useRef(null);
  const [clickFlag, setClickFlag] = useState(false);

  const [categories, setCategories] = useState([]);
  const [isActive, setIsActive] = useState({ lv1: '' });
  const lv1CateId = useRef('');
  const lv2CateId = useRef('');
  const mouseX = useRef(10000000);

  const handleCategoryClick = (lv, category) => {
    let href = ''
    if (lv === 2) {
      const [l1, l2] = category.bloodIds;
      href = `/s?k=${encodeURIComponent(category.cateName.trim())}&l1=${l1}&l2=${l2}`;
    } else {
      const [l1, l2, l3] = category.bloodIds;
      href = `/s?k=${encodeURIComponent(category.cateName.trim())}&l1=${l1}&l2=${l2}&l3=${l3}`;
    }
    window.location.href = href
  }

  //点击三级类目
  const handleClickThirdCategory = (firstCategory, secondcid, second_category_name, thirdcid, third_category_name, index) => {
    sendClickTrack.Category_Main_ThirdCategory({
      firstcid: firstCategory.cateId,
      first_category_name: firstCategory.cateName,
      secondcid: secondcid || '-100',
      second_category_name: second_category_name || '-100',
      thirdcid: thirdcid,
      third_category_name: third_category_name,
      index
    })
    window.location.href = `/s?k=${encodeURIComponent(third_category_name.trim())}&l1=${firstCategory.cateId}&l2=${secondcid}&l3=${thirdcid}`;
  }

  const initData = async () => {
    const [err, res] = await to(cms_rootCateListAll({}));
    if (err) {
      return;
    }
    const { code, data } = res;
    if (code === "200") {
      const newCategoryList = collapseSecondLevel(data)

      setCategories(newCategoryList);
      if (newCategoryList && newCategoryList.length > 0) {
        setIsActive({ lv1: newCategoryList[0].cateId })
      }
    }
  }

  //把二级类目干掉，直接变成三级类目数组
  const collapseSecondLevel = (data) => {
    const result = [];

    data.forEach((firstLevel) => {
      if (firstLevel.childrenList.length > 0) {
        const newSecondLevel = {
          id: firstLevel.id,
          parentId: firstLevel.parentId,
          cateId: firstLevel.cateId,
          cateName: firstLevel.cateName,
          cateAlias: firstLevel.cateAlias,
          cateImageUri: firstLevel.cateImageUri,
          selectedIconUri: firstLevel.selectedIconUri,
          cateLevel: 1,
          language: firstLevel.language,
          childrenList: [],
          bloodIds: firstLevel.bloodIds,
        };

        firstLevel.childrenList.forEach((secondLevel) => {
          if (secondLevel.childrenList.length > 0) {
            secondLevel.childrenList.forEach((thirdLevel) => {
              thirdLevel.secondCateName = secondLevel.cateName
              thirdLevel.secondCateId = secondLevel.cateId
              newSecondLevel.childrenList.push(thirdLevel);
            });
          }
        });

        if (newSecondLevel.childrenList.length > 0) {
          result.push(newSecondLevel);
        }
      }
    });

    return result;
  }

  //右侧三级类目根据currentIndex触发滚动
  useEffect(() => {
    if (!clickFlag) {
      return;
    }
    let scrollHeight = 0;
    for (let i = 0, length = Array.from(rightRef.current?.children || []).length; i < length; i++) {
      if (i < currentIndex) {
        scrollHeight += rightRef.current?.children[i].getBoundingClientRect().height || 0;
      } else {
        break;
      }
    }
    rightRef.current?.scrollTo(0, scrollHeight);
  }, [currentIndex, clickFlag])

  useEffect(() => {
    if (categories.length > 0) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (clickFlag) {
            return;
          }
          if (entry.isIntersecting) {
            const index = Array.from((entry.target.parentNode).children).findIndex((item) => item === entry.target)
            setCurrentIndex(index)
          }
        })
      }, {
        root: rightRef.current,
        // 120px为一个类目行的高度
        rootMargin: `0px 0px ${120 - (rightRef.current?.getBoundingClientRect().height || 0)}px 0px`,
      })

      document.querySelectorAll(".observer-hook").forEach((el) => {
        observer.observe(el);
      })

      return () => {
        observer.disconnect();
      }
    }
  }, [categories, clickFlag])

  useEffect(() => {
    initData()
  }, [])


  const getChildrenCategory = () => {
    const activeLv1 = categories.find(x => x.cateId === isActive.lv1)
    const childrenList = activeLv1.childrenList
    if (categories?.length > 0) {
      return (
        <>
          <div className={style["category-divider"]}></div>
          <ul className={style["category-list-right"]} ref={rightRef} onTouchStart={() => setClickFlag(false)}>
            {
              categories.map((cate, i) => (
                <li
                  className={`${style["category-list-lv3-li"]} observer-hook`}
                  key={cate.id}
                  onMouseEnter={(e) => categoryMouseEnter(e, cate, i, true)}
                >
                  <div className={style["category-list-lv2-title"]}>
                    <span className={style["lv2-title"]}>{cate.cateName}</span>
                  </div>

                  <div className={style["category-list-lv3-items"]}>
                    {
                      cate?.childrenList.length > 0 ? cate.childrenList.map((lv3, ii) => {
                        return (
                          <div
                            className={style["category-list-lv3-item"]}
                            key={lv3.id}
                            onClick={() => handleClickThirdCategory(activeLv1, lv3.secondCateId, lv3.secondCateName, lv3.cateId, lv3.cateName, ii)}
                            exptag={`exp|${EXPOSURE_RECORD.Category_Main_ThirdCategory_Expo}`}
                            data-exptag-config={
                              JSON.stringify({
                                useClick: false, // 是否用点击接口,默认使用曝光接口   exptag-config-useClick
                                stay_duration: 0.3, // 曝光时长    exptag-config-stay_duration
                                repeated: false, // 是否可重复曝光  exptag-config-repeated
                                area_rate: 0.01  // 漏出比例  exptag-config-area_rate
                              })
                            }
                            data-exptag-json-param={
                              JSON.stringify([{
                                firstcid: activeLv1?.cateId,
                                first_category_name: activeLv1?.cateName,
                                secondcid: lv3.secondCateId || '-100',
                                second_category_name: lv3.secondCateName || '-100',
                                thirdcid: lv3.cateId,
                                third_category_name: lv3.cateName,
                                index: ii
                              }])
                            }
                          >
                            <div className={style["category-list-lv3-items-img"]}>
                              <img
                                src={imageUtil.concatImgUrlWithoutForceHttps(lv3.cateImageUri, 300, 300)}
                                alt={lv3.cateName}
                                onError={(e) => {
                                  e.target.style.display = 'none';
                                }} />
                            </div>
                            <div className={style["category-list-lv3-items-name"]}>
                              {lv3.cateName}
                            </div>
                          </div>
                        )
                      }) : null
                    }
                  </div>
                </li>
              ))
            }
          </ul>
        </>
      )
    }
    return
  }
  const categoryMouseEnter = async (e, category, index, isRight = false) => {
    const { pageX, pageY } = e
    if (isRight) {
      setClickFlag(false)
      let [lv1] = category.bloodIds
      lv1CateId.current = lv1;
      if (lv1 === lv1CateId.current) {
        setIsActive({ lv1 })
      }
    } else {
      setClickFlag(true)
      let [lv1] = category.bloodIds
      lv1CateId.current = lv1;
      const distance = pageX - mouseX.current
      mouseX.current = pageX;
      //判断鼠标是否往右移动
      if (distance > 0) {
        await sleep(500)
      }
      if (lv1 === lv1CateId.current) {
        setIsActive({ lv1 })
        index !== undefined && setCurrentIndex(index)
      }
    }

  }

  //显示二级类目的MouseEnter方法
  // const categoryMouseEnter = async (e, category, isTrending = false) => {
  //   const { pageX, pageY } = e
  //   let [lv1, lv2] = category.bloodIds
  //   //判断如果是trending，则lv1和lv2都为空
  //   if (isTrending) {
  //     lv1 = isActive.lv1
  //     lv2 = ''
  //   }
  //   lv1CateId.current = lv1;
  //   const distance = pageX - mouseX.current
  //   mouseX.current = pageX;
  //   //判断鼠标是否往右移动
  //   if (distance > 0) {
  //     await sleep(500)
  //   } else {
  //   }
  //   if (lv1 === lv1CateId.current) {
  //     setIsActive({ lv1, lv2 })
  //   }
  // }
  // const getCategoryLv2 = () => {
  //   if (categories.length > 0) {
  //     const lv1 = categories.find(x => x.cateId === isActive.lv1)
  //     const lv2List = lv1.childrenList

  //     if (lv2List && lv2List.length > 0 && lv2List[0].id) {
  //       return (
  //         <>
  //           <div className={style["category-divider"]}></div>
  //           <ul className={style["category-list-lv2"]} onMouseEnter={(e) => categoryMouseEnter(e, lv2List[0])}>
  //             {
  //               lv2List.map((category, index) => (
  //                 <li
  //                   key={category.cateId}
  //                   className={style["category-list-item"]}
  //                   onClick={() => handleCategoryClick(2, category)}
  //                   onMouseEnter={(e) => categoryMouseEnter(e, category)}
  //                 >
  //                   <div className={style["category-list-item-img"]}>
  //                     <img
  //                       src={imageUtil.concatImgUrlWithoutForceHttps(category.cateImageUri, 180, 180)}
  //                       alt={category.cateName}
  //                       onError={(e) => {
  //                         e.target.style.display = 'none';
  //                       }} />
  //                   </div>
  //                   <span className={style["category-list-item-cateName"]}>{category.cateName}</span>
  //                 </li>
  //               ))
  //             }
  //           </ul>
  //         </>
  //       )
  //     }
  //   }
  //   return
  // }
  // const getCategoryLv3 = () => {
  //   if (categories.length > 0) {
  //     const lv2 = categories.find(x => x.cateId === isActive.lv1)
  //     const lv2List = lv2.childrenList
  //     if (lv2List?.length > 0) {
  //       return (
  //         <>
  //           <div className={style["category-divider"]}></div>
  //           <ul className={style["category-list-lv3"]}>
  //             {
  //               lv2List.map(lv2 => (
  //                 <li className={style["category-list-lv3-li"]} key={lv2.id}>
  //                   {
  //                     lv2.id &&
  //                     <div className={style["category-list-lv2-title"]}>
  //                       <span className={style["lv2-title"]}>{lv2.cateName}</span>
  //                       <span className={style["view-all"]} onClick={() => handleCategoryClick(2, lv2)}>
  //                         View all
  //                         <ArrowRight className={style["ArrowRight"]}></ArrowRight>
  //                       </span>
  //                     </div>
  //                   }

  //                   <div className={style["category-list-lv3-items"]}>
  //                     {
  //                       lv2?.childrenList.length > 0 ? lv2.childrenList.map((lv3, index) => {
  //                         return (
  //                           <div
  //                             className={style["category-list-lv3-item"]}
  //                             key={lv3.id}
  //                             onClick={() => handleCategoryClick(3, lv3)}
  //                             onMouseEnter={(e) => categoryMouseEnter(e, lv3, !lv2.id)}
  //                           >
  //                             <div className={style["category-list-lv3-items-img"]}>
  //                               <img
  //                                 src={imageUtil.concatImgUrlWithoutForceHttps(lv3.cateImageUri, 300, 300)}
  //                                 alt={lv3.cateName}
  //                                 onError={(e) => {
  //                                   e.target.style.display = 'none';
  //                                 }} />
  //                             </div>
  //                             <div className={style["category-list-lv3-items-name"]}>
  //                               {lv3.cateName}
  //                             </div>
  //                           </div>
  //                         )
  //                       }) : null
  //                     }
  //                   </div>
  //                 </li>
  //               ))
  //             }
  //           </ul>
  //         </>
  //       )
  //     } else {
  //       // console.log('lv2是空的');
  //     }
  //   }
  //   return
  // }

  return (
    <div className={`category-list-dropdown-layer ${style["category-list-dropdown-layer"]}`}>
      {
        categories.length === 0 ?
          <div className="loading">Loading...</div> :
          <div className={style["category-list-container"]}>
            <ul className={style["category-list-lv1"]}>
              {/*没有二级类目的一级类目不显示*/}
              {!!categories.length && categories.map((firstCategory, index) => {
                if (firstCategory.childrenList && !!firstCategory.childrenList.length) {
                  return (
                    <li
                      key={firstCategory.cateId}
                      className={`${style["category-list-item"]} ${currentIndex === index ? style.active : ""}`}
                      onMouseEnter={(e) => categoryMouseEnter(e, firstCategory, index)}
                      exptag={`exp|${EXPOSURE_RECORD.Category_Main_FirstCategory_Expo}`}
                      data-exptag-config={
                        JSON.stringify({
                          useClick: false, // 是否用点击接口,默认使用曝光接口   exptag-config-useClick
                          stay_duration: 0.3, // 曝光时长    exptag-config-stay_duration
                          repeated: false, // 是否可重复曝光  exptag-config-repeated
                          area_rate: 0.01  // 漏出比例  exptag-config-area_rate
                        })
                      }
                      data-exptag-json-param={
                        JSON.stringify([{
                          firstcid: firstCategory.cateId,
                          first_category_name: firstCategory.cateName,
                          index: index
                        }])
                      }
                    >
                      <span>{firstCategory.cateName}</span>
                    </li>
                  )
                }
                return null;
              })}
            </ul>
            {getChildrenCategory()}

            {/* 二级分类样式暂时隐藏 */}
            {/* {getCategoryLv2()}
            {getCategoryLv3()} */}
          </div>
      }
    </div>
  );
}
export default CategoryDropdown;
