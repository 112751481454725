import { Response } from "../api.interface";
import newColorAxiosInstance from "../new-color-axiosInstance";
import {
  UserReviewedDetailResponse,
  PublishReplyReq,
  PublishRes,
  HelpfulReqParams,
  HandleHelpfulRes,
  DeleteCommentReqParams,
  DeleteCommentRes,
  AnonymousRes,
  DeleteReplyParams,
  DeleteReplyRes,
} from "./review.interface";
const colorAxiosInstance = newColorAxiosInstance;
// api doc: https://joyspace.jd.com/pages/OKN92AcEPEEiX1TYiRMc

// rbi.b2c.v1_1.comment.getUserCommentedList 用户已评价详情
export const fetchUserReviewDetail = async ({
  commentId,
  parentReplyId,
  skuId,
}: {
  commentId: string;
  parentReplyId: string | number;
  skuId: string;
}): Promise<UserReviewedDetailResponse | null> => {
  const res = await colorAxiosInstance.get<Response<UserReviewedDetailResponse>>(
    `content_review_get`,
    {
      params: {
        commentId,
        parentReplyId,
        skuId,
        pageSize: 30,
      },
    },
  );
  if (res?.data?.success) {
    return res?.data;
  }
  return res?.data;
};

// rbi.b2c.v1_1.comment.publishReplyComment 发表回复

export const publishReplyComment = async (params: PublishReplyReq): Promise<PublishRes | null> => {
  const res = await colorAxiosInstance.get<Response<PublishRes>>(`content_review_reply`, {
    params,
  });
  console.log("res 999", res);
  if (res?.data?.success) {
    return res?.data;
  }
  return res?.data;
};

// 点赞/取消点赞接口
export const handleHelpfulOperation = async ({
  commentId,
  operation,
}: HelpfulReqParams): Promise<HandleHelpfulRes | null> => {
  const res = await colorAxiosInstance.post<Response<HandleHelpfulRes>>(
    "content_review_like",
    undefined,
    {
      params: {
        commentId,
        operation,
      },
    },
  );
  if (res?.data?.success) {
    return res?.data;
  }
  return res?.data;
};

// 删除商品评论 rbi.b2c.v1_1.comment.deleteComment
export const handleDeleteComment = async ({
  commentId,
  skuId,
}: DeleteCommentReqParams): Promise<DeleteCommentRes | null> => {
  const res = await colorAxiosInstance.post<Response<DeleteCommentRes>>(
    "content_review_delete",
    undefined,
    {
      params: {
        commentId,
        skuId,
      },
    },
  );
  if (res?.data?.success) {
    return res?.data;
  }
  return res?.data;
};

// 匿名 rbi.b2c.v1_1.comment.setAnonymousFlag
export const handleAnonymous = async ({
  commentId,
  skuId,
  anonymous, //  1-匿名 0-非匿名
}: DeleteCommentReqParams & { anonymous: number }): Promise<AnonymousRes | null> => {
  const res = await colorAxiosInstance.post<Response<DeleteCommentRes>>(
    "content_review_setAnonymous",
    undefined,
    {
      params: {
        commentId,
        skuId,
        anonymous,
      },
    },
  );
  if (res?.data?.success) {
    return res?.data;
  }
  return res?.data;
};
// 删除商品评论 rbi.b2c.v1_1.comment.deleteComment
export const handleDeleteReply = async ({
  skuId,
  replyId,
}: DeleteReplyParams): Promise<DeleteReplyRes | null> => {
  const res = await colorAxiosInstance.post<Response<DeleteReplyRes>>(
    "content_review_deleteReply",
    undefined,
    {
      params: {
        replyId,
        skuId,
      },
    },
  );
  if (res?.data?.success) {
    return res?.data;
  }
  return res?.data;
};

// 上传视频
export const uploadVideo = (file: any, data: any): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await colorAxiosInstance.post<Response<string>>(
        "content_review_getVideoUrl",
        undefined,
        {
          params: {
            fileSize: file[0]?.size,
            ...data,
          },
        },
      );
      if (res && res?.data?.success) {
        return uploadVideoFetch(file[0], res?.data?.data, resolve, reject);
      }
    } catch (error) {
      reject(error);
    }
  });
};

const uploadVideoFetch = async (file: any, data: any, resolve: any, reject: any) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const url = URL.createObjectURL(file);
    const response = await fetch(data?.uploadUrl, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Failed to upload video");
    }

    const result = await responseon();
    resolve({ ...result, videoUrl: url, videoId: data?.vid });
  } catch (error) {
    reject(error);
  }
};

// 获取举报原因
export const getReportReason = async (): Promise<any | null> => {
  const res = await colorAxiosInstance.get<Response<any>>(
    "content_review_getReportReasons",
    undefined,
    {},
  );
  if (res?.data?.success) {
    return res?.data?.data;
  }
  return null;
};

// 举报评论
export const reportComment = async (params: any) => {
  const res = await colorAxiosInstance.post<Response<any>>("content_review_report", undefined, {
    params,
  });
  if (res?.data?.success) {
    return res?.data?.data;
  }
  return null;
};
