import _ from "lodash";
export enum SortType {
  sort_default = "sort_default",
  sort_dredisprice_asc = "sort_dredisprice_asc",
  sort_dredisprice_desc = "sort_dredisprice_desc",
}

export type Props = {
  searchParams: {
    k?: string;
    sortType?: SortType;
    page?: string;
    [key: string]: string | undefined;
  };
  basePath: string;
  isAddon?: boolean;
};

interface BuildSearchUrlOptions {
  merge?: boolean;
  basePath?: string;
}

// merge: true表示newParams里的key要和searchParams合并，false表示newParams覆盖searchParams
export const buildSearchUrl = (searchParams: Props["searchParams"], newParams: Props["searchParams"], options: BuildSearchUrlOptions = {}) => {
  const { merge = false, basePath = "/s" } = options;
  let paramsObject = {};
  if (newParams.filterByExpandName) {
    const originExpandName =
      searchParams.filterByExpandName
        ?.split("**")
        ?.map((v) => v.split("::"))
        .map((v) => [v[0].split("--"), v[1]]) ?? [];
    const newExpandName =
      newParams.filterByExpandName
        .split("**")
        .map((v) => v.split("::"))
        .map((v) => [v[0].split("--"), v[1]]) ?? [];
    const mergedExpandName = _.groupBy([...originExpandName, ...newExpandName], (v) => v[1]);
    const result = [];
    for (const key in mergedExpandName) {
      const values = mergedExpandName[key].map((v) => v[0]);
      const value = _.xor(values[0], values[1] ?? []);
      value.filter((v) => v).length && result.push(`${value.join("--")}::${key}`);
    }
    newParams.filterByExpandName = result.join("**");
  } else if (merge) {
    for (const key in newParams) {
      if (searchParams[key] === undefined) {
        continue;
      } else {
        const originParams = searchParams[key].split("--").filter((v) => v);
        if (originParams.includes(newParams[key] as string)) {
          newParams[key] = _.pull(originParams, newParams[key]).join("--");
        } else {
          newParams[key] = _.uniq([...originParams, newParams[key]]).join("--");
        }
      }
    }
  }
  paramsObject = Object.assign({}, searchParams, newParams);
  paramsObject = _.omitBy(paramsObject, (v) => v === undefined || v === "");
  return `${basePath}?${new URLSearchParams(paramsObject as any).toString()}`;
};

// 埋点曝光露出参数
export const EXPCONFIG_FAST = JSON.stringify({
  useClick: false,
  repeated: false,
  stay_duration: 0.3,
  area_rate: 0.01,
});

export const EXPCONFIG_DELAY = JSON.stringify({
  useClick: false,
  repeated: false,
  stay_duration: 0.3,
  area_rate: 0.5,
});

export function createTrackParams<T>(params: T): string {
  return JSON.stringify(params);
}

const expandShowStyle = (obj: any) => {
  const showStyleObj = obj?.showStyle ? JSON.parse(obj?.showStyle) : {};
  return { ...obj, ...showStyleObj };
};

//获取商品促销标签的文案；promotionType的含义：优惠券: 2  ,单品促销: 1, 总价促销: 10  ,时效标签: 3
export const getPromotionObj = (promotions: any) => {
  let promotionObj = {
    couponInfo: "-100",
    single_promotion: "-100",
    amount_promotion: "-100",
    time_label: "-100",
  };
  if (promotions) {
    promotions.map((promotion: any) => {
      promotion = expandShowStyle(promotion);
      switch (promotion.promotionType) {
        case 1:
          promotionObj.single_promotion = `${promotion.text}${promotion?.endTime || ""}`;
          break;
        case 10:
          promotionObj.amount_promotion = `${promotion.text}${promotion?.endTime || ""}`;
          break;
        case 2:
          promotionObj.couponInfo = `${promotion.text}${promotion?.endTime || ""}`;
          break;
        case 3:
          promotionObj.time_label = `${promotion.text}${promotion?.endTime || ""}`;
          break;
      }
    });
    return promotionObj;
  }
  return promotionObj;
};
