import { useState } from "react";
import { observer } from "mobx-react";
import cn from "clsx";
import styles from "./index.module.scss";

interface LoadingIconProps {
  style?: React.CSSProperties;
  className?: string;
  type?: string; // laoding颜色 white | black default: white
}

const UKLoadingIcon: React.FC<LoadingIconProps> = observer(({ className, style, type }) => {
  return (
    <div className={cn(styles.loadingIcon, className)} style={style}>
      <img src={
        type === "black" ? "https://st.joy-sourcing.com/website/other/loadingBlack.png"
          : "https://st.joy-sourcing.com/website/other/loadingNew.png"
      } />
    </div>
  );
});

export default UKLoadingIcon;
