import React from 'react';
import ReactDOM from "react-dom/client"; // 使用 createRoot API
import { Backdrop } from "@mui/material";
import UKLoadingIcon from "../UKLoadingIcon";

// eslint-disable-next-line react/prop-types
const MaskLoadingComponent = ({ open }) => {
  return (
    <Backdrop
      sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
      open={open}
      id="loading-mask"
    >
      <UKLoadingIcon color="inherit" />
    </Backdrop>
  );
};

const UKMaskLoading = (() => {
  let root = null;

  const close = () => {
    const maskLoadingElement = document.getElementById("loading-mask");
    maskLoadingElement.setAttribute("style", "display: none");
  };

  const show = () => {
    const maskLoadingElement = document.getElementById("loading-mask");
    if (!root) {
      root = ReactDOM.createRoot(maskLoadingElement);
      root.render(renderLoading());
    } else {
      maskLoadingElement.setAttribute("style", "display: block");
    }
  };

  const renderLoading = () => {
    return (
      <>
        <MaskLoadingComponent open={true} />
      </>
    );
  };

  return { show, close };
})();

export default UKMaskLoading;
