// 埋点页面链接：https://stream.jd.com/ddrx/#/requirement/25424
// WARNING!!! 请勿手动修改此文件，如需修改请在子午线平台变更后，执行npm run track-init 25424 
import { eventTrackingUtils } from '@/utils/eventTrackingUtils'
interface IExpoTrackParamsRecord {
  /** 地址删除曝光 */
NeworderAddressDelete_Expo: undefined
  /** 地址删除取消曝光 */
NeworderAddressDelete_Cancel_Expo: undefined
}

const EXPOSURE_RECORD: Readonly<Record<keyof IExpoTrackParamsRecord,string>> = Object.freeze({
/** 地址删除曝光 */
  NeworderAddressDelete_Expo:'NeworderAddressDelete_Expo',
/** 地址删除取消曝光 */
  NeworderAddressDelete_Cancel_Expo:'NeworderAddressDelete_Cancel_Expo',
})

interface IClickTrackParamsRecord {
  /** 地址删除 */
NeworderAddressDelete: undefined
  /** 地址删除取消 */
NeworderAddressDelete_Cancel: undefined
}

const sendClickTrack = {
  /** 点击事件上报-地址删除 */
NeworderAddressDelete: () => {
    eventTrackingUtils.submitCL({event_id:'NeworderAddressDelete'});
  },
  /** 点击事件上报-地址删除取消 */
NeworderAddressDelete_Cancel: () => {
    eventTrackingUtils.submitCL({event_id:'NeworderAddressDelete_Cancel'});
  },
}

export {
  EXPOSURE_RECORD,
  sendClickTrack,
}
export type {
  IExpoTrackParamsRecord,
}