import newColorAxiosInstance from "../new-color-axiosInstance";

// 用户开关设置
export const getOrSetSwitches = (requestData: any): Promise<any> => {
  return newColorAxiosInstance
    .post<any>(`user_settings_getOrSetSwitches`, undefined, {
      params: requestData,
    })
    .then((response: any) => response.data)
    .catch((error: any) => error);
};