import "./index.scss";
import { useEffect, useMemo, useState } from "react";
import MessageDetail from "./components/messageDetail";
import MessageDialog from "./components/messageDialog";
import { getMsgPreviewApi, updateReadedStatusApi, oneKeyReadApi } from "@/common-components-src/api/message/message.api";
import UKToast from '@/common-components-src/components/UKToast/index';
import { MessagePreviewResponse, MessageTitleItem, MessageTypeEnum } from "@/common-components-src/api/message/message.interface";
import { TitleConstData, TitleProps } from "./components/titleItem";
import UKPageError from "@/common-components-src/components/UKPageError";
import to from "await-to-js";

export interface DetailData {
  showDetail: boolean;
  type: number;
  title: string;
}

export default function Message({open, onClose, onUpdate}: {open: boolean, onClose:()=>void, onUpdate:(num: number)=>void}) {

  const defaultData = {showDetail: false, type: 0, title: ""};
  const [error, setError] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isInitMessage, setIsInitMessage] = useState<boolean>(false);
  const [isInitService, setIsInitService] = useState<boolean>(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [detailData, setDetailData] = useState<DetailData>(defaultData);
  const [totalNum, setTotalNum] = useState<number>(0);
  const [messageNum, setMessageNum] = useState<number>(0);
  const [serviceNum, setServiceNum] = useState<number>(0);
  const [previewData, setPreviewData] = useState<MessagePreviewResponse|null>();
  const [floatTitleList, setFloatTitleList] = useState<TitleProps[]>([]);
  const [fixedTitleList, setFixedTitleList] = useState<TitleProps[]>([
    {
      type: MessageTypeEnum.services,
      title: "Customer Service",
      content: "7*24 online support.",
      time: "",
      num: 0,
      showDot: false,
      msgId: "",
      orderId: "",
      promoId: ""
    },
    {
      type: MessageTypeEnum.orders,
      title: "Order & Shipping",
      content: "Order status and logistics updates.",
      time: "",
      num: 0,
      showDot: false,
      msgId: "",
      orderId: "",
      promoId: ""
    },
    {
      type: MessageTypeEnum.aftersale,
      title: "After-sale",
      content: "Order after-sales status and progress updates.",
      time: "",
      num: 0,
      showDot: false,
      msgId: "",
      orderId: "",
      promoId: ""
    },
  ]);

  const onPrevDataChange = (previewData: MessagePreviewResponse) => {
    if(previewData){
      updateMessageNum(previewData);
      if(previewData.topListMessageMap)
        updateFixedTitleData(previewData.topListMessageMap);
      if(previewData.messageList)
        updateFloatTitleData(previewData.messageList);
    }
  }

  const init = async () => {
    setIsPageLoading(true);
    const [error, res] = await to(getMsgPreviewApi());
    if(error){
      setError(true);
      setPreviewData(null);
    }
    else if(res && res.code === "200"){
      setError(false);
      onPrevDataChange(res.data);
      setPreviewData(res.data);
    }
    setIsPageLoading(false);
  };

  const initServiceNum = () => {
    if(window && (window as any).zE){
      (window as any).zE("messenger:on", "unreadMessages", function (count: number) {
        setServiceNum(count)
        setIsInitService(true)
        updateServiceTitleData(count)
        if(isInitMessage){
          setTotalNum(count+messageNum);
        }
      })
    }
  }

  const updateMessageNum = (previewData: MessagePreviewResponse) => {
    let total = 0;
    if(previewData && previewData.topListMessageMap){
      Object.values(previewData.topListMessageMap).map((item: MessageTitleItem) => {
        if(!item.redPoint){
          total += item.noticeNum;
        }
      });
    }
    if(previewData && previewData.messageList){
      previewData.messageList.forEach((item: MessageTitleItem) => {
        if(!item.redPoint){
          total += item.noticeNum;
        }
      })
    }
    if(isInitService){
      setTotalNum(total+serviceNum);
    }
    setMessageNum(total)
    setIsInitMessage(true)
  };

  const updateFixedTitleData = (fixedList: Record<number, MessageTitleItem>) => {
    const myFixedList = [...fixedTitleList]
    Object.values(fixedList).map((item: MessageTitleItem) => {
      myFixedList.forEach((fixedItem) => {
        if(fixedItem.type == item.accountType){
          fixedItem.num = item.noticeNum;
          fixedItem.showDot = item.redPoint;
          fixedItem.content = item.msgEntityVo ? item.msgEntityVo.title : item.replaceContent;
          fixedItem.time = item.msgEntityVo && item.msgEntityVo.formatTime;
          fixedItem.msgId = item.msgEntityVo && item.msgEntityVo.msgId;
          fixedItem.orderId = (item.msgEntityVo && item.msgEntityVo.extraAttribute && String(item.msgEntityVo.extraAttribute.orderId)) || "";
          fixedItem.promoId = (item.msgEntityVo && item.msgEntityVo.extraAttribute && String(item.msgEntityVo.extraAttribute.promoId)) || "";
        }
      })
    });
    setFixedTitleList(myFixedList);
  }

  const updateServiceTitleData = (num: number = 0) => {
    const myFixedList = [...fixedTitleList]
      myFixedList.forEach((fixedItem) => {
        if(fixedItem.type == MessageTypeEnum.services){
          fixedItem.num = num;
        }
    });
    setFixedTitleList(myFixedList);
  }

  const updateFloatTitleData = (floatList: MessageTitleItem[]) => {
    const myFloatList: TitleProps[] = []
    floatList.forEach((item: MessageTitleItem) => {
      const data = {
        type: item.accountType,
        num: item.noticeNum,
        showDot: item.redPoint,
        title: (TitleConstData[item.accountType] && TitleConstData[item.accountType].title) || (item.msgEntityVo && item.msgEntityVo.accountName),
        content: item.msgEntityVo && item.msgEntityVo.content,
        time: item.msgEntityVo && item.msgEntityVo.formatTime,
        msgId: item.msgEntityVo && item.msgEntityVo.msgId,
        orderId: (item.msgEntityVo && item.msgEntityVo.extraAttribute && String(item.msgEntityVo.extraAttribute.orderId)) || "",
        promoId: (item.msgEntityVo && item.msgEntityVo.extraAttribute && String(item.msgEntityVo.extraAttribute.promoId)) || "",
      }
      myFloatList.push(data)
    });
    setFloatTitleList(myFloatList);
  }

  const onOpenDetail = (data: DetailData) => {
    switch(data.type){
      case MessageTypeEnum.services:
        onCloseDialog();
        if(window && (window as any).zE)
          (window as any)?.zE("messenger", "open");
        break;
      default:
        onReadedStatusChange(data.type);
        setDetailData(data);
    }
  }

  const onBackToHome = (isModify: boolean) => {
    if(isModify){
      init();
      initServiceNum();
    }
    setDetailData(defaultData);
  }

  const onCloseDialog = () => {
    setIsOpen(false)
    setDetailData(defaultData);
    onClose && onClose()
  }

  // 消息按类型已读
  const onReadedStatusChange = async (type: number) => {
    if (previewData == null) {
      return;
    }
    if(previewData.topListMessageMap && previewData.topListMessageMap[type] && previewData.topListMessageMap[type].noticeNum == 0){
      return;
    }
    if(previewData.messageList){
      for(const item of previewData.messageList){
        if(item.accountType == type && item.noticeNum == 0){
          return;
        }
      }
    }
    const res = await updateReadedStatusApi(type);
    if (res?.data) {
      const myData = { ...previewData };
      if (myData.topListMessageMap && myData.topListMessageMap[type]) {
        myData.topListMessageMap[type].noticeNum = 0;
      }
      else{
        if(myData.messageList){
          myData.messageList.forEach((item: MessageTitleItem) => {
            if(item.accountType == type){
              item.noticeNum = 0;
            }
          })
        }
      }
      onPrevDataChange(myData);
      setPreviewData(myData);
    }
  };

  // 一键消息已读
  const oneKeyReaded = async () => {
    if (totalNum <= 0) {
      UKToast.show({
        content: "There are currently no new messages.",
        icon: "warning",
      });
      return;
    }
    const res = await oneKeyReadApi();
    if (res?.data && previewData) {
      const myData = { ...previewData };
      if(myData && myData.topListMessageMap){
        Object.values(myData.topListMessageMap).map((value) => {
          value.noticeNum = 0;
        });
      }
      if(myData && myData.messageList){
        myData.messageList.forEach((item: MessageTitleItem) => {
          item.noticeNum = 0;
        })
      }
      onPrevDataChange(myData);
      setPreviewData(myData);
    }
  };

  useEffect(() => {
    setIsOpen(open)
  }, [open]);

  useEffect(() => {
    onUpdate && onUpdate(totalNum)
  }, [totalNum]);

  useEffect(() => {
    init();
    initServiceNum();
  }, []);

  if(!isOpen){
    return null;
  }

  if (error) {
    return <div className="message-frame page-error">
      <UKPageError
        clickTryAgain={() => {
          init();
          initServiceNum();
        }}
        text="Loading up, please try again"
        btnText="Try again"
      />
    </div>
  }

  return (
    detailData && detailData.showDetail
    ? 
    <MessageDetail type={detailData.type} title={detailData.title} onBack={onBackToHome} onClose={onCloseDialog}/> 
    : 
    <MessageDialog data={{totalNum, fixedTitleList, floatTitleList, isPageLoading}} onOpenDetail={onOpenDetail} onClose={onCloseDialog} oneKeyRead={oneKeyReaded} />
  );
};
