import { useState, useEffect } from "react";
import { HelpfulProps } from "@/common-components-src/api/reviews/review.interface";
import { handleHelpfulOperation } from "@/common-components-src/api/reviews/review.api";
import styles from "./reviews.module.scss";
import cn from "clsx";
// import { customElement, CUSTOM_DETAIL_OPTS_EVENT_HELPFUL } from "./const";
import UKToast from "@/common-components-src/components/UKToast/index";
import eventEmitter from "../utils/eventEmitterUtil";
import EVENT_TYPE from "@/common-components-src/const/eventType";
import ZanIcon  from "@/common-components-src/assets/icons/zan.svg";
import ZanActiveIcon  from "@/common-components-src/assets/icons/zan-on.svg";
import { sendClickTrack as reviewDetailSendClickTrack } from "@/tracks/25347";

interface HelpProps {
  data: HelpfulProps;
  pageType?: string;
  expConfig?: any;
}

export default function HelpfulButton({ data, pageType, expConfig }: HelpProps) {
  const { helpfulNum, commentId, currentIsHelp } = data || {};
  const [helpNum, setHelpNum] = useState<string>(helpfulNum || "0");
  const [helpfulState, setHelpfulState] = useState<boolean>(currentIsHelp);

  const onHandle = async (event: React.MouseEvent) => {
    const res = await handleHelpfulOperation({
      commentId: commentId,
      operation: helpfulState ? 2 : 1,
    });
    if (res?.data?.result) {
      setHelpfulState(!helpfulState);
      const { likeCount } = res?.data || {};
      setHelpNum(likeCount !== "0" ? likeCount || "0" : "0");
      eventEmitter.emit(EVENT_TYPE.CUSTOM_DETAIL_OPTS_EVENT_HELPFUL, {
        detail: {
          data: {
            [commentId]: {
              helpfulState: !helpfulState,
              helpfulNum: likeCount,
            },
          },
        },
      });
      // customElement.dispatchEvent(
      //   new CustomEvent(CUSTOM_DETAIL_OPTS_EVENT_HELPFUL, {
      //     detail: {
      //       data: {
      //         [commentId]: {
      //           helpfulState: !helpfulState,
      //           helpfulNum: likeCount,
      //         },
      //       },
      //     },
      //   }),
      // );
    } else {
      UKToast.show({
        icon: "error",
        content: res?.msg || "Oops! Something went wrong. Please try again.",
      });
    }
    event.preventDefault();
  };
  const handleEvent = (e: any) => {
    const { detail } = e;
    if (detail?.data?.[commentId]) {
      const { helpfulNum, helpfulState } = detail?.data?.[commentId] || {};
      setHelpNum(helpfulNum || "0");
      setHelpfulState(helpfulState);
    }
  };
  useEffect(() => {
    eventEmitter.on(EVENT_TYPE.CUSTOM_DETAIL_OPTS_EVENT_HELPFUL, handleEvent);
    return () => {
        eventEmitter.off(EVENT_TYPE.CUSTOM_DETAIL_OPTS_EVENT_HELPFUL, handleEvent);
    };
    // customElement.addEventListener(CUSTOM_DETAIL_OPTS_EVENT_HELPFUL, handleEvent);
    // return () => {
    //   customElement.removeEventListener(CUSTOM_DETAIL_OPTS_EVENT_HELPFUL, handleEvent);
    // };
  }, []);
  return (
    <div className={cn(styles.helpfulBtn, helpfulState ? styles.active : "")} 
      onClick={(e: any)=> {
        onHandle(e);
        switch (pageType) {
          case "reviewDetail":
            reviewDetailSendClickTrack.Evaluate_CommentDetail_Card_Btn({button_name: "1", commentId: `${commentId}`, skuid: `${data?.skuId}` || "-100"})
            break;
        
          default:
            break;
        }
      }}
      exptag={expConfig?.help?.expTag}
      data-exptag-json-param={JSON.stringify(expConfig?.help?.data)}
      data-exptag-config={expConfig?.help?.config}
    >
      {helpfulState ? <ZanActiveIcon className={cn(styles.zanIcon, styles.mar)}/> : <ZanIcon className={cn(styles.zanIcon, styles.mar)}/>}
      {`Helpful${helpNum !== "0" ? "(" + helpNum + ")" : ""}`}
    </div>
  );
}
