/* eslint-disable @typescript-eslint/no-explicit-any */
import newColorAxiosInstance from "../new-color-axiosInstance";
import { GetCartRequest, CartData, TypeDetailInfo, SystemInfo } from "./cart.interface";

/*
 * https://joyspace.jd.com/pages/iFAEYMJLLB1eAX3tEDDH
 * @interface Data
 */

interface Response<T> {
  success: boolean;
  code: string;
  msg: string;
  data: T;
  traceId: string;
  result: boolean;
}

interface TypeData {
  resultInfo: TypeDetailInfo;
  systemInfo: SystemInfo;
}

// 加入购物车 API 方法
export const AddCart = (requestData: GetCartRequest): Promise<CartData> => {
  return newColorAxiosInstance
    .get<CartData>(`trade_cart_addItem`, {
      params: { ...requestData },
    })
    .then((response: any) => response?.data)
    .catch((error: any) => error);
};

// 选择其他商品类型 API 方法
export const selectOtherProType = (requestData: GetCartRequest): Promise<TypeData> => {
  return newColorAxiosInstance
    .get<TypeData>(`trade_item_getLite`, {
      params: { ...requestData },
    })
    .then((response: any) => response?.data?.data)
    .catch((error: any) => error);
};


