import { imageUtil } from "@/lib/jdiClientUtils";

/**
 *
 * @param prefix
 */

type ImgParams = {
    width: number
    height: number
    quality: number
}

export const cnHelper = (prefix: string) => {
    return function (className: string) {
        return `${prefix}-${className}`
    }
}


export const debounce = (fn: () => void, wait: number) => {
    let timeout: any = null;
    return function () {
        if (timeout !== null) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(fn, wait);
    };
};

export const avatarFn = (v: any) => v ? (v.startsWith("http://") || v.startsWith("https://") || v.startsWith("//")  ? v : imageUtil.concatImgUrlWithoutForceHttps(v, 100, 0, 100, process.env.NEXT_PUBLIC_IMAGE_CDN_DOMAIN?.split(","))) : "";

// 将跳转帮助中心title转化为小写用中划线连接
export const getTitle = (title: string) => {
  return title.toLowerCase()
    .replace(/[^\w-]+|_/g, '-')
    .replace(/^-+|-+$/g, '')
    .replace(/-+/g, '-');
}