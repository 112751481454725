// 埋点页面链接：https://stream.jd.com/ddrx/#/requirement/26438
// WARNING!!! 请勿手动修改此文件，如需修改请在子午线平台变更后，执行npm run track-init 26438 
import { eventTrackingUtils } from '@/utils/eventTrackingUtils'
interface IExpoTrackParamsRecord {
  /** 商品规格弹层曝光 */
ProductSpecLayer_Expo: {
/** 商品id  */
skuid: string,
/** 商品spuid  */
spuid: string}
}

const EXPOSURE_RECORD: Readonly<Record<keyof IExpoTrackParamsRecord,string>> = Object.freeze({
/** 商品规格弹层曝光 */
  ProductSpecLayer_Expo:'ProductSpecLayer_Expo',
})

interface IClickTrackParamsRecord {
  /** 商品规格弹层-更新确认按钮点击 */
ProductSpecLayer_MainBtn_Click: {
/** 商品id  */
skuid: string,
/** 商品spuid  */
spuid: string}
}

const sendClickTrack = {
  /** 点击事件上报-商品规格弹层-更新确认按钮点击 */
ProductSpecLayer_MainBtn_Click: (json_param: IClickTrackParamsRecord['ProductSpecLayer_MainBtn_Click']) => {
    eventTrackingUtils.submitCL({event_id:'ProductSpecLayer_MainBtn_Click',json_param});
  },
}

const sendKeyActionTrack = {
  typeLayer_Expo: (json_param: IExpoTrackParamsRecord['ProductSpecLayer_Expo']) => {
    eventTrackingUtils.submitExp({ event_id: `ProductSpecLayer_Expo`, json_param })
  },
}

export {
  EXPOSURE_RECORD,
  sendClickTrack,
  sendKeyActionTrack
}
export type {
  IExpoTrackParamsRecord,
}