// 埋点页面链接：https://stream.jd.com/ddrx/#/requirement/25630
// WARNING!!! 请勿手动修改此文件，如需修改请在子午线平台变更后，执行npm run track-init 
import { eventTrackingUtils } from '@/utils/eventTrackingUtils'
interface IExpoTrackParamsRecord {
  /** 侧边栏消息曝光 */
Sidebar_Message_Expo: undefined
  /** 侧边栏客服曝光 */
Sidebar_Customer_Expo: undefined
  /** 侧边栏反馈曝光 */
Sidebar_Feedback_Expo: undefined
  /** 消息列表曝光 */
Message_List_MsgListExpo: {
/** 消息数量  */
msgNum: string}
  /** 客服弹窗曝光 */
Customer_Service_Pop_Expo: undefined
  /** 反馈弹窗曝光 */
Feedback_Pop_Expo: undefined
  /** 消息曝光 */
Message_MsgDetail_MsgExpo: {
/** 消息id  */
msgId: string,
/** 订单id  */
orderId: string,
/** 促销id  */
promId: string,
/** 位置号  */
pos: number,
/** 消息类别 系统通知-null,活动公告-null,学院动态-null,学术通动态-null */
msgType: | '系统通知'| '活动公告'| '学院动态'| '学术通动态'}
}

const EXPOSURE_RECORD: Readonly<Record<keyof IExpoTrackParamsRecord,string>> = Object.freeze({
/** 侧边栏消息曝光 */
  Sidebar_Message_Expo:'Sidebar_Message_Expo',
/** 侧边栏客服曝光 */
  Sidebar_Customer_Expo:'Sidebar_Customer_Expo',
/** 侧边栏反馈曝光 */
  Sidebar_Feedback_Expo:'Sidebar_Feedback_Expo',
/** 消息列表曝光 */
  Message_List_MsgListExpo:'Message_List_MsgListExpo',
/** 客服弹窗曝光 */
  Customer_Service_Pop_Expo:'Customer_Service_Pop_Expo',
/** 反馈弹窗曝光 */
  Feedback_Pop_Expo:'Feedback_Pop_Expo',
/** 消息曝光 */
  Message_MsgDetail_MsgExpo:'Message_MsgDetail_MsgExpo',
})

interface IClickTrackParamsRecord {
  /** 侧边栏消息点击 */
Sidebar_Message_Click: undefined
  /** 侧边栏客服点击 */
Sidebar_Customer_Click: undefined
  /** 侧边栏反馈点击 */
Sidebar_Feedback_Click: undefined
  /** 消息列表点击 */
Message_List_MsgList: {
/** 消息类别 系统通知-null,活动公告-null,学院动态-null,学术通动态-null */
msgType: | '系统通知'| '活动公告'| '学院动态'| '学术通动态',
/** 消息数量  */
msgNum: string}
  /** 客服弹窗点击 */
Customer_Service_Pop: undefined
  /** 反馈弹窗点击 */
Feedback_Pop: {
/** 按钮名称  */
button_name: string}
  /** 消息点击 */
Message_MsgDetail_Msg: {
/** 消息id  */
msgId: string,
/** 订单id  */
orderId: string,
/** 促销id  */
promId: string,
/** 位置号  */
pos: number,
/** 消息类别 系统通知-null,活动公告-null,学院动态-null,学术通动态-null */
msgType: | '系统通知'| '活动公告'| '学院动态'| '学术通动态'}
  /** 删除消息 */
Message_MsgDetail_DeleteMsg: {
/** 消息id  */
msgId: string,
/** 订单id  */
orderId: string,
/** 促销id  */
promId: string,
/** 位置号  */
pos: number,
/** 消息类别 系统通知-null,活动公告-null,学院动态-null,学术通动态-null */
msgType: | '系统通知'| '活动公告'| '学院动态'| '学术通动态'}
}

const sendClickTrack = {
  /** 点击事件上报-侧边栏消息点击 */
Sidebar_Message_Click: () => {
    eventTrackingUtils.submitCL({event_id:'Sidebar_Message_Click'});
  },
  /** 点击事件上报-侧边栏客服点击 */
Sidebar_Customer_Click: () => {
    eventTrackingUtils.submitCL({event_id:'Sidebar_Customer_Click'});
  },
  /** 点击事件上报-侧边栏反馈点击 */
Sidebar_Feedback_Click: () => {
    eventTrackingUtils.submitCL({event_id:'Sidebar_Feedback_Click'});
  },
  /** 点击事件上报-消息列表点击 */
Message_List_MsgList: (json_param: IClickTrackParamsRecord['Message_List_MsgList']) => {
    eventTrackingUtils.submitCL({event_id:'Message_List_MsgList',json_param});
  },
  /** 点击事件上报-客服弹窗点击 */
Customer_Service_Pop: () => {
    eventTrackingUtils.submitCL({event_id:'Customer_Service_Pop'});
  },
  /** 点击事件上报-反馈弹窗点击 */
Feedback_Pop: (json_param: IClickTrackParamsRecord['Feedback_Pop']) => {
    eventTrackingUtils.submitCL({event_id:'Feedback_Pop',json_param});
  },
  /** 点击事件上报-消息点击 */
Message_MsgDetail_Msg: (json_param: IClickTrackParamsRecord['Message_MsgDetail_Msg']) => {
    eventTrackingUtils.submitCL({event_id:'Message_MsgDetail_Msg',json_param});
  },
  /** 点击事件上报-删除消息 */
Message_MsgDetail_DeleteMsg: (json_param: IClickTrackParamsRecord['Message_MsgDetail_DeleteMsg']) => {
    eventTrackingUtils.submitCL({event_id:'Message_MsgDetail_DeleteMsg',json_param});
  },
}

export {
  EXPOSURE_RECORD,
  sendClickTrack,
}
export type {
  IExpoTrackParamsRecord,
}