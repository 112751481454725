export default {
  // 需要添加每个模块的模块名称，作为特殊标识，防止i18n字段名称冲突，最好用{}对象的这种形式，增加可读性
  demoTitle: "My orders {{name}} {{title}}",
  demoList: {
    title: "1212",
    icon: "<strong>{{username}}</strong>",
    iconPath: "https://st.joy-sourcing.com/website/other/safe.png",
  },
  benefitTitle: "Free Shipping",
  footerItemTitle: "JoyBuy",
};
