import { ReactNode } from "react";
import styles from "./reviews.module.scss";
import cn from "clsx";
import StarIcon from "@/common-components-src/assets/icons/start.png"
import StarHalfIcon from "@/common-components-src/assets/icons/star-half.png"
import StarEmptyIcon from "@/common-components-src/assets/icons/start-off.png"
interface Props {
  value: number;
  extra?: ReactNode;
  hiddenScoreNum?: boolean;
}

export default function RatingDisplay({ value, extra, hiddenScoreNum = false }: Props) {
  const fullStars = Math.floor(value);
  const hasHalfStar = value % 1 !== 0;
  const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);
  
  const stars = [];
  for (let i = 0; i < fullStars; i++) {
    stars.push(<img src={StarIcon?.src} key={i} className={cn(styles.star, styles.full)}></img>); // 实心星星
  }
  if (hasHalfStar) {
    stars.push(<img src={StarHalfIcon?.src} key="half" className={cn(styles.star, styles.half)}></img>); // 半颗星星
  }
  for (let i = 0; i < emptyStars; i++) {
    stars.push(<img src={StarEmptyIcon?.src} key={i + fullStars + 1} className={cn(styles.star, styles.empty)}></img>); // 空心星星
  }

  return (
    <div className={styles.commentScoreRating}>
      <div className={styles.starIconBox}>{stars}</div>
      <div className={styles.basicFont}>
        {!hiddenScoreNum && <span className={styles.scoreIcon}>4.8</span>}
        {extra}
      </div>
    </div>
  );
}
