import "../index.scss";
import { DetailData } from "../index";
import TitleItem, { TitleProps } from "./titleItem"
import UKPageLoading from "@/common-components-src/components/UKPageLoading";
import CloseIcon  from "../assets/close-icon.svg";
import OneClearIcon  from "../assets/one-clear.svg";

interface DataProps{
  totalNum: number;
  isPageLoading: boolean;
  fixedTitleList: TitleProps[],
  floatTitleList: TitleProps[],
}

interface Props{
  data: DataProps;
  onOpenDetail: (deatilData: DetailData) => void;
  onClose: () => void;
  oneKeyRead: () => void;
}

const MessageDialog = ({data, onOpenDetail, onClose, oneKeyRead}: Props) => {

  const { totalNum, fixedTitleList, floatTitleList, isPageLoading } = data;

  const onItemClick = (type: number, title: string) => {
    const data = {
      showDetail: true,
      type: type,
      title: title
    }
    onOpenDetail(data);
  }

  const formatMessageNum = (num: number) => {
    return num >= 100 ? "(99+)" : num > 0 ? `(${num})` : ""
  };

  const handleClose = () => {
    onClose();
  }
  
  return (
    <div className="message-dialog message-frame">
      <div className="title">
        {/* <OneClearIcon className="cursor hover" onClick={oneKeyRead} /> */}
        <span>Messages{formatMessageNum(totalNum)}</span>
        <CloseIcon className="arrow-right cursor hover" onClick={handleClose}/>
      </div>
      {
        isPageLoading
        ?
        <UKPageLoading style={{height: "calc(100% - 44px)", background: "#F5F6FA"}}/>
        :
        <div className="list">
          <div className="fixedlist">
            { fixedTitleList.map((item: TitleProps, index: number) => {
              return <TitleItem key={index} data={item} onClick={onItemClick} />
            })}
          </div>
          { floatTitleList && !!floatTitleList.length &&
            <div className="floatlist">
              { floatTitleList.map((item: TitleProps, index: number) => {
                return <TitleItem key={index} data={item} onClick={onItemClick} />
              })}
            </div>
          }
        </div>
      }
    </div>
  );
};
export default MessageDialog;
