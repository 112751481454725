/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Dialog,
  Typography,
  Checkbox,
  Radio,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
import to from "await-to-js";
import styles from "./index.module.scss";
import { updateAddress, deleteAddress, getUserAddressList, deleteDraftAddress } from "@/common-components-src/api/address/address.api";
import { Address, PostCodeAddress } from "@/common-components-src/api/address/address.interface";
import AddressAdd from "@/common-components-src/js/address/icons/AddressAdd.svg";
import AddressEdit from "@/common-components-src/js/address/icons/AddressEdit.svg";
import AddressDelete from "@/common-components-src/js/address/icons/AddressDelete.svg";
import VectorDown from "@/common-components-src/assets/icons/VectorDown.svg";
import NewOrEditAddress from "@/common-components-src/js/address/addressCreate";
import { setAddress, clearAddress, initAddress } from "@/utils/addressUtils";
import { curPostCodeOrAddress, setDefaultLocalAddress } from "@/common-components-src/js/utils/addressRequestUtils";
import { getAddressStore } from "../AddressStore";
import UKDialog from "@/common-components-src/components/UKDialog/index.tsx";
import UKToast from "@/common-components-src/components/UKToast/index.tsx"
import UKPageLoading from "@/common-components-src/components/UKPageLoading";
import UKPageError from "@/common-components-src/components/UKPageError";
import UKPageNothing from "@/common-components-src/components/UKPageNothing";
import UKTruncatedTooltip from "@/common-components-src/components/UKTruncatedTooltip/index"
import UKCopy from "@/common-components-src/components/UKCopy";
import Grid from "@mui/material/Grid2";
import PAGE_TYPE from "../const/pageType";
import { sendPv, sendClickTrack, EXPOSURE_RECORD } from "@/tracks/25422";
import { sendClickTrack as sendClickTrackDelete, EXPOSURE_RECORD as DELETE_EXPOSURE_RECORD } from "@/tracks/25424";

import { itemClickTrack } from "../utils/addressTrackUtils";

interface DataItemProps {
  addressList?: Address[] | null;
  isModal?: boolean;
  isPersonModal?: boolean; //个人中心地址入口
  getAddress?: (type: string) => void;
}

const AddressList: React.FC<DataItemProps> = ({ isPersonModal, getAddress }) => {
  const addressStore = getAddressStore();
  const [addressList, setAddressList] = useState<Address[] | null>(null);
  const [unSyncAddressList, setUnSyncAddressList] = useState<Address[] | null>(null); // 联合登录英国站地址
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [curAddress, setCurAddress] = useState<Address | null>(null);
  const [againDelete, setAgainDelete] = useState(false); // 删除二次确认弹窗
  const [curSelectIndex, setCurSelectIndex] = useState(-1); // 当前选中的角标

  useEffect(() => {
    getAddressList(true);
    if (isPersonModal) {
      sendPv.NeworderReceiverList();
    }
  }, []);

  useEffect(() => {
    // 如果是个人中心地址 只有一条数据需要默认选中且置灰
    if (isPersonModal && addressList && addressList.length === 1 && !addressList[0]?.defAddress) {
      requestUpdateAddress(addressList[0], true);
      return;
    }
    if (addressList && addressList.length > 0) {
      handleRadioChange(addressList[0], 0, "init");
    }
  }, [addressList])


  // 判断是否为Address类型的类型守卫函数
  const isAddress = (data: Address | PostCodeAddress | null): data is Address => {
    if (!data) {
      return false;
    }
    return (data as Address).addressId !== undefined;
  }

  // 判断是否为PostCodeAddress类型的类型守卫函数
  const isPostCodeAddress = (data: Address | PostCodeAddress | null): data is PostCodeAddress => {
    if (!data) {
      return false;
    }
    return (data as PostCodeAddress).postCode !== undefined && !(data as Address).addressId;
  }

  // 匹配地址的函数
  const matchAddress = (identifier: string | number, addressList: Address[] | null) => {
    if (!addressList) {
      return;
    }

    let matchedIndex: number = -1;

    if (typeof identifier === "string") {
      matchedIndex = addressList.findIndex(
        (item) => item.postCode === identifier,
      );
    } else if (typeof identifier === "number") {
      matchedIndex = addressList.findIndex(
        (item) => item.addressId === identifier,
      );
    }
    if (matchedIndex !== -1) {
      // 如果找到匹配的元素，选中该元素
      if (matchedIndex !== 0) {
        const matchedItem = addressList[matchedIndex];
        addressList.splice(matchedIndex, 1);
        addressList.unshift(matchedItem);
      }
    }
    setAddressList(addressList);
  };

  // 更新列表数据（包含缓存处理）
  const updateAddressList = async (list: Address[]) => {
    if (!list || list.length === 0) {
      setAddressList(null);
      return;
    }
    const address = await initAddress()
    // 无缓存 直接返回
    if (!address) {
      setAddressList(list);
      return;
    }
    // 有缓存则获取缓存匹配的到值排序到第一条
    if (isAddress(address)) {
      matchAddress(address.addressId, list);
      return;
    }
    if (isPostCodeAddress(address)) {
      matchAddress(address.postCode, list);
      return;
    }
    // 未匹配成功兜底直接返回
    setAddressList(list);
  };

  const getAddressList = async (isInit: boolean = false) => {
    if (isInit) {
      setIsLoading(true);
    }
    const [err, result] = await to(getUserAddressList());
    if (isInit) {
      setIsLoading(false);
    }
    setIsLoaded(true);
    if (err) {
      setAddressList(null);
      if (isPersonModal) {
        setIsError(true);
        return;
      }
    } else {
      console.log("result---------", result);
      if (result?.code === "401") {
        if (isPersonModal) {
          setIsError(false);
          return;
        }
        return;
      }
      const list = result?.data?.addressList;
      if (list) {
        console.log("isLocal result addressList", addressList);
        if (isPersonModal) {
          setIsError(false);
          setAddressList(list as Address[]);
        } else {
          updateAddressList(list);
        }
      } else {
        if (isPersonModal) {
          setIsError(true);
          return;
        }
      }
      if (isPersonModal) {
        const unList = result?.data?.unSyncAddressList;
        setUnSyncAddressList(unList);
      }
      // 删除、编辑需要更新缓存数据
      const postCode = await curPostCodeOrAddress();
      const detailPostCode = await curPostCodeOrAddress(true);
      addressStore.setPostCode(postCode);
      addressStore.setDetailPostCode(detailPostCode);
    }
  };

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClose = () => {
    setOpenCreateModal(false);
    setCurAddress(null);
    if (getAddress) {
      getAddress("changeAddress");
    }
  };

  const handleAgainClose = () => {
    setAgainDelete(false);
    setCurAddress(null);
  };

  const clearLocal = async () => {
    const localData = await initAddress();
    if (curAddress && localData) {
      const data = localData as Address;
      if (curAddress?.addressId === data?.addressId) {
        clearAddress();
        // 删除过后，需要再次缓存默认、或者第一条
        setDefaultLocalAddress();
      }
    }
  };

  const handleAgainConfirm = async () => {
    if (!curAddress) {
      return;
    }
    const [err, result] = await to(curAddress?.unSync === 1 ? deleteDraftAddress({ addressId: curAddress?.addressId }) : deleteAddress({ addressId: curAddress?.addressId }));
    if (result?.code === "200") {
      handleAgainClose();
      // 如果删除的数据跟缓存的数据是同一条，需要删除缓存数据
      clearLocal();
      if (getAddressList) {
        getAddressList();
      }
      UKToast.show({
        content: "Delete successful",
        icon: "success",
      });
    } else {
      if (result?.msg) {
        UKToast.show({
          content: result?.msg,
          icon: "error",
        });
      }
    }
  };

  const requestUpdateAddress = async (item: Address, isSelect: boolean) => {
    if (!item) {
      return;
    }
    item.defAddress = isSelect;
    const [err, result] = await to(
      updateAddress({
        ...item,
      }),
    );
    if (result?.code === "200") {
      if (result?.data?.defAddress) {
        UKToast.show({
          content: "Set as A Default Address successful",
          icon: "success",
        });
      }
      if (getAddressList) {
        getAddressList();
      }
    } else {
      if (result?.msg) {
        UKToast.show({
          content: result?.msg,
          icon: "error",
        });
      }
    }
  };

  // type init｜changeAddress 分别代表初始化和 切换改变
  const handleRadioChange = async (item: Address, index: number, type: string) => {
    setCurSelectIndex(index);
    // 结算切换需要写缓存
    if (type === "changeAddress") {
      setAddress(item);
      const postCode = await curPostCodeOrAddress();
      const detailPostCode = await curPostCodeOrAddress(true);
      addressStore.setPostCode(postCode);
      addressStore.setDetailPostCode(detailPostCode);
      if (getAddress) {
        getAddress("changeAddress");
      }
    }
  };

  const isListOnlyOne = () => {
    if (addressList && addressList.length === 1) {
      return true;
    }
    if (unSyncAddressList && unSyncAddressList.length === 1) {
      return true;
    }
    return false;
  };

  if (isLoading) {
    return <UKPageLoading text="Loading" />;
  }

  const cardFloor = (item: Address, index: number) => {
    return (
      <div className={styles.box} style={{ width: isListOnlyOne() ? "100%" : isPersonModal ? "460px" : "362px" }}>
        <div className={styles.flex}>
          <UKTruncatedTooltip
            text={item?.name}
            tooltipStyles={styles.country}
            tooltipProps={{
              placement: "top",
              arrow: true
            }}
          />
          <div className={styles.phone}>{item?.areaCode ? "+" + item?.areaCode : ""} {item?.mobile}</div>
        </div>
        <div className={styles.flexRight}>
          <UKTruncatedTooltip
            text={item?.fullAddress}
            textLines={2}
            tooltipStyles={styles.address}
            tooltipProps={{
              placement: "top",
              arrow: true
            }}
          />
          {isPersonModal && (
            <UKCopy
              text={`${item?.name} +${item?.areaCode} ${item?.mobile}\n${item?.fullAddress}`}
            >
              <span
                className={styles.copy}
                exptag={"exp|" + EXPOSURE_RECORD.NeworderReceiverList_Copy_Expo}
                data-exptag-config='{"useClick": false, "stay_duration": 0.3, "repeated":false, "area_rate": 0.01}'
                data-exptag-json-param={JSON.stringify({ ...itemClickTrack(index, item), from_page: isPersonModal ? "1" : "2" })}
                onClick={() => {
                  sendClickTrack.NeworderReceiverList_Copy(itemClickTrack(index, item));
                }}>Copy</span>
            </UKCopy>
          )}
        </div>
        <div className={styles.flexTop9}>
          <div
            className={styles.flex}
            exptag={"exp|" + EXPOSURE_RECORD.NeworderReceiverList_Default_Expo}
            data-exptag-config='{"useClick": false, "stay_duration": 0.3, "repeated":false, "area_rate": 0.01}'
            data-exptag-json-param={JSON.stringify({ ...itemClickTrack(index, item), from_page: isPersonModal ? "1" : "2" })}
          >
            {/* 草稿(联合登录地址) item?.unSync === 1 */}
            {item?.unSync === 1 ?
              <span className={styles.draftItemText}>Please update your address</span> :
              <>
                <Checkbox
                  sx={{
                    color: "#E0E1E5",
                    padding: "0px",
                    marginLeft: "-2px",
                    "&.Mui-checked": {
                      color: item?.defAddress && isPersonModal && (addressList && addressList.length === 1) ? "#E0E1E5" : "#CC0C1C",
                    },
                  }}
                  // 如果是个人中心地址 只有一条数据需要默认选中且置灰
                  disabled={isPersonModal && (addressList && addressList.length === 1) ? true : false}
                  checked={item?.defAddress}
                  onClick={(event) => {
                    if (!isPersonModal) {
                      event.stopPropagation();
                    }
                    const target = event.target as HTMLInputElement;
                    requestUpdateAddress(item, target.checked);
                    sendClickTrack.NeworderReceiverList_Default({
                      index,
                      addrid: `${item?.addressId}`,
                      chstatus: target.checked ? "1-选中" : "0-取消",
                    });
                  }}
                />
                <div className={styles.setText}>
                  {item?.defAddress ? "Default address" : "Set as default address"}
                </div>
              </>}
          </div>
          <div className={styles.flex}>
            <div
              className={styles.addressActions}
              exptag={"exp|" + EXPOSURE_RECORD.NeworderReceiverList_Edit_Expo}
              data-exptag-config='{"useClick": false, "stay_duration": 0.3, "repeated":false, "area_rate": 0.01}'
              data-exptag-json-param={JSON.stringify({ ...itemClickTrack(index, item), from_page: isPersonModal ? "1" : "2" })}
            >
              <AddressEdit
                onClick={(event: any) => {
                  if (!isPersonModal) {
                    event.stopPropagation();
                  }
                  setOpenCreateModal(true);
                  setCurAddress(item);
                  sendClickTrack.NeworderReceiverList_Edit(itemClickTrack(index, item));
                }}
              />
            </div>
            <div
              className={styles.addressActions}
              style={{
                display: addressList?.length === 1 && !isPersonModal ? "none" : "block",
              }}
              exptag={"exp|" + EXPOSURE_RECORD.NeworderReceiverList_Delete_Expo}
              data-exptag-config='{"useClick": false, "stay_duration": 0.3, "repeated":false, "area_rate": 0.01}'
              data-exptag-json-param={JSON.stringify({ from_page: isPersonModal ? "1" : "2" })}
            >
              <AddressDelete
                onClick={(event: any) => {
                  if (!isPersonModal) {
                    event.stopPropagation();
                  }
                  setAgainDelete(true);
                  setCurAddress(item);
                  sendClickTrack.NeworderReceiverList_Delete({ from_page: isPersonModal ? "1" : "2" });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const draftList = () => {
    return (
      <>
        <span className={styles.draftTitle}>Draft addresses</span>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          {unSyncAddressList && unSyncAddressList.length > 0 &&
            unSyncAddressList.map((item, index) => [
              <Grid
                size={unSyncAddressList && unSyncAddressList?.length === 1 ? 12 : 6}
                key={index}
              >
                <div className={styles.flexLeftGray}>
                  {/* 地址卡片 */}
                  {cardFloor(item, index)}
                </div>
              </Grid>,
            ])}
        </Grid>
      </>
    );
  };

  return (
    <div className={styles.container}
      exptag={"exp|" + EXPOSURE_RECORD.NeworderReceiverList_AddressQuantityAuto}
      data-exptag-config='{"useClick": false, "stay_duration": 0.3, "repeated":false, "area_rate": 0.01}'
      data-exptag-json-param={JSON.stringify({ addqty: addressList?.length, from_page: isPersonModal ? "1" : "2" })}
    >
      {/* 添加地址 */}
      {isPersonModal && isLoaded && <div
        className={styles.flexHeaderPerson}
        exptag={"exp|" + EXPOSURE_RECORD.NeworderReceiverList_Add_Expo}
        data-exptag-config='{"useClick": false, "stay_duration": 0.3, "repeated":false, "area_rate": 0.01}'
        data-exptag-json-param={JSON.stringify({ from_page: isPersonModal ? "1" : "2" })}
      >
        <div className={styles.titlePerson}>Addresses</div>
        <Button
          className={styles.add}
          onClick={() => {
            setOpenCreateModal(true);
            sendClickTrack.NeworderReceiverList_Add({ from_page: isPersonModal ? "1" : "2" });
          }}
          style={{ opacity: addressList && addressList?.length >= 30 ? 0.4 : 1 }}
          disabled={addressList && addressList?.length >= 30 ? true : false}
        >
          <AddressAdd /> Add a new address
        </Button>
      </div>}
      {isError && (
        <div className={styles.errorPage}>
          <UKPageError
            style={{ marginTop: "60px" }}
            clickTryAgain={() => getAddressList()}
            text="Loading up, please try again"
            btnText="Try again"
          />
        </div>
      )}
      {!isPersonModal && isLoaded && <>
        <div className={styles.title}>Shipping Address</div>
        <div className={styles.info}>
          Delivery options and delivery speeds may vary for different area
        </div>
      </>}
      {/* 地址列表 */}
      <div
        className={!isPersonModal ? styles.noScrollbars : ""}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          {addressList && addressList.length > 0 &&
            ((!isExpanded && isPersonModal) ? addressList.slice(0, 10) : addressList).map((item, index) => [
              <Grid
                size={addressList && addressList?.length === 1 ? 12 : 6}
                key={index}
              >
                <div
                  className={curSelectIndex === index ? styles.flexLeft : styles.flexLeftGray}
                  exptag={"exp|" + EXPOSURE_RECORD.NeworderReceiverList_Address_Card_Expo}
                  data-exptag-config='{"useClick": false, "stay_duration": 0.3, "repeated":false, "area_rate": 0.01}'
                  data-exptag-json-param={JSON.stringify({ ...itemClickTrack(index, item), from_page: isPersonModal ? "1" : "2" })}
                  onClick={() => {
                    if (!isPersonModal) {
                      handleRadioChange(item, index, "changeAddress")
                      sendClickTrack.NeworderReceiverList_SelectAddress(itemClickTrack(index, item));
                    }
                  }}>
                  {!isPersonModal && <Radio
                    checked={curSelectIndex === index}
                    value="a"
                    name="radio-buttons"
                    style={{ padding: 0 }}
                    inputProps={{ "aria-label": "A" }}
                  />}
                  {/* 地址卡片 */}
                  {cardFloor(item, index)}
                </div>
              </Grid>,
            ])}
        </Grid>
        {/* 展开更多地址 */}
        {addressList && addressList.length === 0 && (
          <div className={styles.errorPage}>
            <UKPageNothing style={{ marginTop: "60px" }} text="There's nothing more" />
          </div>
        )}
        {unSyncAddressList && unSyncAddressList.length > 0 && draftList()}
      </div>
      {/* 个人中心 */}
      {addressList && addressList.length > 10 && isPersonModal && !isExpanded && (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <div className={`${styles.expanded} ${styles.personExpand}`} onClick={() => handleExpand()}>
            View more
            <VectorDown width={10} className={styles.svgExpand} style={{ top: "0px" }} />
          </div>
        </div>
      )}
      {!isPersonModal && isLoaded && <div className={styles.bottomFloat}>
        <Button className={styles.add} onClick={() => setOpenCreateModal(true)}
          style={{ opacity: addressList && addressList?.length >= 30 ? 0.4 : 1 }}
          disabled={addressList && addressList?.length >= 30 ? true : false}>
          <AddressAdd /> Add a new address
        </Button>
      </div>}
      {/* 创建地址 弹窗 */}
      <UKDialog
        open={openCreateModal}
        onClose={handleClose}
        showCloseBtn={true}
        sx={{
          "& .MuiDialog-paper": {
            width: "880px", // 设置自定义宽度
            Height: "596px",
            // padding: "0px",
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenCreateModal(false);
            setCurAddress(null);
          }}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <Typography sx={{ p: 2, padding: 0 }}>
          <NewOrEditAddress
            data={curAddress}
            handleClose={handleClose}
            isModal={true}
            getAddress={getAddress}
            getAddressList={getAddressList}
            isEdit={curAddress ? true : false}
            isPersonModal={isPersonModal}
            pageType={isPersonModal ? PAGE_TYPE.PERSON : PAGE_TYPE.SHIPPING}
          />
        </Typography>
      </UKDialog>
      {/* 删除二次确认弹窗 */}
      <Dialog
        open={againDelete}
        onClose={handleAgainClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            exptag={"exp|" + DELETE_EXPOSURE_RECORD.NeworderAddressDelete_Cancel_Expo}
            data-exptag-config='{"useClick": false, "stay_duration": 0.3, "repeated":false, "area_rate": 0.01}'
            data-exptag-json-param={{}}
            onClick={() => {
              handleAgainClose();
              sendClickTrackDelete.NeworderAddressDelete_Cancel();
            }}>
            Cancel
          </Button>
          <Button variant="contained"
            exptag={"exp|" + DELETE_EXPOSURE_RECORD.NeworderAddressDelete_Expo}
            data-exptag-config='{"useClick": false, "stay_duration": 0.3, "repeated":false, "area_rate": 0.01}'
            data-exptag-json-param={{}}
            onClick={() => {
              handleAgainConfirm();
              sendClickTrackDelete.NeworderAddressDelete();
            }}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddressList;
