import { IObservableValue, observable, action } from "mobx";

// 定义 AddressStore 接口，明确其结构和类型
interface AddressStore {
    postCode: IObservableValue<string>;
    detailPostCode: IObservableValue<string>;
    setPostCode: (newPostCode: string) => void;
    setDetailPostCode: (newPostCode: string) => void;
}

class AddressStoreClass {
    // 使用 observable 定义可观察的数据属性
    postCode = observable.box("");
    detailPostCode = observable.box("");

    setPostCode = action((newPostCode: string) => {
        this.postCode.set(newPostCode);
    });
    
    setDetailPostCode = action((newPostCode: string) => {
        this.detailPostCode.set(newPostCode);
    });

    // 私有构造函数，防止外部直接实例化类
    private constructor() {}

    // 静态私有实例变量，用于存储单例实例
    private static instance: AddressStoreClass;

    // 静态方法用于获取单例实例
    static getInstance(): AddressStoreClass {
        if (!AddressStoreClass.instance) {
            AddressStoreClass.instance = new AddressStoreClass();
        }
        return AddressStoreClass.instance;
    }
}

// 导出获取单例实例的方法
export const getAddressStore = (): AddressStore => {
    return AddressStoreClass.getInstance();
};