// 埋点页面链接：https://stream.jd.com/ddrx/#/requirement/25217
// WARNING!!! 请勿手动修改此文件，如需修改请在子午线平台变更后，执行npm run track-init 25217 
import { eventTrackingUtils } from '@/utils/eventTrackingUtils'
export const PageId = Object.freeze({
  Productdetail_MainPage: 'Productdetail_MainPage'
})

interface IPVTrackParamsRecord {
  /** 商品详情页 */
Productdetail_MainPage: {
/** 主商品id  */
mainskuid: string}
}

const sendPv = {
  /** PV-商品详情页 */
Productdetail_MainPage: (page_param: IPVTrackParamsRecord['Productdetail_MainPage']) => {
    eventTrackingUtils.submitPV({page_id:PageId.Productdetail_MainPage,page_param});
  },
}

interface IExpoTrackParamsRecord {
  /** 加入购物车按钮曝光 */
Productdetail_Addtocart_Expo: undefined
  /** 立即购买按钮曝光 */
Productdetail_GotoBuyNow_Expo: undefined
  /** 顶部功能区-按钮曝光 */
Productdetail_TopFunctionBtn_Expo: {
/** 主商品id  */
mainskuid: string,
/** 按钮名称  */
btnName: string}
  /** 顶部更多功能弹层-按钮曝光 */
Productdetail_TopFunctionLayerBtn_Expo: {
/** 主商品id  */
mainskuid: string,
/** 按钮名称  */
button_name: string}
  /** 主图区曝光 */
Productdetail_MainPicture_Expo: {
/** 主商品id  */
mainskuid: string,
/** 媒体素材类型  */
madiaType: string,
/** 位置  */
index: number}
  /** 单促楼层曝光 */
Productdetail_SinglePromotionFloor_Expo: {
/** 主商品id  */
mainskuid: string,
/** 活动名称  */
activityName: string,
/** 活动id  */
activityId: string}
  /** 消费分期楼层曝光 */
Productdetail_Installment_Expo: {
/** 主商品id  */
mainskuid: string,
/** 展示文本  */
displayText: string}
  /** 券楼层曝光 */
Productdetail_CouponFloor_Expo: {
/** 主商品id  */
mainskuid: string,
/** 优惠券名称  */
couponName: string,
/** 优惠券id  */
batchId: string}
  /** 券弹层整体曝光 */
Productdetail_CouponLayer_Expo: {
/** 主商品id  */
mainskuid: string}
  /** 券弹层-单张券曝光 */
Productdetail_CouponLayer_Coupon_Expo: {
/** 主商品id  */
mainskuid: string,
/** 优惠券名称  */
couponName: string,
/** 优惠券id  */
couponId: string,
/** 优惠券批次号  */
batchId: string,
/** 位置  */
index: number}
  /** 总促楼层曝光 */
Productdetail_MarketFloor_Expo: {
/** 主商品id  */
mainskuid: string,
/** 展示文本  */
displayText: string,
/** 促销id  */
promotionId: string}
  /** 星级楼层曝光 */
Productdetail_StarRating_Expo: {
/** 主商品id  */
mainskuid: string,
/** 星级评级  */
ratingNum: string,
/** 评价条数  */
reviewsNum: string,
/** 销售量  */
SalesNum: string}
  /** 选择规格楼层曝光 */
Productdetail_Specification_Expo: {
/** 主商品id  */
mainskuid: string,
/** 规格类型  */
specificationType: string}
  /** 送至楼层曝光 */
Productdetail_SZFloor_Expo: {
/** 主商品id  */
mainskuid: string}
  /** 平台服务楼层曝光 */
Productdetail_BasicServiceFloor_Expo: {
/** 主商品id  */
mainskuid: string,
/** 展示文本  */
displayText: string}
  /** 商品图文详情楼层曝光 */
Productdetail_DetailDescription_Expo: {
/** 主商品id  */
mainskuid: string}
  /** 评价楼层曝光 */
Productdetail_ReviewsFloor_Expo: {
/** 主商品id  */
mainskuid: string}
  /** 评价楼层-单条评价曝光 */
Productdetail_ReviewsFloor_SingleReview_Expo: {
/** 主商品id  */
mainskuid: string,
/** 评价id  */
commentId: string,
/** 位置  */
index: number}
  /** 评价楼层-举报按钮曝光 */
Productdetail_ReviewsFloor_ReportBtn_Expo: {
/** 主商品id  */
mainskuid: string}
  /** 评价楼层-评价图片曝光 */
Productdetail_ReviewsFloor_Picture_Expo: {
/** 主商品id  */
mainskuid: string,
/** 位置  */
index: number}
  /** 楼层锚点曝光 */
Productdetail_FloorAnchor_Expo: {
/** 主商品id  */
mainskuid: string,
/** 锚点名称  */
anchorName: Array<string>}
  /** 底部提醒条曝光 */
Productdetail_BottomTradeStrip_Expo: {
/** 主商品id  */
mainskuid: string,
/** 展示文本  */
displayText: string}
}

const EXPOSURE_RECORD: Readonly<Record<keyof IExpoTrackParamsRecord,string>> = Object.freeze({
/** 加入购物车按钮曝光 */
  Productdetail_Addtocart_Expo:'Productdetail_Addtocart_Expo',
/** 立即购买按钮曝光 */
  Productdetail_GotoBuyNow_Expo:'Productdetail_GotoBuyNow_Expo',
/** 顶部功能区-按钮曝光 */
  Productdetail_TopFunctionBtn_Expo:'Productdetail_TopFunctionBtn_Expo',
/** 顶部更多功能弹层-按钮曝光 */
  Productdetail_TopFunctionLayerBtn_Expo:'Productdetail_TopFunctionLayerBtn_Expo',
/** 主图区曝光 */
  Productdetail_MainPicture_Expo:'Productdetail_MainPicture_Expo',
/** 单促楼层曝光 */
  Productdetail_SinglePromotionFloor_Expo:'Productdetail_SinglePromotionFloor_Expo',
/** 消费分期楼层曝光 */
  Productdetail_Installment_Expo:'Productdetail_Installment_Expo',
/** 券楼层曝光 */
  Productdetail_CouponFloor_Expo:'Productdetail_CouponFloor_Expo',
/** 券弹层整体曝光 */
  Productdetail_CouponLayer_Expo:'Productdetail_CouponLayer_Expo',
/** 券弹层-单张券曝光 */
  Productdetail_CouponLayer_Coupon_Expo:'Productdetail_CouponLayer_Coupon_Expo',
/** 总促楼层曝光 */
  Productdetail_MarketFloor_Expo:'Productdetail_MarketFloor_Expo',
/** 星级楼层曝光 */
  Productdetail_StarRating_Expo:'Productdetail_StarRating_Expo',
/** 选择规格楼层曝光 */
  Productdetail_Specification_Expo:'Productdetail_Specification_Expo',
/** 送至楼层曝光 */
  Productdetail_SZFloor_Expo:'Productdetail_SZFloor_Expo',
/** 平台服务楼层曝光 */
  Productdetail_BasicServiceFloor_Expo:'Productdetail_BasicServiceFloor_Expo',
/** 商品图文详情楼层曝光 */
  Productdetail_DetailDescription_Expo:'Productdetail_DetailDescription_Expo',
/** 评价楼层曝光 */
  Productdetail_ReviewsFloor_Expo:'Productdetail_ReviewsFloor_Expo',
/** 评价楼层-单条评价曝光 */
  Productdetail_ReviewsFloor_SingleReview_Expo:'Productdetail_ReviewsFloor_SingleReview_Expo',
/** 评价楼层-举报按钮曝光 */
  Productdetail_ReviewsFloor_ReportBtn_Expo:'Productdetail_ReviewsFloor_ReportBtn_Expo',
/** 评价楼层-评价图片曝光 */
  Productdetail_ReviewsFloor_Picture_Expo:'Productdetail_ReviewsFloor_Picture_Expo',
/** 楼层锚点曝光 */
  Productdetail_FloorAnchor_Expo:'Productdetail_FloorAnchor_Expo',
/** 底部提醒条曝光 */
  Productdetail_BottomTradeStrip_Expo:'Productdetail_BottomTradeStrip_Expo',
})

interface IClickTrackParamsRecord {
  /** 加入购物车按钮点击 */
Productdetail_Addtocart: {
/** 主商品id  */
mainskuid: string,
/** 加购购买商品数量  */
skuNum: string}
  /** 立即购买按钮点击 */
Productdetail_GotoBuyNow: {
/** 主商品id  */
mainskuid: string,
/** 加购购买商品数量  */
skuNum: string}
  /** 顶部功能区-按钮点击 */
Productdetail_TopFunctionBtn_Click: {
/** 主商品id  */
mainskuid: string,
/** 按钮名称  */
btnName: string,
/** 按钮状态  */
btnStatus: string}
  /** 顶部更多功能弹层-按钮点击 */
Productdetail_TopFunctionLayerBtn_Click: {
/** 主商品id  */
mainskuid: string,
/** 按钮名称  */
btnName: string}
  /** 主图区点击 */
Productdetail_MainPicture_Click: {
/** 主商品id  */
mainskuid: string,
/** 媒体素材类型  */
madiaType: string,
/** 位置  */
index: number}
  /** 主图大图-视频播放暂停点击 */
Productdetail_BigVideo_Click: {
/** 主商品id  */
mainskuid: string,
/** 播放状态  */
playStatus: string}
  /** 主图大图-图片点击 */
Productdetail_BigPicture_Click: {
/** 主商品id  */
mainskuid: string}
  /** 消费分期楼层点击 */
Productdetail_Installment_Click: {
/** 主商品id  */
mainskuid: string,
/** 展示文本  */
displayText: string}
  /** 券楼层点击 */
Productdetail_CouponFloor_Click: {
/** 主商品id  */
mainskuid: string,
/** 优惠券名称  */
couponName: string,
/** 优惠券id  */
couponId: string}
  /** 总促楼层点击 */
Productdetail_MarketFloor_Click: {
/** 主商品id  */
mainskuid: string,
/** 展示文本  */
displayText: string,
/** 促销id  */
promotionId: string}
  /** 星级楼层点击 */
Productdetail_StarRating_Click: {
/** 主商品id  */
mainskuid: string,
/** 星级评级  */
ratingNum: string,
/** 评价条数  */
reviewsNum: string,
/** 销售量  */
SalesNum: string}
  /** 选择规格点击 */
Productdetail_Click: {
/** 主商品id  */
mainskuid: string,
/** 规格类型  */
specificationType: string}
  /** 送至楼层点击 */
Productdetail_SZFloor_Click: {
/** 主商品id  */
mainskuid: string}
  /** 平台服务楼层点击 */
Productdetail_BasicServiceFloor_Click: {
/** 主商品id  */
mainskuid: string,
/** 展示文本  */
displayText: string}
  /** 评价楼层-查看所有评价入口点击 */
Productdetail_ReviewsFloor_ViewAll_Click: {
/** 主商品id  */
mainskuid: string}
  /** 评价楼层-单条评价点击 */
Productdetail_ReviewsFloor_SingleReview_Click: {
/** 主商品id  */
mainskuid: string,
/** 评价id  */
commentId: string,
/** 位置  */
index: number}
  /** 评价楼层-评价有帮助按钮 */
Productdetail_ReviewsFloor_HelpfulBtn_Click: {
/** 主商品id  */
mainskuid: string,
/** 按钮状态  */
btnStatus: string}
  /** 评价楼层-举报按钮点击 */
Productdetail_ReviewsFloor_ReportBtn_Click: {
/** 主商品id  */
mainskuid: string}
  /** 评价楼层-评价图片点击 */
Productdetail_ReviewsFloor_Picture_Click: {
/** 主商品id  */
mainskuid: string,
/** 位置  */
index: number}
  /** 评价楼层-回复评价按钮点击 */
Productdetail_ReviewsFloor_CommentBtn_Click: undefined
  /** 评价楼层-三个点更多按钮点击 */
Productdetail_ReviewsFloor_MoreBtn_Click: undefined
  /** 楼层锚点点击 */
Productdetail_FloorAnchor_Click: {
/** 主商品id  */
mainskuid: string,
/** 锚点名称  */
anchorName: string}
}

const sendClickTrack = {
  /** 点击事件上报-加入购物车按钮点击 */
Productdetail_Addtocart: (json_param: IClickTrackParamsRecord['Productdetail_Addtocart']) => {
    eventTrackingUtils.submitCL({event_id:'Productdetail_Addtocart',json_param});
  },
  /** 点击事件上报-立即购买按钮点击 */
Productdetail_GotoBuyNow: (json_param: IClickTrackParamsRecord['Productdetail_GotoBuyNow']) => {
    eventTrackingUtils.submitCL({event_id:'Productdetail_GotoBuyNow',json_param});
  },
  /** 点击事件上报-顶部功能区-按钮点击 */
Productdetail_TopFunctionBtn_Click: (json_param: IClickTrackParamsRecord['Productdetail_TopFunctionBtn_Click']) => {
    eventTrackingUtils.submitCL({event_id:'Productdetail_TopFunctionBtn_Click',json_param});
  },
  /** 点击事件上报-顶部更多功能弹层-按钮点击 */
Productdetail_TopFunctionLayerBtn_Click: (json_param: IClickTrackParamsRecord['Productdetail_TopFunctionLayerBtn_Click']) => {
    eventTrackingUtils.submitCL({event_id:'Productdetail_TopFunctionLayerBtn_Click',json_param});
  },
  /** 点击事件上报-主图区点击 */
Productdetail_MainPicture_Click: (json_param: IClickTrackParamsRecord['Productdetail_MainPicture_Click']) => {
    eventTrackingUtils.submitCL({event_id:'Productdetail_MainPicture_Click',json_param});
  },
  /** 点击事件上报-主图大图-视频播放暂停点击 */
Productdetail_BigVideo_Click: (json_param: IClickTrackParamsRecord['Productdetail_BigVideo_Click']) => {
    eventTrackingUtils.submitCL({event_id:'Productdetail_BigVideo_Click',json_param});
  },
  /** 点击事件上报-主图大图-图片点击 */
Productdetail_BigPicture_Click: (json_param: IClickTrackParamsRecord['Productdetail_BigPicture_Click']) => {
    eventTrackingUtils.submitCL({event_id:'Productdetail_BigPicture_Click',json_param});
  },
  /** 点击事件上报-消费分期楼层点击 */
Productdetail_Installment_Click: (json_param: IClickTrackParamsRecord['Productdetail_Installment_Click']) => {
    eventTrackingUtils.submitCL({event_id:'Productdetail_Installment_Click',json_param});
  },
  /** 点击事件上报-券楼层点击 */
Productdetail_CouponFloor_Click: (json_param: IClickTrackParamsRecord['Productdetail_CouponFloor_Click']) => {
    eventTrackingUtils.submitCL({event_id:'Productdetail_CouponFloor_Click',json_param});
  },
  /** 点击事件上报-总促楼层点击 */
Productdetail_MarketFloor_Click: (json_param: IClickTrackParamsRecord['Productdetail_MarketFloor_Click']) => {
    eventTrackingUtils.submitCL({event_id:'Productdetail_MarketFloor_Click',json_param});
  },
  /** 点击事件上报-星级楼层点击 */
Productdetail_StarRating_Click: (json_param: IClickTrackParamsRecord['Productdetail_StarRating_Click']) => {
    eventTrackingUtils.submitCL({event_id:'Productdetail_StarRating_Click',json_param});
  },
  /** 点击事件上报-选择规格点击 */
Productdetail_Click: (json_param: IClickTrackParamsRecord['Productdetail_Click']) => {
    eventTrackingUtils.submitCL({event_id:'Productdetail_Click',json_param});
  },
  /** 点击事件上报-送至楼层点击 */
Productdetail_SZFloor_Click: (json_param: IClickTrackParamsRecord['Productdetail_SZFloor_Click']) => {
    eventTrackingUtils.submitCL({event_id:'Productdetail_SZFloor_Click',json_param});
  },
  /** 点击事件上报-平台服务楼层点击 */
Productdetail_BasicServiceFloor_Click: (json_param: IClickTrackParamsRecord['Productdetail_BasicServiceFloor_Click']) => {
    eventTrackingUtils.submitCL({event_id:'Productdetail_BasicServiceFloor_Click',json_param});
  },
  /** 点击事件上报-评价楼层-查看所有评价入口点击 */
Productdetail_ReviewsFloor_ViewAll_Click: (json_param: IClickTrackParamsRecord['Productdetail_ReviewsFloor_ViewAll_Click']) => {
    eventTrackingUtils.submitCL({event_id:'Productdetail_ReviewsFloor_ViewAll_Click',json_param});
  },
  /** 点击事件上报-评价楼层-单条评价点击 */
Productdetail_ReviewsFloor_SingleReview_Click: (json_param: IClickTrackParamsRecord['Productdetail_ReviewsFloor_SingleReview_Click']) => {
    eventTrackingUtils.submitCL({event_id:'Productdetail_ReviewsFloor_SingleReview_Click',json_param});
  },
  /** 点击事件上报-评价楼层-评价有帮助按钮 */
Productdetail_ReviewsFloor_HelpfulBtn_Click: (json_param: IClickTrackParamsRecord['Productdetail_ReviewsFloor_HelpfulBtn_Click']) => {
    eventTrackingUtils.submitCL({event_id:'Productdetail_ReviewsFloor_HelpfulBtn_Click',json_param});
  },
  /** 点击事件上报-评价楼层-举报按钮点击 */
Productdetail_ReviewsFloor_ReportBtn_Click: (json_param: IClickTrackParamsRecord['Productdetail_ReviewsFloor_ReportBtn_Click']) => {
    eventTrackingUtils.submitCL({event_id:'Productdetail_ReviewsFloor_ReportBtn_Click',json_param});
  },
  /** 点击事件上报-评价楼层-评价图片点击 */
Productdetail_ReviewsFloor_Picture_Click: (json_param: IClickTrackParamsRecord['Productdetail_ReviewsFloor_Picture_Click']) => {
    eventTrackingUtils.submitCL({event_id:'Productdetail_ReviewsFloor_Picture_Click',json_param});
  },
  /** 点击事件上报-评价楼层-回复评价按钮点击 */
Productdetail_ReviewsFloor_CommentBtn_Click: () => {
    eventTrackingUtils.submitCL({event_id:'Productdetail_ReviewsFloor_CommentBtn_Click'});
  },
  /** 点击事件上报-评价楼层-三个点更多按钮点击 */
Productdetail_ReviewsFloor_MoreBtn_Click: () => {
    eventTrackingUtils.submitCL({event_id:'Productdetail_ReviewsFloor_MoreBtn_Click'});
  },
  /** 点击事件上报-楼层锚点点击 */
Productdetail_FloorAnchor_Click: (json_param: IClickTrackParamsRecord['Productdetail_FloorAnchor_Click']) => {
    eventTrackingUtils.submitCL({event_id:'Productdetail_FloorAnchor_Click',json_param});
  },
}

export {
  EXPOSURE_RECORD,
  sendClickTrack,
  sendPv,
}
export type {
  IExpoTrackParamsRecord,
}