import demo from "./demo";
import address from "./address";
import header from "./header";

const zh_CN_lang = {
  header,
  address,
  demo,
};
export default zh_CN_lang;
