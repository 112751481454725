/* eslint-disable @next/next/no-img-element */
import "./titleItem.scss";
import CustomerServiceIcon from "../assets/customer-service.png";
import OrderShippingIcon from "../assets/order-shipping.png";
import AfterSaleIcon from "../assets/after-sale.png";
import MentionIcon from "../assets/mention.png";
import CampaignIcon from "../assets/campaign.png";
import PriceDropIcon from "../assets/price-drop.png";
import FlashSaleIcon from "../assets/flash-sale.png";
import { MessageTypeEnum } from "@/common-components-src/api/message/message.interface";
import { sendClickTrack, EXPOSURE_RECORD } from "@/tracks/25630"
import { EXPCONFIG_FAST } from "@/common-components-src/const/point";

export const TitleConstData: { [key: number]: any } = {
  [MessageTypeEnum.services]: {icon: CustomerServiceIcon.src, title: "Customer Service"}, 
  [MessageTypeEnum.orders]: {icon: OrderShippingIcon.src, title: "Order & Shipping"}, 
  [MessageTypeEnum.aftersale]: {icon: AfterSaleIcon.src, title: "After-sale"}, 
  [MessageTypeEnum.mentions]: {icon: MentionIcon.src, title: "Mentions"}, 
  [MessageTypeEnum.campaign]: {icon: CampaignIcon.src, title: "Campaign"}, 
  [MessageTypeEnum.pricedrop]: {icon: PriceDropIcon.src, title: "Price Drop"}, 
  [MessageTypeEnum.flashsale]: {icon: FlashSaleIcon.src, title: "Flash Sales"}, 
};

export interface TitleProps {
  type: number;
  title: string;
  content: string;
  time: string;
  num: number;
  showDot: boolean;
  msgId: string;
  orderId: string;
  promoId: string;
}

interface Props {
  data: TitleProps;
  onClick: (type:number, title: string) => void;
}

const TitleItem = ({ data, onClick }: Props) => {

  const { type, msgId, orderId, promoId, title, content, time, num, showDot = false } = data;
  const icon = TitleConstData[type].icon;
  const jsonParam = {
    msgType: TitleConstData[type].title,
    msgNum: String(num),
    msgId: msgId,
    orderId: orderId || "",
    promId: promoId || "",
  };

  const handleClick = () => {
    onClick(type, title);
    sendClickTrack.Message_List_MsgList(jsonParam);
  }

  const formatMessageNum = (num: number) => {
    return num >= 100 ? "99+" : num > 0 ? num : ""
  };

  return (
    <div 
      className="item cursor" 
      onClick={handleClick}
      exptag={"exp|" + EXPOSURE_RECORD.Message_List_MsgListExpo}
      data-exptag-config={EXPCONFIG_FAST}
      data-exptag-json-param={JSON.stringify(jsonParam)}
    >
      <img src={icon} alt={title} />
      <div className="container">
        <div className="top-conent">
          <span className="item-title">{title}</span>
          <span className="time">{time}</span>
        </div>
        <div className="bottom-content">
          <span className={`content ${!!num && "width-limit"}`}>{content}</span>
          { showDot && !!num && <span className="dot"/> }
          { !showDot && !!num && <div className="num"><span>{formatMessageNum(num)}</span></div> }
        </div>
      </div>
    </div>
  );
};

export default TitleItem;
