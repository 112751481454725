// 埋点页面链接：https://stream.jd.com/ddrx/#/requirement/25061
// WARNING!!! 请勿手动修改此文件，如需修改请在子午线平台变更后，执行npm run track-init 25061 
import { eventTrackingUtils } from '@/utils/eventTrackingUtils'
interface IExpoTrackParamsRecord {
  /** 页头Logo曝光 */
Header_Logo_Expo: undefined
  /** 页头地址曝光 */
Header_Location_Expo: undefined
  /** 页头国家曝光 */
Header_Country_Expo: undefined
  /** 页头我的曝光 */
Header_Me_Expo: undefined
  /** 页头订单曝光 */
Header_Order_Expo: undefined
  /** 页头购物车曝光 */
Header_Cart_Expo: {
/** name  */
name: string}
  /** 页头导航曝光 */
Header_Navigation_Expo: {
/** name  */
name: string}
  /** 页头登陆曝光 */
Header_Singin_Expo: undefined
}

const EXPOSURE_RECORD: Readonly<Record<keyof IExpoTrackParamsRecord,string>> = Object.freeze({
/** 页头Logo曝光 */
  Header_Logo_Expo:'Header_Logo_Expo',
/** 页头地址曝光 */
  Header_Location_Expo:'Header_Location_Expo',
/** 页头国家曝光 */
  Header_Country_Expo:'Header_Country_Expo',
/** 页头我的曝光 */
  Header_Me_Expo:'Header_Me_Expo',
/** 页头订单曝光 */
  Header_Order_Expo:'Header_Order_Expo',
/** 页头购物车曝光 */
  Header_Cart_Expo:'Header_Cart_Expo',
/** 页头导航曝光 */
  Header_Navigation_Expo:'Header_Navigation_Expo',
/** 页头登陆曝光 */
  Header_Singin_Expo:'Header_Singin_Expo',
})

interface IClickTrackParamsRecord {
  /** 页头Logo */
Header_Logo: undefined
  /** 页头地址 */
Header_Location: undefined
  /** 页头国家 */
Header_Country: undefined
  /** 页头我的 */
Header_Me: undefined
  /** 页头订单 */
Header_Order: undefined
  /** 页头购物车 */
Header_Cart: undefined
  /** 页头导航 */
Header_Navigation: {
/** name  */
name: string}
  /** 页头登陆 */
Header_Singin: undefined
}

const sendClickTrack = {
  /** 点击事件上报-页头Logo */
Header_Logo: () => {
    eventTrackingUtils.submitCL({event_id:'Header_Logo'});
  },
  /** 点击事件上报-页头地址 */
Header_Location: () => {
    eventTrackingUtils.submitCL({event_id:'Header_Location'});
  },
  /** 点击事件上报-页头国家 */
Header_Country: () => {
    eventTrackingUtils.submitCL({event_id:'Header_Country'});
  },
  /** 点击事件上报-页头我的 */
Header_Me: () => {
    eventTrackingUtils.submitCL({event_id:'Header_Me'});
  },
  /** 点击事件上报-页头订单 */
Header_Order: () => {
    eventTrackingUtils.submitCL({event_id:'Header_Order'});
  },
  /** 点击事件上报-页头购物车 */
Header_Cart: () => {
    eventTrackingUtils.submitCL({event_id:'Header_Cart'});
  },
  /** 点击事件上报-页头导航 */
Header_Navigation: (json_param: IClickTrackParamsRecord['Header_Navigation']) => {
    eventTrackingUtils.submitCL({event_id:'Header_Navigation',json_param});
  },
  /** 点击事件上报-页头登陆 */
Header_Singin: () => {
    eventTrackingUtils.submitCL({event_id:'Header_Singin'});
  },
}

export {
  EXPOSURE_RECORD,
  sendClickTrack,
}
export type {
  IExpoTrackParamsRecord,
}