/* eslint-disable @typescript-eslint/no-explicit-any */
import axiosInstance from "../new-color-axiosInstance";
import { NewGiftActivityDto, NewGiftCouponDto } from "./gift.interface";

interface Response<T> {
  success: boolean;
  code: string;
  msg: string;
  data: T;
  traceId: string;
  result: boolean;
}

// 查询礼包 https://cf.jd.com/pages/viewpage.action?pageId=629977547
export const giftQuery = (): Promise<Response<NewGiftActivityDto>> => {
  return axiosInstance
    .get<Response<NewGiftActivityDto>>(`marketing_interact_getGiftPack`)
    .then((response) => response.data)
    .catch((error: any) => error);
};

/**
   * 当前用户查询新用户礼包领取优惠券礼包
   * @cf https://cf.jd.com/pages/viewpage.action?pageId=645945646
   * @param couponList
   * @param couponList[].ruleId 优惠券活动ID
   * @param couponList[].encryptedKey 加密KEY
   * @param couponList[].ext 扩展
   * @param ext 扩展
   */
export const giftReceive = (couponList: { ruleId: string, encryptedKey: string, ext?: { [key: string]: string; } }[], ext?: { [key: string]: string; }): Promise<Response<any>> => {
  const list: any = []
  couponList.forEach(({ ruleId, encryptedKey, ext }) => {
    list.push({ ruleId, encryptedKey, ext })
  })
  const params: {[key: string]: string} = {}
  list.forEach((item: any, index: any) => {
    params['couponList[' + index + '].ruleId'] = item.ruleId;
    params['couponList[' + index + '].encryptedKey'] = item.encryptedKey;
  })
  return axiosInstance
    .post<Response<any>>(`marketing_giftpack_coupon_receive`, undefined, { params: params })
    .then((response) => response.data)
    .catch((error: any) => error);
};
