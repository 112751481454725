/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
import { Address } from "@/common-components-src/api/address/address.interface";
import NewOrEditAddress from "@/common-components-src/js/address/addressCreate/index";
import AddressList from "@/common-components-src/js/address/addressList/index";
import { curShippingAddress } from "@/common-components-src/js/utils/addressRequestUtils";
import UKDialog from "@/common-components-src/components/UKDialog/index";
import VectorDown  from "@/common-components-src/assets/icons/VectorDown.svg";
import UKTruncatedTooltip from "@/common-components-src/components/UKTruncatedTooltip/index"
import PAGE_TYPE from "../const/pageType";

import { sendClickTrack as sendPayClickTrack } from "@/tracks/25281";

import styles from "./index.module.scss";

interface DataItemProps {
  loadCurrentOrder?: (params?: any) => void;
  isPersonModal?: boolean; //个人中心地址入口
}

const AccountAddress: React.FC<DataItemProps> = ({ loadCurrentOrder, isPersonModal }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [curAddress, setCurAddress] = useState<Address | null>(null);
  const [openCreateModal, setOpenCreateModal] = useState(false);

  useEffect(() => {
    if (!isPersonModal) {
      getAddress("init");
    }
  }, []);

  const getAddress = async (type: string) => {
    const cur = await curShippingAddress();
    if (cur) {
      setCurAddress(cur as Address);
      if (loadCurrentOrder) {
        loadCurrentOrder({ addressId: cur?.addressId, type, address: cur });
      }
    }
    setIsLoading(false);
    // 结算地址：切换不同地址则需求关闭弹窗
    if(type !== "init"){
      handleClose();
    }
  };

  const handleClose = () => {
    setOpenCreateModal(false);
  };

  const checkOutAddress = () => {
    return (
      <div className={styles.box}>
        <div className={styles.title}>Shipping Address
          <span className={styles.go} onClick={() => {
            setOpenCreateModal(true);
            sendPayClickTrack.SettleAccounts_OrderNew_Address();
          }}>
            Change
            <VectorDown width={10} className={styles.svg} />
          </span>
        </div>
        <div className={styles.flex}>
          <UKTruncatedTooltip
            text={curAddress?.name || ""}
            tooltipStyles={styles.country}
            tooltipProps={{
              placement: "top",
              arrow: true
            }}
          />
          <span className={styles.phone}>{curAddress?.areaCode ? "+" + curAddress?.areaCode : ""} {curAddress?.mobile}</span>
        </div>
        <UKTruncatedTooltip
          text={curAddress?.fullAddress}
          textLines={2}
          tooltipStyles={styles.address}
          tooltipProps={{
            placement: "top",
            arrow: true
          }}
        />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {!isLoading && curAddress && !isPersonModal && checkOutAddress()}
      {!isLoading && !curAddress && !isPersonModal && (
        <>
          <Alert severity="warning" sx={{ marginBottom: 2 }}>
            Please enter your shipping address
          </Alert>
          <NewOrEditAddress getAddress={getAddress} isModal={false} pageType={isPersonModal ? PAGE_TYPE.PERSON : PAGE_TYPE.SHIPPING}/>
        </>
      )}
      {isPersonModal && (
        <AddressList
          getAddress={getAddress}
          isPersonModal={isPersonModal}
        />
      )}
      <UKDialog
        open={openCreateModal}
        onClose={handleClose}
        showCloseBtn={true}
        sx={{
          "& .MuiDialog-paper": {
            width: "880px", // 设置自定义宽度
            Height: "596px",
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <Typography>
          <AddressList
            isPersonModal={isPersonModal}
            getAddress={getAddress}
          />
        </Typography>
      </UKDialog>
    </div>
  );
};

export default AccountAddress;
