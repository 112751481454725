import React from "react";

interface props {
  style?: React.CSSProperties;
  symbol: string;
}
const symbolMapping: Record<string, string> = {
  "£": "£",
  "￡": "£",
  "¥": "¥",
  "€": "€",
};

const getCurrencySymbolByCharactor = (symbol: string) => {
  return symbolMapping[symbol?.trim()];
};

const JDICurrencySymbol = ({ style, symbol }: props) => {
  return <span style={style}>{getCurrencySymbolByCharactor(symbol)}</span>;
};

export default JDICurrencySymbol;
