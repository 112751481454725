import "../index.scss"
import IntlPopover from "./popover";
import { Typography } from "@mui/material";
import { MouseEvent, useState } from "react";
import MoreIcon  from "../assets/more.svg";

const typographyStyle = {
  fontSize: "14px",
  cursor: "pointer",
  width: "88px",
  height: "52px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": { color: "#990915" },
};

interface Props {
  onDelete?: () => void;
}
export default function MenuOpts({ onDelete }: Props) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const onPopDelete = (event: MouseEvent) => {
    event.stopPropagation();
    onDelete?.();
    handlePopoverClose();
  };

  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? "simple-popover" : "";
  return (
    <div>
      <div className="pover-btn" aria-describedby={id} onClick={handleClick}>
        <MoreIcon className="more-icon" />
      </div>
      <IntlPopover id={id} open={popoverOpen} anchorEl={anchorEl} onClose={handlePopoverClose}>
        <Typography sx={typographyStyle} className="report-block" onClick={onPopDelete}>
          Delete
        </Typography>
      </IntlPopover>
    </div>
  );
}
