/* eslint-disable @typescript-eslint/no-explicit-any */
import style from "./style.module.scss"; // 引入CSS文件
import ImageErrorIcon from "../icons/imageErrorIcon.svg";

import { Button } from "@mui/material";
import { useState, useEffect } from "react";

const ImageDom = ({ src, alt, imgStyle }: { src: string; alt: string; imgStyle?: any }) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setIsLoading(false);
    };
    img.onerror = () => {
      setIsError(true);
    };
  }, [src, currentIndex]);

  return (
    <div className={style.imageContainer}>
      {isLoading && <div className={style.loadingSpinner}></div>}
      {isError && (
        <div className={style.errorImage}>
          <ImageErrorIcon />
          <span>Loading up, please try again</span>
          <Button
            size="small"
            variant="outlined"
            sx={{
              color: "#fff",
              borderColor: "#fff",
            }}
            onClick={() => {
              setIsError(false);
              setCurrentIndex((prevKey) => prevKey + 1);
            }}
          >
            Try again
          </Button>
        </div>
      )}
      <img
        key={currentIndex}
        style={imgStyle}
        src={src}
        alt={alt}
        className={`${style.image} ${isLoading ? style.hidden : ""}`}
        onLoad={() => setIsLoading(false)}
        onError={() => setIsError(true)}
      />
    </div>
  );
};

export default ImageDom;
