// 埋点页面链接：https://stream.jd.com/ddrx/#/requirement/25156
// WARNING!!! 请勿手动修改此文件，如需修改请在子午线平台变更后，执行npm run track-init 25156 
import { eventTrackingUtils } from '@/utils/eventTrackingUtils'
interface IExpoTrackParamsRecord {
  /** 下拉联想词曝光 */
Search_Sug_AssociativeWord_Expo: {
/** 实验位信息(搜索)  */
abver: string,
/** 搜索id  */
pvid: string,
/** 搜索请求标识  */
logid: string,
/** 渠道名称  */
channelName: string,
/** 位置  */
pos: number,
/** 关键词  */
keyWord: string,
/** 输入词  */
Input_Keyword: string}
}

const EXPOSURE_RECORD: Readonly<Record<keyof IExpoTrackParamsRecord,string>> = Object.freeze({
/** 下拉联想词曝光 */
  Search_Sug_AssociativeWord_Expo:'Search_Sug_AssociativeWord_Expo',
})

interface IClickTrackParamsRecord {
  /** 下拉联想词点击 */
Search_Sug_AssociativeWord: {
/** 实验位信息(搜索)  */
abver: string,
/** 搜索id  */
pvid: string,
/** 搜索请求标识  */
logid: string,
/** 渠道名称  */
channelName: string,
/** 位置  */
pos: number,
/** 关键词  */
keyWord: string,
/** 输入词  */
Input_Keyword: string}
}

const sendClickTrack = {
  /** 点击事件上报-下拉联想词点击 */
Search_Sug_AssociativeWord: (json_param: IClickTrackParamsRecord['Search_Sug_AssociativeWord']) => {
    eventTrackingUtils.submitCL({event_id:'Search_Sug_AssociativeWord',json_param});
  },
}

export {
  EXPOSURE_RECORD,
  sendClickTrack,
}
export type {
  IExpoTrackParamsRecord,
}