"use client";
import cn from "clsx";
import styles from "./index.module.scss";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import aircraft from "@/common-components-src/assets/icons/aircraft.png";
import CommonPageError  from "@/common-components-src/assets/icons/PageError.svg";
interface LoadingIconProps {
  layout?: string; // row | column
  text?: string;
  btnText?: string;
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clickTryAgain?: (...args: any[]) => void;
}

const UKPageError = ({
  layout = "column",
  text,
  btnText,
  className,
  style,
  textStyle,
  clickTryAgain,
}: LoadingIconProps) => {
  const { t } = useTranslation();
  console.log(aircraft,'aircraft');

  return (
    <div className={cn(styles.pageError, className)} style={style}>
      <div className={cn(layout === "row" ? styles.row : styles.column)}>
        {/* <CommonPageError className={styles.icon} /> */}
        <img className={styles.icon} src={aircraft.src} />
        <span className={styles.pageErrorText} style={textStyle}>{text ? text : t ? t("common.pageStatus.error") : ""}</span>
      </div>
      <Button className={styles.btn} variant="outlined" onClick={clickTryAgain}>
        {btnText ? btnText : t ? t("common.btnText.tryAgain") : ""}
      </Button>
    </div>
  );
};

export default UKPageError;
