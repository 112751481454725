const expandShowStyle = (obj: any) => {
  const showStyleObj = obj?.showStyle ? JSON.parse(obj?.showStyle) : {};
  return { ...obj, ...showStyleObj };
};

//获取商品促销标签的文案；promotionType的含义：优惠券: 2  ,单品促销: 1, 总价促销: 10  ,时效标签: 3
export const getPromotionObj = (promotions: any) => {
  let promotionObj = {
    couponInfo: "-100",
    single_promotion: "-100",
    amount_promotion: "-100",
    time_label: "-100",
  };
  let single_promotion: string[] = [];
  let amount_promotion: string[] = [];
  let couponInfo: string[] = [];
  let time_label: string[] = [];
  if (promotions) {
    promotions.map((promotion: any) => {
      promotion = expandShowStyle(promotion);
      switch (promotion.promotionType) {
        case 1:
          single_promotion.push(`${promotion.text}${promotion?.endTime || ""}`);
          break;
        case 10:
          amount_promotion.push(`${promotion.text}${promotion?.endTime || ""}`);
          break;
        case 2:
          couponInfo.push(`${promotion.text}${promotion?.endTime || ""}`);
          break;
        case 3:
          time_label.push(`${promotion.text}${promotion?.endTime || ""}`);
          break;
      }
    });

    promotionObj.couponInfo = couponInfo.length > 0 ? couponInfo.join(";") : "-100";
    promotionObj.single_promotion = single_promotion.length > 0 ? single_promotion.join(";") : "-100";
    promotionObj.amount_promotion = amount_promotion.length > 0 ? amount_promotion.join(";") : "-100";
    promotionObj.time_label = time_label.length > 0 ? time_label.join(";") : "-100";
    return promotionObj;
  }
  return promotionObj;
};
