import cn from "clsx";
import style from "./style.module.scss";
import React, { useState, useRef, useEffect } from "react";
import VideoPlayIcon from "./icons/videoPlay.svg";
import VideoPay from "./icons/videoPay_top.svg";
import CloseImage from "./icons/pageClose.svg";
import VideoPayBig from "./icons/start_playing_big.svg";
import FullScreenIcon from "./icons/fullScreenIcon.svg";
import VolumeIcon from "./icons/volumeIcon.svg";
import StartPlaying from "./icons/start_playing.svg";
import PauseIcon from "./icons/pauseIcon.svg";

interface VideoPlayerProps {
  src: string;
  controls?: boolean;
  autoplay?: boolean;
  preview?: boolean;
  loop?: boolean;
  muted?: boolean;
  playbackRate?: number;
  volume?: number;
  position?: "content" | "top";
  maskType?: "ordinary" | "fullScreen";
  title?: string;
  content?: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  src,
  position = "content", // 小图时候播放按钮位置
  maskType = "fullScreen", // 全屏类型，原生全屏，优化普通全屏
  preview = true, // 是否预览
  controls = false, // 是否显示视频控制条
  autoplay = false, // 是否自动播放
  loop = false, // 是否循环播放
  muted = false, // 是否静音
  playbackRate = 1, // 播放速度
  volume = 0.5, // 音量
  title = "",
  content = "",
}) => {
  const videoRef = useRef<any>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.controls = controls; // 是否显示视频控制条（默认为 true）
      videoRef.current.autoplay = autoplay; // 是否自动播放（默认为 false）
      videoRef.current.loop = loop; // 是否循环播放（默认为 false）
      videoRef.current.muted = muted; // 是否静音（默认为 false）
      videoRef.current.playbackRate = playbackRate; // 播放速度（默认为 1）
      videoRef.current.volume = volume; // 音量（默认为 0.5）
    }
  }, [controls, autoplay, loop, muted, playbackRate, volume]);

  // 全屏打开视频
  const openVideo = () => {
    if (!preview) {
      return;
    }
    if (maskType == "ordinary") {
      setOpenVideoMask(true);
      return;
    }
    if (videoRef.current) {
      if (isFullscreen) {
        // 关闭视频
        if (document && document.fullscreenElement) {
          // 关闭全屏
          document.exitFullscreen();
        }
      } else {
        // 播放视频
        videoRef.current.play();
        // 全屏
        videoRef.current.requestFullscreen();
      }
      setIsFullscreen(!isFullscreen);
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        videoRef?.current?.pause();
        setIsFullscreen(false);
      }
    };
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const [openVideoMask, setOpenVideoMask] = useState(false); // 是否打开预览模式 全屏
  const [videoMaskPay, setVideoMaskPay] = useState(false); // 是否打开预览模式 大图
  const progressRef = useRef<HTMLDivElement>(null); // 进度条
  const volumeRef = useRef<HTMLDivElement>(null);
  const [duration, setDuration] = useState<any>(0); // 视频时长
  const [volumeDom, setVolume] = useState(1);

  const [currentTime, setCurrentTime] = useState<any>(0); // 当前时间
  const [isVolumeSlider, setIsVolumeSlider] = useState(false); // 是否显示音量条

  const getVideoTime = () => {
    const video = videoRef.current;
    if (!video) {
      return;
    }

    const timeUpdate = () => setCurrentTime(video.currentTime);
    const loadedMetadata = () => setDuration(video.duration);
    const ended = () => setVideoMaskPay(false);

    video.addEventListener("timeupdate", timeUpdate);
    video.addEventListener("loadedmetadata", loadedMetadata);
    video.addEventListener("ended", ended);

    return () => {
      video.removeEventListener("timeupdate", timeUpdate);
      video.removeEventListener("loadedmetadata", loadedMetadata);
      video.removeEventListener("ended", ended);
    };
  };

  // 点击 中间播放按钮 开始播放视频
  const videoPayBigIconFn = () => {
    setIsVolumeSlider(false);
    // 检查是否需要显示付费大图标
    console.log(videoMaskPay, "videoMaskPay");
    if (!videoMaskPay) {
      // 显示付费大图标并播放视频
      setVideoMaskPay(true);
      // 如果不是预览模式，则直接返回
      if (!preview) {
        return;
      }
      // 如果视频元素存在，则开始播放视频
      if (videoRef.current) {
        videoRef.current.play();
        getVideoTime();
      }
    } else {
      // 隐藏付费大图标并暂停视频播放
      setVideoMaskPay(false);
      // 如果不是预览模式，则直接返回
      if (!preview) {
        return;
      }
      // 如果视频元素存在，则暂停视频播放
      if (videoRef.current) {
        videoRef?.current?.pause();
      }
    }
  };

  // 视频拖动控制
  const handleProgressDrag = (e: React.MouseEvent<HTMLDivElement>) => {
    if (videoRef.current && progressRef.current) {
      const rect = progressRef.current.getBoundingClientRect();
      const pos = (e.clientX - rect.left) / rect.width;
      if (videoRef.current.currentTime) {
        videoRef.current.currentTime = pos * duration;
        videoRef.current.play();
        setVideoMaskPay(true);
      }
    }
  };
  // 音量控制
  const handleVolumeChange = (e: React.MouseEvent<HTMLDivElement>) => {
    if (volumeRef.current) {
      const rect = volumeRef.current.getBoundingClientRect();
      const newVolume = 1 - (e.clientY - rect.top) / rect.height;
      const clampedVolume = Math.max(0, Math.min(1, newVolume));
      setVolume(clampedVolume);
      if (videoRef.current) {
        videoRef.current.volume = clampedVolume;
      }
    }
  };
  // 格式化时间
  const formatTime = (time: any) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };
  useEffect(() => {
    getVideoTime();
  }, []);

  // 预览模式打开全屏
  const openFullScreen = () => {
    setIsVolumeSlider(false);
    if (videoRef.current) {
      if (isFullscreen) {
        // 关闭视频
        if (document && document.fullscreenElement) {
          // 关闭全屏
          document.exitFullscreen();
        }
      } else {
        // 播放视频
        videoRef.current.play();
        // 全屏
        videoRef.current.requestFullscreen();
      }
      setIsFullscreen(!isFullscreen);
    }
  };

  return (
    <div className={style.videoPlayer_wrap}>
      <video
        className={style.videoDom}
        ref={videoRef}
        src={src}
        style={{
          cursor: position == "top" && preview ? "pointer" : "",
        }}
        onClick={() => {
          position == "top" && openVideo();
        }}
      />
      <div className={style.videoPlayer_mask}>
        {position == "content" && (
          <VideoPlayIcon onClick={openVideo} className={style.videoPlayIcon} />
        )}
        {position == "top" && (
          <VideoPay
            onClick={openVideo}
            className={style.videoPay}
            style={{ cursor: position == "top" && preview ? "pointer" : "" }}
          />
        )}
      </div>
      {/* 不全屏展示 */}
      {openVideoMask && (
        <div className={style.videoPlayer_mask_wrap}>
          {/* <VideoPlayerTest src={src} /> */}
          {true && (
            <>
              <CloseImage className={style.closeIcon} onClick={() => setOpenVideoMask(false)} />
              <div className={style.contentWrap}>
                <video
                  onClick={() => {
                    setIsVolumeSlider(false);
                    videoPayBigIconFn();
                  }}
                  style={{ cursor: "pointer" }}
                  className={style.videoDom}
                  ref={videoRef}
                  src={src}
                />
                <VideoPayBig
                  className={cn(style.videoPayBig, videoMaskPay ? style.videoPayBigShow : "")}
                  onClick={videoPayBigIconFn}
                />
                {/* 设置按钮盒子 */}
                <div className={style.consoleWrap}>
                  {/* 右边按钮盒子 */}
                  <div className={style.consoleWrap_right}>
                    {videoMaskPay ? (
                      <div className={style.consoleIcon}>
                        <PauseIcon onClick={videoPayBigIconFn} />
                      </div>
                    ) : (
                      <div className={style.consoleIcon}>
                        <StartPlaying onClick={videoPayBigIconFn} />
                      </div>
                    )}
                  </div>

                  {/* 中间盒子 */}
                  <div className={style.consoleWrapCenter}>
                    {/* 时长 */}
                    <div className={style.duration}>{formatTime(currentTime)}</div>
                    {/* 进度条 */}
                    <div className={style.progressBarWrap}>
                      <div
                        ref={progressRef}
                        className={style.progressBar}
                        onClick={handleProgressDrag}
                      >
                        <div
                          className={style.progressFilled}
                          style={{ width: `${(currentTime / duration) * 100}%` }}
                        />
                      </div>
                      {/* 当前时间 */}
                      <div className={style.currentTime}>{formatTime(duration)}</div>
                    </div>
                  </div>

                  {/* 左侧按钮盒子 */}
                  <div className={style.consoleWrap_left}>
                    {/* 音量 */}
                    <div className={cn(style.consoleIcon, style.volumeIcon)}>
                      {isVolumeSlider && (
                        <div
                          ref={volumeRef}
                          className={style.volumeSlider}
                          onClick={handleVolumeChange}
                        >
                          <div
                            className={style.volumeLevel}
                            style={{ height: `${volumeDom * 85}%` }}
                          />
                        </div>
                      )}
                      <VolumeIcon onClick={() => setIsVolumeSlider(!isVolumeSlider)} />
                    </div>
                    {/* 放大 */}
                    <div className={style.consoleIcon}>
                      <FullScreenIcon onClick={openFullScreen} />
                    </div>
                  </div>
                </div>

                {/* 文本描述 */}
                <div className={style.textWrap}>
                  <div className={style.textWrap_title}>
                    <div className={style.textWrap_title_left}>{title}</div>
                    <div className={style.textWrap_title_right}></div>
                  </div>
                  <div className={style.textWrap_content}>{content}</div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
