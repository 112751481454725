import ProductCardV2 from "../productCard_V2";
import "./index.scss";
import { getPromotionObj } from "@/utils/tracksUtils";
interface IExtraTrack {
  keyWord: string;
  categoryId: string;
  logid: string;
  pvid: string;
  request_id: string;
  mtest: string;
  channelName: string;
  foreLabel: string;
  labelWords: string;
  batchId: string;
}
interface productTrackParamsType {
  RecomSku_Expo?: string;
  RecomSku_Addcart_Expo?: string;
  productClick?: (json_param: any) => void;
  addCartClick?: (json_param: any) => void;
  tab_name?: string;
  extraTrackParams?: Partial<IExtraTrack>;
}
interface IProps {
  products?: any[]; //渲染的商品列表
  localInfo?: any; //本地信息，主要用其中的货币单位
  addCartFn?: Function; //商卡加车回调方法(未加车)
  onAddCartSuccess?: Function; // 商卡--加车成功后--回调
  productTrackParams?: productTrackParamsType; //埋点传参
}
const ProductList = (props: IProps) => {
  const { products = [], localInfo = {}, addCartFn, onAddCartSuccess, productTrackParams } = props;
  //统一处理推荐埋点参数
  const { extraTrackParams } = productTrackParams || {};
  const {
    keyWord = "-100",
    categoryId = "-100",
    logid = "-100",
    pvid = "-100",
    request_id = "-100",
    mtest = "-100",
    channelName = "-100",
    foreLabel = "-100",
    labelWords = "-100",
    batchId = "-100",
  } = extraTrackParams || {};
  const getJsonParam = (item: any, index: number) => {
    return {
      broker_info: item?.brokeInfo || "-100",
      page: Math.floor(index / 20) + 1,
      index,
      skuid: item?.skuId || "-100",
      p: "-100",
      secprice: item?.content?.originalPrice || "-100",
      secpricetype: "83",
      firprice: item.content.realPrice || "-100",
      firpricetype: "11",
      source: "-100",
      freqTag: "-100",
      feedspos: "-100",
      tab_name: productTrackParams?.tab_name,
      filter_name: "-100",
      // 新增参数
      pos: index,
      // 包邮页新增参数
      ...(extraTrackParams
        ? {
            keyWord,
            categoryId,
            logid,
            request_id: logid,
            pvid,
            mtest,
            channelName,
            foreLabel,
            labelWords,
            batchId,
          }
        : {}),
      // 促销参数
      ...getPromotionObj(item?.content?.promotions),
    };
  };
  if (products && products.length > 0) {
    return (
      <div className="product-list">
        {products &&
          products?.length > 0 &&
          products.map((product: any = {}, index: number) => (
            <ProductCardV2
              key={product.skuId}
              productData={{
                type: "2",
                width: 240,
                skuName: product?.content?.wareName,
                skuImgKeyParams: {
                  imgUrlKey: product?.content?.imageUrl,
                  width: 240,
                  height: 240,
                  quality: 100,
                },
                onClickCart: addCartFn,
                onAddCartSuccess: onAddCartSuccess,
                currency: localInfo?.currencySymbol,
                skuId: product.skuId,
                containerStyle: { backgroundColor: "#fff" },
                shouldShowCart: true,
                score: product?.content?.commentScore,
                trackingPointParams: {
                  productExpConfig: {
                    event_id: productTrackParams?.RecomSku_Expo,
                    config: {
                      useClick: false,
                      stay_duration: 0.3,
                      repeated: false,
                      area_rate: 0.5,
                    },
                    json_param: getJsonParam(product, index),
                  },
                  cartExpConfig: {
                    event_id: productTrackParams?.RecomSku_Addcart_Expo,
                    config: {
                      useClick: false,
                      stay_duration: 0.3,
                      repeated: false,
                      area_rate: 0.5,
                    },
                    json_param: getJsonParam(product, index),
                  },
                  productClickPoint: () =>
                    productTrackParams && productTrackParams.productClick && productTrackParams.productClick(getJsonParam(product, index)),
                  cartClickPoint: () => productTrackParams && productTrackParams.addCartClick && productTrackParams.addCartClick(getJsonParam(product, index)),
                },
                ...product.content,
              }}
            />
          ))}
      </div>
    );
  } else {
    return null;
  }
};

export default ProductList;
