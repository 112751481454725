// 埋点页面链接：https://stream.jd.com/ddrx/#/requirement/25373
// WARNING!!! 请勿手动修改此文件，如需修改请在子午线平台变更后，执行npm run track-init 25373 
import { eventTrackingUtils } from '@/utils/eventTrackingUtils'
interface IExpoTrackParamsRecord {
  /** 一级类目导航曝光 */
Category_Main_FirstCategory_Expo: Array<{
/** 一级类目id  */
firstcid: string,
/** 商品一级类目名称  */
first_category_name: string,
/** 位置  */
index: number}>
  /** 三级类目导航曝光 */
Category_Main_ThirdCategory_Expo: Array<{
/** 一级类目id  */
firstcid: string,
/** 商品一级类目名称  */
first_category_name: string,
/** 二级类目id  */
secondcid: string,
/** 商品二级类目名称  */
second_category_name: string,
/** 商品三级类目名称  */
third_category_name: string,
/** 三级类目id  */
thirdcid: string,
/** 位置  */
index: number}>
}

const EXPOSURE_RECORD: Readonly<Record<keyof IExpoTrackParamsRecord,string>> = Object.freeze({
/** 一级类目导航曝光 */
  Category_Main_FirstCategory_Expo:'Category_Main_FirstCategory_Expo',
/** 三级类目导航曝光 */
  Category_Main_ThirdCategory_Expo:'Category_Main_ThirdCategory_Expo',
})

interface IClickTrackParamsRecord {
  /** 一级类目导航点击 */
Category_Main_FirstCategory: Array<{
/** 一级类目id  */
firstcid: string,
/** 商品一级类目名称  */
first_category_name: string,
/** 位置  */
index: number}>
  /** 三级类目导航点击 */
Category_Main_ThirdCategory: Array<{
/** 一级类目id  */
firstcid: string,
/** 商品一级类目名称  */
first_category_name: string,
/** 二级类目id  */
secondcid: string,
/** 商品二级类目名称  */
second_category_name: string,
/** 商品三级类目名称  */
third_category_name: string,
/** 三级类目id  */
thirdcid: string,
/** 位置  */
index: number}>
}

const sendClickTrack = {
  /** 点击事件上报-一级类目导航点击 */
Category_Main_FirstCategory: (json_param: IClickTrackParamsRecord['Category_Main_FirstCategory']) => {
    eventTrackingUtils.submitCL({event_id:'Category_Main_FirstCategory',json_param});
  },
  /** 点击事件上报-三级类目导航点击 */
Category_Main_ThirdCategory: (json_param: IClickTrackParamsRecord['Category_Main_ThirdCategory']) => {
    eventTrackingUtils.submitCL({event_id:'Category_Main_ThirdCategory',json_param});
  },
}

export {
  EXPOSURE_RECORD,
  sendClickTrack,
}
export type {
  IExpoTrackParamsRecord,
}