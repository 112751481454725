// Feedback Style
export const DialogStyle = {
  "& .MuiDialog-paper": {
    width: "592px",
    minHeight: "604px",
    borderRadius: "8px",
  }
}

export const FlexRightStyle = {
  display: "flex",
  justifyContent: "right",
}

export const MarginRightHoverStyle = {
  marginRight: "10px",
  color: "#CC0C1C",
}

export const SubjectTitleStyle = {
  marginTop: "7px",
  marginBottom: "2px",
}

export const DescTitleStyle = {
  marginTop: "16px",
  marginBottom: "10px",
}

export const ContactTitleStyle = {
  marginTop: "10px",
  marginBottom: "6px",
}

export const SubjectFormStyle = {
  width: "95%",
  "& .MuiInputBase-root": {
    background: "#f7f8fc",
    paddingTop: "10px",
    paddingBottom: "15px",
  },
}

export const DescFormStyle = {
  "& .MuiInputBase-root": {
    background: "#f7f8fc",
    paddingTop: "10px",
    paddingBottom: "15px",
  }
}

export const ContactFormStyle = {
  "& .MuiInputBase-root": {
    background: "#f7f8fc",
  }
}

export const RadioLabelStyle = {
  height: "48px",
  "& .MuiFormControlLabel-label": {
    height: "20px",
    fontSize: "16px",
    lineHeight: "22px",
    "&:hover": {
      color: "#CC0C1C",
    }
  },
}

export const RadioColorStyle = {
  '&.Mui-checked': {
    color: "#ff0f23",
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
  "&:hover": {
    color: "#CC0C1C",
    backgroundColor: "transparent",
  }
}

export const SubjectInputStyle = {
  // height: "60px",
  lineHeight: "20px",
  color: "#1A1A1A",
  fontSize: "16px",
}

export const DescInputStyle = {
  lineHeight: "20px",
  color: "#1A1A1A",
  fontSize: "16px",
}

export const EmailInputStyle = {
  lineHeight: "20px",
  color: "#1A1A1A",
  fontSize: "16px",
}

export const CancelBTnStyle = {
  textTransform: "none",
  margin: "20px 10px",
  width: "74px",
  height: "32px",
  fontSize: "14px",
  color: "#1A1A1A",
  border: "1px solid #E0E1E5",
}

export const SubmitBTnStyle = {
  textTransform: "none",
  margin: "0 10px",
  width: "74px",
  height: "32px",
  fontSize: "14px",
  color: "#FFFFFF",
  background: "#FF475D",
  border: "1px solid #E0E1E5",
  "&.Mui-disabled": {
    backgroundColor: "#FF9FA7",
    color: "#FFFFFF",
  },
}