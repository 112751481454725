// 埋点页面链接：https://stream.jd.com/ddrx/#/requirement/25347
// WARNING!!! 请勿手动修改此文件，如需修改请在子午线平台变更后，执行npm run track-init 25347
import { eventTrackingUtils } from "@/utils/eventTrackingUtils";
interface IExpoTrackParamsRecord {
  /** 举报按钮弹窗 */
  Evaluate_CommentDetail_Report_Win_Expo: {
    /** 商品id  */
    skuid: string;
    /** 评价id  */
    commentId: string;
  };
  /** 评价内容按钮曝光 */
  Evaluate_CommentDetail_Card_Btn_Expo: {
    /** 商品id  */
    skuid: string;
    /** 评价id  */
    commentId: string;
    /** 按钮名称  */
    button_name: string;
  };
  /** 更多浮层项曝光 */
  Evaluate_CommentDetail_More_Layer_Expo: {
    /** 商品id  */
    skuid: string;
    /** 评价id  */
    commentId: string;
    /** 标题  */
    title: string;
  };
}

const EXPOSURE_RECORD: Readonly<Record<keyof IExpoTrackParamsRecord, string>> = Object.freeze({
  /** 举报按钮弹窗 */
  Evaluate_CommentDetail_Report_Win_Expo: "Evaluate_CommentDetail_Report_Win_Expo",
  /** 评价内容按钮曝光 */
  Evaluate_CommentDetail_Card_Btn_Expo: "Evaluate_CommentDetail_Card_Btn_Expo",
  /** 更多浮层项曝光 */
  Evaluate_CommentDetail_More_Layer_Expo: "Evaluate_CommentDetail_More_Layer_Expo",
});

interface IClickTrackParamsRecord {
  /** 评价内容按钮点击 */
  Evaluate_CommentDetail_Card_Btn: {
    /** 商品id  */
    skuid: string;
    /** 评价id  */
    commentId: string;
    /** 按钮名称  */
    button_name: string;
  };
  /** 更多浮层项点击 */
  Evaluate_CommentDetail_More_Layer: {
    /** 商品id  */
    skuid: string;
    /** 评价id  */
    commentId: string;
    /** 标题  */
    title: string;
  };
  /** 举报弹窗点击 */
  Evaluate_CommentDetail_Report_Win: {
    /** 商品id  */
    skuid: string;
    /** 评价id  */
    commentId: string;
    /** 按钮名称  */
    button_name: string;
  };
  /** 视频图片点击 */
  Evaluate_CommentDetail_Picture: {
    /** 商品id  */
    skuid: string;
    /** 评价id  */
    commentId: string;
    /** 素材类型  */
    material_type: string;
  };
}

const sendClickTrack = {
  /** 点击事件上报-评价内容按钮点击 */
  Evaluate_CommentDetail_Card_Btn: (json_param: IClickTrackParamsRecord["Evaluate_CommentDetail_Card_Btn"]) => {
    eventTrackingUtils.submitCL({ event_id: "Evaluate_CommentDetail_Card_Btn", json_param });
  },
  /** 点击事件上报-更多浮层项点击 */
  Evaluate_CommentDetail_More_Layer: (json_param: IClickTrackParamsRecord["Evaluate_CommentDetail_More_Layer"]) => {
    eventTrackingUtils.submitCL({ event_id: "Evaluate_CommentDetail_More_Layer", json_param });
  },
  /** 点击事件上报-举报弹窗点击 */
  Evaluate_CommentDetail_Report_Win: (json_param: IClickTrackParamsRecord["Evaluate_CommentDetail_Report_Win"]) => {
    eventTrackingUtils.submitCL({ event_id: "Evaluate_CommentDetail_Report_Win", json_param });
  },
  /** 点击事件上报-视频图片点击 */
  Evaluate_CommentDetail_Picture: (json_param: IClickTrackParamsRecord["Evaluate_CommentDetail_Picture"]) => {
    eventTrackingUtils.submitCL({ event_id: "Evaluate_CommentDetail_Picture", json_param });
  },
};

export { EXPOSURE_RECORD, sendClickTrack };
export type { IExpoTrackParamsRecord };
