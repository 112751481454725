import React, { useRef, useState, useEffect } from 'react';
import styles from "./truncated.module.scss";
import VectorDown  from "@/common-components-src/assets/icons/VectorDown.svg";
import { sendClickTrack } from "@/tracks/25579";

const TruncatedName = ({ text, index, track }: {
  text: string;
  index: number;
  track: any;
}) => {
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [curSelectIndex, setCurSelectIndex] = useState(-1); // 当前选中的角标
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const checkTruncation = () => {
      if (elementRef.current) {
        const computedStyle = window.getComputedStyle(elementRef.current);
        const containerWidth = elementRef.current.offsetWidth;
        const lines = parseInt(computedStyle["-webkit-line-clamp"], 10) || 1; // 获取允许的行数

        // 创建一个临时元素来测量文本的实际宽度
        const tempSpan = document.createElement('span');
        tempSpan.style.fontFamily = computedStyle.fontFamily;
        tempSpan.style.fontSize = computedStyle.fontSize;
        tempSpan.style.fontWeight = computedStyle.fontWeight;
        tempSpan.style.visibility = 'hidden'; // 不显示
        tempSpan.innerText = text;

        document.body.appendChild(tempSpan);
        const textWidth = tempSpan.offsetWidth;
        document.body.removeChild(tempSpan);
        console.log("dhsafhdashfads------", textWidth, containerWidth * lines);

        // 判断文本宽度是否超过容器宽度, 同时需要获取当前设置的行数，乘以宽度，代表总长度
        setIsTruncated(textWidth > containerWidth * lines);
      }
    };

    checkTruncation();
    window.addEventListener("resize", checkTruncation);
    return () => {
      window.removeEventListener("resize", checkTruncation);
    };
  }, [text]);

  const handleExpand = (index: number) => {
    setCurSelectIndex(index);
    setIsExpanded(!isExpanded);
    sendClickTrack.NewCustomerGift_GiftLayer({...track, clickPos: isExpanded && index === curSelectIndex ? "3" : "4"});
  };

  return (
    <div className={styles.limitBox}
      ref={elementRef}
    >
      <div
        className={isExpanded && index === curSelectIndex ? styles.limitH100 : styles.limitH16}
      >
        {text}
      </div>
      {isTruncated &&
        <span className={styles.expanded} onClick={() => handleExpand(index)}>
          <VectorDown width={10} className={isExpanded && index === curSelectIndex ? styles.svgExpanded : styles.svgExpand} />
        </span>}
    </div>
  );
};

export default TruncatedName;
