import { observer } from "mobx-react";
import cn from "clsx";
import styles from "./index.module.scss";
import {useAppI18n} from '@/common-components-src/js/hooks/useAppI18n';

interface LoadingIconProps {
  layout?: string; // row | column
  text?: string;
  style?: React.CSSProperties;
  className?: string;
}

const UKPageNothing: React.FC<LoadingIconProps> = observer(({ layout, text, className, style }) => {
  const { t } = useAppI18n();
  
  return (
    <div className={cn(styles.pageError, className)} style={style}>
      <div className={cn(layout === "row" ? styles.row : styles.column)}>
        <img src="https://st.joy-sourcing.com/website/other/empty-box.png" />
        <span className={styles.pageErrorText}>{text ? text : t ? t("common.pageStatus.nothing") : ""}</span>
      </div>
    </div>
  );
});

export default UKPageNothing;
