// 埋点页面链接：https://stream.jd.com/ddrx/#/requirement/25532
// WARNING!!! 请勿手动修改此文件，如需修改请在子午线平台变更后，执行npm run track-init 25532 
import { eventTrackingUtils } from '@/utils/eventTrackingUtils'
interface IExpoTrackParamsRecord {
  /** 地址组件半弹层曝光 */
AddressPop_Expo: {
/** 页面类型  */
page_type: string,
/** 地址总数  */
addqty: string}
  /** 半弹层地址曝光 */
AddressPop_Addr_Expo: {
/** 页面类型  */
page_type: string,
/** 地址id  */
addrid: string,
/** 位置  */
index: number}
  /** 邮编方式选择地址曝光 */
AddressPop_AddrPostcode_Expo: {
/** 页面类型  */
page_type: string}
  /** 使用按钮曝光 */
AddressPop_Apply_Expo: {
/** 页面类型  */
page_type: string}
  /** 新建地址按钮曝光 */
AddressPop_Add_NewAddr_Expo: {
/** 页面类型  */
page_type: string}
}

const EXPOSURE_RECORD: Readonly<Record<keyof IExpoTrackParamsRecord,string>> = Object.freeze({
/** 地址组件半弹层曝光 */
  AddressPop_Expo:'AddressPop_Expo',
/** 半弹层地址曝光 */
  AddressPop_Addr_Expo:'AddressPop_Addr_Expo',
/** 邮编方式选择地址曝光 */
  AddressPop_AddrPostcode_Expo:'AddressPop_AddrPostcode_Expo',
/** 使用按钮曝光 */
  AddressPop_Apply_Expo:'AddressPop_Apply_Expo',
/** 新建地址按钮曝光 */
  AddressPop_Add_NewAddr_Expo:'AddressPop_Add_NewAddr_Expo',
})

interface IClickTrackParamsRecord {
  /** 使用按钮点击 */
AddressPop_Apply: {
/** 页面类型  */
page_type: string}
  /** 关闭按钮点击 */
AddressPop_Close: {
/** 页面类型  */
page_type: string}
  /** 新建地址按钮点击 */
AddressPop_Add_NewAddr: {
/** 页面类型  */
page_type: string}
  /** 半弹层地址选择点击 */
AddressPop_Addr_Select: {
/** 页面类型  */
page_type: string,
/** 地址id  */
addrid: string,
/** 位置  */
index: number}
  /** 邮编方式选择地址点击 */
AddressPop_AddrPostcode: {
/** 页面类型  */
page_type: string}
}

const sendClickTrack = {
  /** 点击事件上报-使用按钮点击 */
AddressPop_Apply: (json_param: IClickTrackParamsRecord['AddressPop_Apply']) => {
    eventTrackingUtils.submitCL({event_id:'AddressPop_Apply',json_param});
  },
  /** 点击事件上报-关闭按钮点击 */
AddressPop_Close: (json_param: IClickTrackParamsRecord['AddressPop_Close']) => {
    eventTrackingUtils.submitCL({event_id:'AddressPop_Close',json_param});
  },
  /** 点击事件上报-新建地址按钮点击 */
AddressPop_Add_NewAddr: (json_param: IClickTrackParamsRecord['AddressPop_Add_NewAddr']) => {
    eventTrackingUtils.submitCL({event_id:'AddressPop_Add_NewAddr',json_param});
  },
  /** 点击事件上报-半弹层地址选择点击 */
AddressPop_Addr_Select: (json_param: IClickTrackParamsRecord['AddressPop_Addr_Select']) => {
    eventTrackingUtils.submitCL({event_id:'AddressPop_Addr_Select',json_param});
  },
  /** 点击事件上报-邮编方式选择地址点击 */
AddressPop_AddrPostcode: (json_param: IClickTrackParamsRecord['AddressPop_AddrPostcode']) => {
    eventTrackingUtils.submitCL({event_id:'AddressPop_AddrPostcode',json_param});
  },
}

const sendKeyActionTrack = {
  addressLayer_Expo: (json_param: IExpoTrackParamsRecord['AddressPop_Expo']) => {
    eventTrackingUtils.submitExp({ event_id: `AddressPop_Expo`, json_param })
  },
  // 目前这些曝光方式埋点都一样，因此统一上报
  otherLayer_Expo: (json_param: IExpoTrackParamsRecord['AddressPop_AddrPostcode_Expo']) => {
    eventTrackingUtils.submitExp({ event_id: "AddressPop_AddrPostcode_Expo", json_param })
    eventTrackingUtils.submitExp({ event_id: "AddressPop_Apply_Expo", json_param })
    eventTrackingUtils.submitExp({ event_id: "AddressPop_Add_NewAddr_Expo", json_param })
  },
}

export {
  EXPOSURE_RECORD,
  sendClickTrack,
  sendKeyActionTrack,
}
export type {
  IExpoTrackParamsRecord,
}