import { Popover } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props {
  id: string;
  anchorEl: Element | null;
  open: boolean;
  onClose: () => void;
}
export default function IntlPopover({
  id,
  anchorEl,
  children,
  open,
  onClose,
}: Props & PropsWithChildren) {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      aria-hidden={false}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        zIndex: "2300",
        "& .MuiPopover-paper": {
          boxShadow: "0px 4px 28px 0px rgba(0, 0, 0, 0.08)",
        },
      }}
    >
      {children}
    </Popover>
  );
}
