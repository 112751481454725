import { useState, useEffect } from "react";
import { ReviewDetailItem, anonymousData } from "@/common-components-src/api/reviews/review.interface";
import styles from "./reviews.module.scss";
import HelpfulButton from "./HelpfulButton";
import MenuOpts from "./menuOpts";
import cn from "clsx";
// import { CUSTOM_DETAIL_OPTS_EVENT_REPLY, customElement } from "./const";
import EVENT_TYPE from "@/common-components-src/const/eventType"
import eventEmitter from "@/common-components-src/js/utils/eventEmitterUtil"
import PingLunIcon  from "@/common-components-src/assets/icons/lun.svg";
import AnonymouseIcon  from "@/common-components-src/assets/icons/anonymouse.svg";
import { sendClickTrack as reviewDetailSendClickTrack, EXPOSURE_RECORD as REVIEW_DETAIL_EXPOSURE_RECORD } from "@/tracks/25347";

interface Props {
  skuId?: string;
  commentItem: ReviewDetailItem;
  pageType?: string;
  onRefresh?: () => void;
  onDeleteCallback?: () => void;
  onAnonymousChange?: (b: boolean, data: anonymousData) => void;
}

export default function CommentBottomDetailOpts({
  commentItem,
  skuId,
  pageType,
  onRefresh,
  onDeleteCallback,
  onAnonymousChange,
}: Props) {
  const {
    anonymousFlag,
    commentId,
    parentReplyId,
    commentNum: outerCommentNum,
    currentIsUseful,
    usefulNum,
  } = commentItem || {};
  // helpfulNum, commentId, currentIsHelp
  // const commentNum = 10;
  const [commentNum, setCommentNum] = useState(outerCommentNum);
  const [isAnoymous, setIsAnonymouse] = useState<boolean>(anonymousFlag);
  const onAnonymous = (is: boolean, data: anonymousData) => {
    setIsAnonymouse(is);
    onAnonymousChange?.(is, data);
  };
  const handleReplyEvent = (e: any) => {
    const { detail } = e;
    if (detail?.data?.[commentId]) {
      const { commentCount } = detail?.data?.[commentId] || {};
      if (commentCount !== 0) {
        setCommentNum(commentCount);
      }
    }
  };

  useEffect(() => {
    eventEmitter.on(EVENT_TYPE.CUSTOM_DETAIL_OPTS_EVENT_REPLY, handleReplyEvent);
    return () => {
        eventEmitter.off(EVENT_TYPE.CUSTOM_DETAIL_OPTS_EVENT_REPLY, handleReplyEvent);
    };
  }, []);

  const [expConfig, setExpConfig] = useState<any>(null);
  useEffect(()=> {
    const config = '{"useClick": false, "stay_duration": 0.6, "repeated":false, "area_rate": 0.01}'
    setExpConfig({
      help:{ expTag:"exp|" + REVIEW_DETAIL_EXPOSURE_RECORD.Evaluate_CommentDetail_Card_Btn_Expo, data:{skuid: `${skuId}` || "-100",button_name:"1", commentId: `${commentId}` || "-100"}, config: config },
      comment:{ expTag:"exp|" + REVIEW_DETAIL_EXPOSURE_RECORD.Evaluate_CommentDetail_Card_Btn_Expo, data:{skuid: `${skuId}` || "-100",button_name:"2", commentId: `${commentId}` || "-100"}, config: config },
      more:{ expTag:"exp|" + REVIEW_DETAIL_EXPOSURE_RECORD.Evaluate_CommentDetail_Card_Btn_Expo, data:{skuid: `${skuId}` || "-100",button_name:"3", commentId: `${commentId}` || "-100"}, config: config },
      reply:{ expTag:"exp|" + REVIEW_DETAIL_EXPOSURE_RECORD.Evaluate_CommentDetail_More_Layer_Expo, data:{skuid: `${skuId}` || "-100",title:"4",commentId:`${commentId}` || "-100"}, config: config },
      delete:{ expTag:"exp|" + REVIEW_DETAIL_EXPOSURE_RECORD.Evaluate_CommentDetail_More_Layer_Expo, data:{skuid: `${skuId}` || "-100",title:"1",commentId:`${commentId}` || "-100"}, config: config },
      report:{ expTag:"exp|" + REVIEW_DETAIL_EXPOSURE_RECORD.Evaluate_CommentDetail_Report_Win_Expo, data:{skuid: `${skuId}` || "-100",commentId:`${commentId}`}, config: config },
    })
  }, [pageType, commentId, skuId])

  return (
    <div className={styles.detailBasicButton}>
      {/* {isAnoymous && <span className={styles.anonymous}><AnonymouseIcon/>Anonymous</span>} */}
      {
        expConfig && <>
          <HelpfulButton
            data={{ currentIsHelp: currentIsUseful, commentId, helpfulNum: String(usefulNum) }}
            expConfig={expConfig} 
            pageType={pageType} 
          />
          <div className={styles.line}></div>
          <div className={styles.btn}
            exptag={expConfig?.comment?.expTag}
            data-exptag-json-param={JSON.stringify(expConfig?.comment?.data)}
            data-exptag-config={expConfig?.comment?.config}
            onClick={() => {
              switch (pageType) {
                case "reviewDetail":
                  // {"button_name":"1-helpful，2-comment，3-more","commentId":""}
                  reviewDetailSendClickTrack.Evaluate_CommentDetail_Card_Btn({button_name: "2", commentId: `${commentId}` || "-100", skuid: `${skuId}` || "-100"})
                  break;
              
                default:
                  break;
              }
            }}
          >
            <PingLunIcon className={cn(styles.pinglunIcon, styles.mar)}></PingLunIcon>
            {`Comment${commentNum !== 0 ? "(" + commentNum + ")" : ""}`}
          </div>
          <div className={styles.line}></div>
          <MenuOpts
            anonymous={anonymousFlag}
            expConfig={expConfig}
            pageType={pageType}
            params={{ commentId, skuId }}
            onRefresh={onRefresh}
            onDeleteCallback={onDeleteCallback}
            onAnonymous={onAnonymous}
          />
        </>
      }
    </div>
  );
}
