/* eslint-disable @next/next/no-img-element */
import React from "react";
import style from "./style.module.scss";
import PageLeft from "./icons/pageLeft.svg";
import PageRight from "./icons/pageRight.svg";
import CloseImage from "./icons/pageClose.svg";
import { imageUtil } from "@/common-components-src/js/main";
interface PropsType {
  open: boolean;
  imageUrl: string;
  urlList: string[];
  nickName?: string;
  content?: string;
  children?: React.ReactNode;
  isContent?: boolean;
  closeView: () => void;
}
const ViewImage: React.FC<PropsType> = ({ open, urlList, content, imageUrl, children, nickName, closeView, isContent = false }) => {
  const [openImage, setOpen] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [currentUrl, setCurrentUrl] = React.useState("");
  
  React.useEffect(() => {
    setOpen(open);
    urlList?.length > 0 &&
      urlList.forEach((item, index) => {
        if (item == imageUrl) {
          setCurrentUrl(item);
          setCurrentIndex(index);
        }
      });
  }, [open, imageUrl]);
  const handlePageTurning = (type: "left" | "right") => {
    if (urlList && urlList?.length > 0) {
      if (type === "left") {
        if (currentIndex <= 0) {
          return;
        }
        urlList.forEach((item, index) => {
          if (item == currentUrl) {
            // 当前图片在 下标 为 1 的位置
            setCurrentUrl(urlList[index - 1]);
            setCurrentIndex(index - 1);
          }
        });
      } else {
        if (currentIndex >= urlList.length - 1) {
          return;
        }
        urlList.forEach((item, index) => {
          if (item == currentUrl) {
            setCurrentUrl(urlList[index + 1]);
            setCurrentIndex(index + 1);
          }
        });
      }
    } else {
      return;
    }
  };
  return (
    <>
      {openImage && (
        <div className={style.view_image}>
          <div className={style.mack}>
            <img
              className={style.img}
              src={imageUtil.concatImgUrlWithoutForceHttps(currentUrl, 1400, 0, 100, process.env.NEXT_PUBLIC_IMAGE_CDN_DOMAIN?.split(","))}
              alt=""
            />
            {/* 显示内容 */}
            {isContent && (
              <>
                <div className={style.header}>
                  <div className={style.nickName}>{nickName}</div>
                  <div className={style.currentIndex}>
                    {currentIndex + 1}/{urlList.length}
                  </div>
                </div>
                <div className={style.content}>{content}</div>
              </>
            )}
            <div className={style.children}>{children}</div>
          </div>
          <div className={style.pageLeft} onClick={() => handlePageTurning("left")}>
            <PageLeft />
          </div>
          <div className={style.pageRight} onClick={() => handlePageTurning("right")}>
            <PageRight />
          </div>
          <CloseImage className={style.closeIcon} onClick={() => closeView && closeView()} />
        </div>
      )}
    </>
  );
};

export default ViewImage;
