export default {
  CookiesSettingList: [
    {
      key: "essential",
      title: "Essential",
      content: "These cookies are essential for our app to function properly",
      isControl: false,
    },
    {
      key: "performance",
      title: "Performance",
      content: "These cookies are used to monitor the platform's performance to help us improve the user experience.",
      isControl: true,
    },
  ],
  defaultUserCookie: {
    essential: 1,
    performance: 0,
  },
  PersonalisedSettingList: [
    {
      key: "recommendations",
      title: "Personalised recommendations",
      content:
        "These features enable us to provide personalized product recommendations tailored to your preferences. Please note that if you choose to disable this permission, the platform will not utilize any of your personal information for recommendation purposes.",
      isControl: true,
    },
  ],
  defaultPersonalised: {
    recommendations: 0,
  },
};
