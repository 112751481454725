const MONITOR_LEVEL = {
  ERROR: 1,
  WARN: 2,
  INFO: 3,
};

interface ILogParams {
  code: string;
  msg?: any;
}

const logMessage = (type: number, params: ILogParams) => {
  try {
    if (!params || !params.code) return;
    const msg = params.msg ? JSON.stringify(params.msg) : "";
    const customLogger = (window as any)?.__sgm__?.custom;
    if (typeof customLogger === "function") {
      customLogger({
        type,
        code: params.code,
        msg: msg,
      });
    }
  } catch (e) {}
};

const errorLog = (params: ILogParams) => logMessage(MONITOR_LEVEL.ERROR, params);
const warningLog = (params: ILogParams) => logMessage(MONITOR_LEVEL.WARN, params);
const infoLog = (params: ILogParams) => logMessage(MONITOR_LEVEL.INFO, params);

const sgmManager = {
  errorLog,
  warningLog,
  infoLog,
}

export default sgmManager