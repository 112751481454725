import React from "react";
import { createRoot } from "react-dom/client";
import FeedbackDialog from "./index.jsx";

export default class FeedbackController {

  isOpen = false;

  constructor() {
    this.root = document.createElement("div");
    document.body.appendChild(this.root);
    this.handleClose = ()=>{
      this.isOpen = false;
    }
  }
  
  static getInstance() {
    if (!this.instance) {
      this.instance = new FeedbackController();
    }
    return this.instance;
  }

  async openFeedbackDialog() {
    if (this.isOpen) return;
    this.isOpen = true;
    const root = createRoot(this.root);
    root.render(
      React.createElement(FeedbackDialog, {
        open: true,
        closeFn: this.handleClose
      }),
    );
  }
}
