import { useEffect, useRef, useState } from "react";

interface props {
  countdown: number; // 距离结束还需要的毫秒数
  style?: React.CSSProperties; // style
  onEnd?: any; // 倒计时结束后执行的回掉函数
  format?: string //倒计时展示的格式；默认HH:mm:ss, 目前还支持mm:ss
}

// Countdown组件接收三个属性：countdown（倒计时总时间）、style（自定义样式）和onEnd（倒计时结束时的回调函数）
const Countdown = ({ countdown, style, onEnd, format='HH:mm:ss' }: props) => {
  // 使用useRef钩子创建一个只读的日期对象，用于记录倒计时开始的时间点
  const nowRef = useRef(new Date().getTime());
  // 使用useState钩子创建一个状态变量duration，用于存储当前剩余的倒计时时间
  const [duration, setDuration] = useState(countdown);
  // 定义一个默认的样式对象，包含颜色和字体大小
  const defaultStyle: React.CSSProperties = { color: "#888B94", fontSize: "14px" };

  // formatDuration函数将毫秒数转换为HH:MM:SS格式的字符串
  const formatDuration = (ms: number): string => {
    if (format === 'HH:mm:ss') {
      // 计算小时、分钟和秒数
      const hours = Math.floor(ms / (1000 * 60 * 60)); // 计算小时
      const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60)); // 计算分钟
      const seconds = Math.floor((ms % (1000 * 60)) / 1000); // 计算秒数

      // 格式化输出，确保每个部分都是两位数（例如 01、05）
      const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
      const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    } else if (format === 'mm:ss') {
      // 计算总分钟数
      const totalMinutes = Math.floor(ms / (1000 * 60));
      // 限制分钟数不超过 99
      const minutes = Math.min(totalMinutes, 99);
      const seconds = Math.floor((ms % (1000 * 60)) / 1000); // 计算秒数

      const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
      const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${formattedMinutes}:${formattedSeconds}`
    } else {
      return ``
    }
  };

  // 使用useEffect钩子来创建一个定时器，每500毫秒更新一次倒计时时间
  useEffect(() => {
    const timer = setInterval(() => {
      // 计算当前剩余的倒计时时间
      const duration = Math.max(countdown - (new Date().getTime() - nowRef.current), 0);
      // 更新状态变量duration
      setDuration(duration);
      // 如果倒计时时间已经到达0，清除定时器并调用onEnd回调函数
      if (duration <= 0) {
        clearInterval(timer);
        onEnd && onEnd();
      }
    }, 500);
    // 在组件卸载时清除定时器
    return () => {
      clearTimeout(timer);
    };
  }, [countdown]);

  // 渲染倒计时组件，使用formatDuration函数将剩余时间格式化为HH:MM:SS字符串，并将其显示在一个<span>元素中
  return <span style={{...defaultStyle,...style }}>{formatDuration(duration)}</span>;
};

export default Countdown;
