import React, { useRef, useState, useEffect } from 'react';
import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import styles from "./index.module.scss";

const UKTruncatedTooltip = ({ text, tooltipStyles, tooltipProps, textLines = 1 }: {
  text: string;
  tooltipProps: any;
  tooltipStyles?: any;
  textLines?: any;
}) => {
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const checkTruncation = () => {
      if (elementRef.current) {
        const computedStyle = window.getComputedStyle(elementRef.current);
        const containerWidth = elementRef.current.offsetWidth;
        const lines = Number(textLines) || parseInt(computedStyle["-webkit-line-clamp"], 10) || 1; // 获取允许的行数

        // 创建一个临时元素来测量文本的实际宽度
        const tempSpan = document.createElement('span');
        tempSpan.style.fontFamily = computedStyle.fontFamily;
        tempSpan.style.fontSize = computedStyle.fontSize;
        tempSpan.style.fontWeight = computedStyle.fontWeight;
        tempSpan.style.visibility = 'hidden'; // 不显示
        tempSpan.innerText = text;

        document.body.appendChild(tempSpan);
        const textWidth = tempSpan.offsetWidth;
        document.body.removeChild(tempSpan);

        // 判断文本宽度是否超过容器宽度, 同时需要获取当前设置的行数，乘以宽度，代表总长度
        setIsTruncated(textWidth > containerWidth * lines);
      }
    };

    checkTruncation();
    window.addEventListener("resize", checkTruncation);
    return () => {
      window.removeEventListener("resize", checkTruncation);
    };
  }, [text]);


  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const textStyle = {
    WebkitLineClamp: textLines,
    cursor: isTruncated ? "pointer" : "default",
  };
  return (
    <div ref={elementRef} className={`${tooltipStyles} ${styles.tooltipText}`}>
      {isTruncated ? (
        <BootstrapTooltip title={text} {...tooltipProps}>
          <span className={styles.displayTxt} style={textStyle}>{text}</span>
        </BootstrapTooltip>
      ) : (
        <span style={textStyle}>{text}</span>
      )}
    </div>
  );
};

export default UKTruncatedTooltip;