import { useState, useEffect } from 'react';
import style from './style.module.scss'
import { imageUtil } from '@/common-components-src/js/utils/imageUtil'
import JDIPrice from '@/common-components-src/js/price'
import AddCartIcon from "@/common-components-src/assets/icons/AddCart.svg"
import DefaultImg from "@/common-components-src/assets/icons/defaultImg.svg"
import {
  DialogContent, IconButton
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AddCart } from '@/common-components-src/api/cart/cart.api'
import { formatStyle, imgTipsStyle, comparePrice, getExptag } from '../../common/utils'
import { formatProductLink } from '@/common-components-src/js/utils/formatProductLink'
import type { productData_1 } from '../../index.interface'
import UKToast from "@/common-components-src/components/UKToast/index";
import UKDialog from "@/common-components-src/components/UKDialog/index";
import ProTypeModal from '@/common-components-src/components/UKProductSelect'
import { getUserUUIDFromCookie } from "@/utils/userUtils";

interface PropsType {
  productData: productData_1
}

export default function ProductCard_1({ productData }: PropsType) {
  const [imageError, setImageError] = useState(false);
  const [isShowProdectModal, setIsShowProdectModal] = useState(false); // 是否显示选择商品类型弹窗
  const [isLoaded, setIsLoaded] = useState(false); // 选择商品是否加载完成
  const [dialogStyle, setDialogStyle] = useState({
    background: "transparent",
    boxShadow: "none",
  });
  useEffect(() => {
    if (isLoaded) {
      setDialogStyle({
        background: "#fff",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      });
    } else {
      setDialogStyle({
        background: "transparent",
        boxShadow: "none",
      });
    }
  }, [isLoaded])

  //点击商卡事件
  const handleProductClick = (e: any) => {
    e.stopPropagation();
    if (trackingPointParams && trackingPointParams.productClickPoint) {
      trackingPointParams.productClickPoint()
    }
    // 检查 onClickCart 是否存在并且是一个函数
    if (onClickProduct && typeof onClickProduct === 'function') {
      onClickProduct(productData);
    }
  }
  //点击购物车事件
  const handleCartClick = (e:any, productData: productData_1) => {
    e.preventDefault();
    e.stopPropagation();
    if (trackingPointParams && trackingPointParams.cartClickPoint) {
      trackingPointParams.cartClickPoint()
    }
    // 检查 onClickCart 是否存在并且是一个函数
    if (onClickCart && typeof onClickCart === 'function') {
      onClickCart(productData);
    } else {
      setIsShowProdectModal(true);
      setIsLoaded(false);
      setDialogStyle({
        background: "transparent",
        boxShadow: "none",
      });
    }
  };

  const gotoCart = (targetSkuId: string) => {
    const params = {
      cartuuid: getUserUUIDFromCookie(),
      verticalTag: "cn_ybxt_b2c",
      userActionId: "addCart",
      operations: {
        products: [{ itemType: itemType, skuId: targetSkuId ? targetSkuId : productData.skuId, num: "1" }]
      }
    }
    addCart(params)
  };

  /**
* 加车
* https://cf.jd.com/pages/viewpage.action?pageId=605887939
*/
  const addCart = async (params: any) => {
    const res = await AddCart(params)
    if (res?.code === '200') {
      if (trackingPointParams && trackingPointParams.addCartPoint) {
        trackingPointParams.addCartPoint()
      }
      UKToast.show({
        content: "Added to Cart!", // 弹窗内容
        icon: "success", // 可选：success, fail, loading, warn, info，影响图标
      });
      if (window && (window as any).JDIheader) (window as any).JDIheader.initCartNum()
    } else {
      UKToast.show({
        content: "Added to cart Failed please try again", // 弹窗内容
        icon: "error", // 可选：success, fail, loading, warn, info，影响图标
      });
    }
  };

  let {
    width,
    height,
    containerStyle,
    shouldShowSkuName = true,
    skuName,
    skuNameStyle,
    skuImgKeyParams,
    skuImg,
    skuImgStyle,
    skuId,
    shouldShowPrice = true,
    priceStyle,
    realPrice,
    realPriceJDIPriceParams,
    originalPrice,
    originalPriceJDIPriceParams,
    currency,
    imgTips,
    shouldShowCart,
    itemType = 1,
    onClickCart,
    jumpTarget,
    onClickProduct,
    saleTipsDom,
    shouldShowMarketing = true,
    marketingStyle,
    marketingArray,
    isOutOfStock,
    isRemoved,
    trackingPointParams
  } = productData;

  containerStyle = Object.assign({}, containerStyle, { width: formatStyle(width), height: formatStyle(height) })
  function handleImageError() {
    console.log('handleImageError');
    setImageError(true);
  }

  const handleClose = () => {
    setIsShowProdectModal(false);
  };

  return (
    <div className={style.UK_product_card}
      style={containerStyle}
      key={skuId}
      exptag={getExptag('exptag', trackingPointParams, 'productExpConfig')}
      data-exptag-config={getExptag('config', trackingPointParams, 'productExpConfig')}
      data-exptag-json-param={getExptag('json-param', trackingPointParams, 'productExpConfig')}
      data-exptag-event-param={getExptag('event-param', trackingPointParams, 'productExpConfig')}
    >
      <a href={formatProductLink(skuId, skuName)} target={jumpTarget}
        onClick={(e) => handleProductClick(e)}>
        {/* 商品图片 */}
        <div className={style.UK_image_wrapper} >
          {
            imageError ?
              <DefaultImg className={`${style.UK_skuImg} ${isOutOfStock || isRemoved ? style.op50 : ''}`} /> :
              <img className={`${style.UK_skuImg} ${isOutOfStock || isRemoved ? style.op50 : ''}`}
                src={skuImgKeyParams ? imageUtil.concatImgUrlWithoutForceHttps(
                  skuImgKeyParams.imgUrlKey,
                  skuImgKeyParams?.width ? skuImgKeyParams.width * 2 : 480,
                  skuImgKeyParams?.height ? skuImgKeyParams.height * 2 : 480,
                  skuImgKeyParams?.quality || 100,
                  skuImgKeyParams?.domains,
                ) : skuImg}
                style={skuImgStyle}
                alt={skuName}
                onError={handleImageError} />
          }

          <div className={style.UK_mask}></div>
          {/* 是否展示无货状态 */}
          {
            isRemoved ?
              <div className={style.UK_outOfStock}>
                <div className={style.UK_outOfStock_text}>Removed</div>
              </div> :
              isOutOfStock ?
                <div className={style.UK_outOfStock}>
                  <div className={style.UK_outOfStock_text}>Out of stock</div>
                </div> : null
          }
          {
            imgTips && imgTips.tipDom ?
              <span className={style.UK_imgTips} style={imgTipsStyle(imgTips.position)}>
                {
                  imgTips.tipDom
                }
              </span> : null
          }
        </div>
        {/* 商品名称 */}
        {
          shouldShowSkuName &&
          <div className={style.UK_name_wrapper} style={skuNameStyle}>
            {/* 折扣标签 */}
            {
              saleTipsDom ?
                <div className={style.UK_saleTipsDom}>
                  {
                    saleTipsDom
                  }
                </div> : null
            }
            <span className={style.UK_skuName}>{skuName}</span>
          </div>
        }
      </a>

      {/* 商品价格+ 购物车 */}
      {
        shouldShowPrice &&
        <div className={style.UK_price_and_cart_wrapper} >
          <div className={style.UK_left}>
            <div className={style.UK_price} style={priceStyle}>
              <JDIPrice {...realPriceJDIPriceParams} symbol={currency} price={realPrice} uiType={1} containerStyle={{ marginRight: '8px' }} />
              {originalPrice && comparePrice(originalPrice, realPrice) && (
                <JDIPrice {...originalPriceJDIPriceParams} symbol={currency} price={originalPrice} uiType={2} lineThrough />
              )}
            </div>
          </div>
          {
            shouldShowCart && !isRemoved ?
              <div 
                className={style.UK_right}
                exptag={getExptag('exptag', trackingPointParams, 'cartExpConfig')}
                data-exptag-config={getExptag('config', trackingPointParams, 'cartExpConfig')}
                data-exptag-json-param={getExptag('json-param', trackingPointParams, 'cartExpConfig')}
                data-exptag-event-param={getExptag('event-param', trackingPointParams, 'cartExpConfig')}
              >
                <AddCartIcon className={style.UK_cart} onClick={(e:any) => handleCartClick(e, productData)}></AddCartIcon>
              </div> : null
          }
        </div>
      }
      {/* 底部营销标签 */}
      {
        shouldShowMarketing && marketingArray && marketingArray.length > 0 ? marketingArray.map((item, i) => {
          return (
            <div className={style.UK_activityRow} key={i} style={marketingStyle}>
              {item}
            </div>
          )
        }) : null
      }
      <UKDialog
        open={isShowProdectModal}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            width: "720px", // 设置自定义宽度
            maxWidth: "none", // 取消最大宽度限制
            maxHeight: "640px",
            minHeight: "334px",
            ...dialogStyle,
          },
        }}
      >
        {isLoaded && <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>}
        <DialogContent>
          <ProTypeModal
            skuId={skuId}
            handleClose={handleClose}
            setIsLoaded={setIsLoaded}
            updateItemData={gotoCart}
          />
        </DialogContent>
      </UKDialog>
    </div>
  )
}
