import { Dialog, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface DialogProps {
  open: boolean;
  onClose: () => void;
  title: string | null;
  content: React.ReactNode;
  width: number | string;
  rootStyles?: unknown;
  sx?: any;
}
export default function IntlDialog({
  open,
  onClose,
  title,
  content,
  width,
  rootStyles,
  sx,
}: DialogProps) {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      sx={{
        "& .MuiPaper-root": {
          width: `${width || 520}px`,
          maxWidth: "1024px",
          ...(rootStyles || {}),
        },
        "& .MuiDialogTitle-root": {
          fontWeight: 700,
          lineHeight: "20px",
          fontSize: "16px",
          padding: "20px 24px 12px",
        },
        "& .dialog-icon-close": {
          fontSize: "20px",
        },
        "& .intl-dialog-content": {
          padding: "0 24px 20px",
        },
        ...sx,
      }}
    >
      {title && (
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>
      )}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={() => ({
          position: "absolute",
          right: 14,
          top: 10,
        })}
      >
        <CloseIcon className="dialog-icon-close" />
      </IconButton>
      <div className="intl-dialog-content">{content}</div>
    </Dialog>
  );
}
