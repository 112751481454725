export default {
  // 需要添加每个模块的模块名称，作为特殊标识，防止i18n字段名称冲突，最好用{}对象的这种形式，增加可读性
  demoTitle: "我的订单 {{name}}",
  demoList: {
    title: "1212",
    icon: "<strong>{{username}}</strong>",
    iconPath: "https://st.joy-sourcing.com/website/home/148330874.png",
  },
  benefitTitle: "免费配送",
  footerItemTitle: ""
};
