// 埋点页面链接：https://stream.jd.com/ddrx/#/requirement/25579
// WARNING!!! 请勿手动修改此文件，如需修改请在子午线平台变更后，执行npm run track-init 25579 
import { eventTrackingUtils } from '@/utils/eventTrackingUtils'
interface IExpoTrackParamsRecord {
  /** 新人礼包弹窗曝光 */
NewCustomerGift_GiftLayerExpo: {
/** 是否登录 0-否,1-是 */
is_login: | '0'| '1',coupon: Array<{
/** 优惠券id  */
couponId: string,
/** 优惠券批次号  */
batchId: string,
/** 优惠券使用门槛  */
use_threshold: string,
/** 券类型 1-普通券,2-新人券 */
couponType: | '1'| '2',
/** 券场景  */
couponTag: string,
/** 优惠券金额  */
couponSave: string}>}
}

const EXPOSURE_RECORD: Readonly<Record<keyof IExpoTrackParamsRecord,string>> = Object.freeze({
/** 新人礼包弹窗曝光 */
  NewCustomerGift_GiftLayerExpo:'NewCustomerGift_GiftLayerExpo',
})

interface IClickTrackParamsRecord {
  /** 新人礼包弹窗点击 */
NewCustomerGift_GiftLayer: {
/** 点击位置  */
clickPos: string,
/** 邮编  */
postcode: string,
/** 是否登录 0-否,1-是 */
is_login: | '0'| '1',coupon: Array<{
/** 优惠券id  */
couponId: string,
/** 优惠券批次号  */
batchId: string,
/** 优惠券使用门槛  */
use_threshold: string,
/** 券类型 1-普通券,2-新人券 */
couponType: | '1'| '2',
/** 券场景  */
couponTag: string,
/** 优惠券金额  */
couponSave: string}>}
}

const sendClickTrack = {
  /** 点击事件上报-新人礼包弹窗点击 */
NewCustomerGift_GiftLayer: (json_param: IClickTrackParamsRecord['NewCustomerGift_GiftLayer']) => {
    eventTrackingUtils.submitCL({event_id:'NewCustomerGift_GiftLayer',json_param});
  },
}

export {
  EXPOSURE_RECORD,
  sendClickTrack,
}
export type {
  IExpoTrackParamsRecord,
}