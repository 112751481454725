import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import FooterData from "@/common-components-src/const/cookieRoot";
import { Switch } from "@mui/material";
import { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import cookieUtils from "@/utils/cookieUtils";
import { getOrSetSwitches } from "@/common-components-src/api/root/root.api.ts";
import styles from "./index.module.scss";

interface SettingListProps {
  isLogin: boolean;
  ref: any;
}

interface Personalised {
  [key: string]: any;
}

const SettingList: React.FC<SettingListProps> = forwardRef(({ isLogin }, ref) => {
  const { PersonalisedSettingList, defaultPersonalised } = FooterData;
  const [personalised, setPersonalised] = useState<Personalised>(defaultPersonalised);
  useEffect(() => {
    getPersonalisedRoot();
  }, []);

  const getPersonalisedRoot = async () => {
    if (isLogin) {
      const { code, data = [] } = await getOrSetSwitches({ scenes: [{ scene: 0, switchStatus: null }] });
      if (code === "200") {
        const newPersonalised = JSON.parse(JSON.stringify(personalised));
        data.map((item: any, index: number) => {
          newPersonalised[PersonalisedSettingList[index].key] = Number(item.switchStatus);
        });
        setPersonalised(newPersonalised);
      }
    } else {
      const personalised_consent = cookieUtils.getCookie("personalised_consent");
      if (personalised_consent) {
        setPersonalised(JSON.parse(personalised_consent));
      }
    }
  };

  const handleSwitchChange = async (checked: boolean, item: any) => {
    const newUserCookie = { ...personalised, ...{ [item.key]: Number(checked) } };
    setPersonalised(newUserCookie);
  };

  useImperativeHandle(ref, () => {
    return {
      getPersonalised: () => {
        return personalised;
      },
    };
  });

  return (
    <div className={styles.cookiesSettingWrapper}>
      {PersonalisedSettingList.map((item) => (
        <div key={item.key}>
          <div className={styles.title}>
            {item.title}
            {item.isControl ? (
              <CustomerSwitch checked={Boolean(personalised[item.key])} onChange={(e) => handleSwitchChange(e.target.checked, item)} />
            ) : (
              <span className={styles.status}>Always active</span>
            )}
          </div>
          <div className={styles.content}>{item.content}</div>
        </div>
      ))}
    </div>
  );
});

const CustomerSwitch = styled((props: SwitchProps) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
    width: "42px !important",
    height: "26px !important",
    padding: "0 !important",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#CC0C1C",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "#CC0C1C",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#CC0C1C",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#fff",
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));

export default SettingList;
