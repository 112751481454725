import { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { imageUtil } from "@/common-components-src/js/utils/imageUtil";
import cn from "clsx";
import styles from "./index.module.scss";
import DefaultImg  from "@/common-components-src/assets/icons/defaultImg.svg";

interface UKImgProps {
  url?: string; // 图片url
  urlExtend?: {
    // 图片扩展
    imgKey: string; // 图片key
    width?: number; // 图片宽度 2倍图
    height?: number; // 图片高度 2倍图
  };
  externalStyles?: React.CSSProperties;
  externalMaskStyles?: React.CSSProperties;
  className?: string;
}

const UKImg: React.FC<UKImgProps> = observer(({ url, urlExtend, externalStyles, externalMaskStyles, className }) => {
  const extendUrl = urlExtend ? imageUtil.concatImgUrlWithoutForceHttps(urlExtend.imgKey, urlExtend.width || 120, urlExtend.height || 120) : "";

  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (!url && !extendUrl) {
      setShowError(true);
    }
  }, [url, extendUrl]);

  const handleImgLoad = () => {
    setShowError(false);
  };
  const handleImgError = () => {
    setShowError(true);
  };

  return (
    <div className={cn(styles.wrap, className)} style={externalStyles}>
      {!showError && (
        <div className={styles.success_img}>
          <img src={extendUrl || url} onLoad={handleImgLoad} onError={handleImgError} />
        </div>
      )}
      {showError && (
        <div className={styles.error_img_wrap}>
          <DefaultImg className={styles.error_img} />
        </div>
      )}
      <div className={styles.img_mask} style={externalMaskStyles} />
    </div>
  );
});

export default UKImg;
