import { Button, Typography, Radio, TextField, MenuItem, InputAdornment, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import to from "await-to-js";
import { Address, PostCodeAddress } from "@/common-components-src/api/address/address.interface";
import {
  queryPostCode,
  getUserAddressList,
} from "@/common-components-src/api/address/address.api";
import NewOrEditAddress from "../addressCreate";
import UKDialog from "@/common-components-src/components/UKDialog/index";
import JDILogin from "@/common-components-src/js/newlogin/index.newlogin";
import UKToast from "@/common-components-src/components/UKToast/index"
import CloseIcon from "@mui/icons-material/Close";
import AddressAdd from "@/common-components-src/js/address/icons/AddressAdd.svg";
import AddressClear from "@/common-components-src/js/address/icons/AddressClear.svg";
import { setAddress, setArea, initAddress } from "@/utils/addressUtils";
import { curPostCodeOrAddress } from "@/common-components-src/js/utils/addressRequestUtils";
import Grid from "@mui/material/Grid2";
import { getAddressStore } from "../AddressStore";
import UKTruncatedTooltip from "@/common-components-src/components/UKTruncatedTooltip/index"
import PAGE_TYPE from "../const/pageType";
import { sendClickTrack, EXPOSURE_RECORD, sendKeyActionTrack } from "@/tracks/25532";
import { loginTrack, postTrack } from "../utils/addressTrackUtils";
import styles from "./index.module.scss";

interface DataItemProps {
  handleAddressClose?: () => void;
  isModal?: boolean;
  isOpenDialog?: boolean;
}

const AddressPostCodeList: React.FC<DataItemProps> = ({ handleAddressClose, isOpenDialog }) => {
  const addressStore = getAddressStore();

  const [isLogin, setIsLogin] = useState(false);
  const [addressList, setAddressList] = useState<Address[] | null>(null);
  const [curSelectIndex, setCurSelectIndex] = useState(-1); // 当前选中的角标
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const [formData, setFormData] = useState({
    postCode: "",
  });
  const [errors, setErrors] = useState({
    postCodeError: "",
  });

  useEffect(() => {
    loginStatus();
  }, []);

  useEffect(() => {
    if (!isOpenDialog) {
      sendClickTrack.AddressPop_Close({ page_type: loginTrack(isLogin, addressList) });
    }
  }, [isOpenDialog]);

  useEffect(() => {
    initLocalAddress();
  }, [isLogin, addressList]);

  const loginStatus = async () => {
    const { isLoggedIn } = await JDILogin.getInstance().getUserInfo();
    setIsLogin(isLoggedIn);
    if (isLoggedIn) {
      getAddressList(isLoggedIn);
      return;
    }
    sendKeyActionTrack.addressLayer_Expo(postTrack(isLoggedIn, null));
    sendKeyActionTrack.otherLayer_Expo({ page_type: loginTrack(isLoggedIn, null)});
  };

  const initLocalAddress = () => {
    initAddress().then((data) => {
      console.log("isLocal data", data);
      // 获取缓存postCode
      if (isPostCodeAddress(data)) {
        // 1、更新postCode
        // 如果缓存的兜底地址 展示空
        setFormData({ ...formData, postCode: !data?.isDefaultLocal ? data?.postCode : "" });
        // 2、更新当前选择收货地址
        matchAddress(data.postCode, addressList);
        return;
      }
      // 如果未登录不需要再次执行Address的缓存机制
      if (!isLogin) {
        return;
      }
      // 获取缓存Address
      if (isAddress(data)) {
        // 1、更新postCode
        setFormData({ ...formData, postCode: "" });
        // 2、更新当前选择收货地址
        matchAddress(data.addressId, addressList);
        return;
      }
      // 如果没有缓存，匹配收货地址顺序（默认收货地址、第一天收货地址），（后端排序规则：有默认则默认在第一条）
      if (addressList && addressList.length > 0) {
        const matchedIndex = addressList.findIndex(
          (item) => item.defAddress === true,
        );
        if (matchedIndex === -1) {
          handleRadioChange(1);
          return;
        }
        // +1 原因是addressList前增加了一个postcode类型的条目
        handleRadioChange(matchedIndex + 1);
        return;
      }
    });
  }

  // 判断是否为Address类型的类型守卫函数
  const isAddress = (data: Address | PostCodeAddress | null): data is Address => {
    if (!data) {
      return false;
    }
    return (data as Address).addressId !== undefined;
  }

  // 判断是否为PostCodeAddress类型的类型守卫函数
  const isPostCodeAddress = (data: Address | PostCodeAddress | null): data is PostCodeAddress => {
    if (!data) {
      return false;
    }
    return (data as PostCodeAddress).postCode !== undefined && !(data as Address).addressId;
  }

  // 匹配地址的函数
  const matchAddress = (identifier: string | number, addressList: Address[] | null) => {
    if (!addressList) {
      return;
    }
    let matchedIndex = -1;
    console.log("isLocal identifier", identifier);
    if (typeof identifier === "string") {
      // 如果postcode有缓存，则选择第一项
      handleRadioChange(0);
      return;
    }
    // 如果未登录不需要再次执行匹配的收货地址
    if (!isLogin) {
      return;
    }
    if (typeof identifier === "number") {
      matchedIndex = addressList.findIndex(
        (item) => item.addressId === identifier,
      );
    }
    console.log("isLocal matchedIndex", matchedIndex);
    // 把匹配的收货地址修改到数组第一条展示
    if (matchedIndex !== -1) {
      // 第0条必然是postcode，而不是收到地址列表 搜[{ type: "postcode" }, ...addressList]
      if (matchedIndex !== 0) {
        const matchedItem = addressList[matchedIndex];
        addressList.splice(matchedIndex, 1);
        addressList.unshift(matchedItem);
        setAddressList(addressList);
      }
      // 更新当前选择的收货地址的索引
      handleRadioChange(1);
    }
  };

  const getAddressList = async (login: boolean) => {
    const [err, result] = await to(getUserAddressList());

    if (err) {
      setAddressList(null);
    } else {
      const list = result?.data?.addressList;
      if (list) {
        console.log("isLocal result addressList", addressList);
        setAddressList(list as Address[]);
      }
    }
    sendKeyActionTrack.addressLayer_Expo(postTrack(login, result?.data?.addressList));
    sendKeyActionTrack.otherLayer_Expo({ page_type: loginTrack(login, result?.data?.addressList)});
  };

  const countryCodes = [
    {
      value: "USD",
      label: "UK",
    }
  ];

  const handleClose = () => {
    setOpenCreateModal(false);
  };

  const newCreateSaveClose = () => {
    handleClose();
    if (handleAddressClose) {
      handleAddressClose();
    }
  };

  const handleRadioChange = (index: number) => {
    setCurSelectIndex(index);
  };

  const cardFloor = (item: Address | {
    type: string;
  }) => {
    if (!item || typeof item !== 'object') {
      return null;
    }
    const address = item as Address;
    return (
      <div className={styles.box}>
        <div className={styles.flex}>
          <UKTruncatedTooltip
            text={address?.name}
            tooltipStyles={styles.country}
            tooltipProps={{
              placement: "top",
              arrow: true
            }}
          />
          <div className={styles.phone}>{address?.areaCode ? "+" + address?.areaCode : ""} {address?.mobile}</div>
        </div>
        <UKTruncatedTooltip
          text={address?.fullAddress}
          textLines={2}
          tooltipStyles={styles.address}
          tooltipProps={{
            placement: "top",
            arrow: true
          }}
        />
      </div>
    );
  };

  const handlePostCodeBlur = async () => {
    if (!formData || !formData.postCode) {
      // setErrors({
      //   ...errors,
      //   postCodeError: "Please enter your postcode."
      // });
      // UKToast.show({
      //   content: "Please enter your postcode.",
      //   icon: "error",
      // });
      return;
    }
    const postCodeValue = formData.postCode.replace(/\s+/g, ''); // 去掉所有空格
    // const postCodeRegex = /^[a-zA-Z]{1,2}[0-9]{1,2}[a-zA-Z]? [0-9][a-zA-Z]{2}$/;
    const postCodeRegex = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;

    if (!postCodeRegex.test(postCodeValue)) {
      setErrors({
        ...errors,
        postCodeError: "Please enter a valid postcode, such as: SW1A 1AA, EC1A 1BB"
      });
      UKToast.show({
        content: "Please enter a valid postcode, such as: SW1A 1AA, EC1A 1BB",
        icon: "error",
      });
    } else {
      setErrors({
        ...errors,
        postCodeError: ""
      });
    }
  };

  const validateForm = () => {
    const newErrors = { ...errors };
    if (!formData || !formData.postCode) {
      setErrors({
        ...errors,
        postCodeError: "Please enter your postcode."
      });
      UKToast.show({
        content: "Please enter your postcode.",
        icon: "error",
      });
      return false;
    }
    // Postcode
    const postCodeValue = formData.postCode.replace(/\s+/g, ''); // 去掉所有空格
    // const postCodeRegex = /^[a-zA-Z]{1,2}[0-9]{1,2}[a-zA-Z]? [0-9][a-zA-Z]{2}$/;
    const postCodeRegex = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;
    if (!postCodeRegex.test(postCodeValue)) {
      setErrors({
        ...errors,
        postCodeError: "Please enter a valid postcode, such as: SW1A 1AA, EC1A 1BB"
      });
      UKToast.show({
        content: "Please enter a valid postcode, such as: SW1A 1AA, EC1A 1BB",
        icon: "error",
      });
    } else {
      setErrors({
        ...errors,
        postCodeError: ""
      });
    }
    const hasErrors = Object.values(newErrors).some((error) => error !== "");
    return !hasErrors;
  };

  const handleApply = async () => {
    console.log("formData", formData);
    // 已登录 未选择
    if (isLogin) {
      if (curSelectIndex === -1 && addressList && addressList.length > 0) {
        UKToast.show({
          content: "Please enter your postcode or select a shipping address",
          icon: "error",
        });
        return
      }
      // curSelectIndex 为0 则先请求PostCode
      if (addressList && addressList.length > 0) {
        if (curSelectIndex === 0) {
          requestPostCodeApply();
          return;
        }
        // 更新缓存 需要去掉postcode第一项
        setAddress(addressList[curSelectIndex - 1]);
        const postCode = await curPostCodeOrAddress();
        const detailPostCode = await curPostCodeOrAddress(true);
        addressStore.setPostCode(postCode);
        addressStore.setDetailPostCode(detailPostCode);
        if (handleAddressClose) {
          handleAddressClose();
        }
        location.reload();
        return;
      }
      // 已登录无收货地址 提交需校验postCode
      requestPostCodeApply();
      return;
    }
    // 未登录提交需校验postCode
    requestPostCodeApply();
  };

  const requestPostCode = (postCode: string) => {
    if (!postCode) {
      return "";
    }
    return postCode.replace(/\s+/g, ''); // 去掉所有空格
  };

  const requestPostCodeApply = async () => {
    // 请求postcode校验接口
    const isValid = validateForm();
    if (isValid) {
      if (!formData || !formData.postCode) {
        return;
      }
      const postCodeValue = requestPostCode(formData.postCode);
      // 先将formData中的postCode字段的值转为大写
      const [err, result] = await to(
        queryPostCode({ postCode: postCodeValue ? postCodeValue.toUpperCase() : postCodeValue }),
      );
      if (result?.code === "200" && result?.data) {
        setFormData({ ...formData, postCode: result?.data?.postCode });
        setErrors({ ...errors, postCodeError: "" });
        // 同时更新缓存
        setArea(result?.data)
        // 未登录场景下 postcode是一样的
        const postCode = await curPostCodeOrAddress();
        addressStore.setPostCode(postCode);
        addressStore.setDetailPostCode(postCode);
        if (handleAddressClose) {
          handleAddressClose();
        }
        location.reload();
      } else {
        if (result?.msg) {
          // setErrors({ ...errors, postCodeError: result?.msg });
          UKToast.show({
            content: result?.msg,
            icon: "error",
          });
        }
      }
    }
  }

  const cardPostCodeFloor = () => {
    return (
      <div>
        <div className={styles.postCode}>Postcode</div>
        <div style={{ display: "flex", marginTop: "4px" }}>
          <TextField
            id="outlined-select-currency"
            select
            sx={{ width: 100 }}
            // label="Post Code"
            size="small"
            slotProps={{
              input: {
                readOnly: true,
                startAdornment: (
                  <img
                    width={18}
                    height={12}
                    style={{ marginRight: "4px" }}
                    src="https://st.joy-sourcing.com/website/other/avatar.png"
                    alt="icon"
                  />
                ),
              },
            }}
            value={countryCodes[0].value}
            onFocus={() => {
              sendClickTrack.AddressPop_AddrPostcode({ page_type: loginTrack(isLogin, addressList) });
            }}
          >
            {countryCodes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="outlined-basic"
            label="Enter Postcode"
            variant="outlined"
            size="small"
            onBlur={handlePostCodeBlur}
            error={!!errors.postCodeError}
            // helperText={errors.postCodeError}
            value={formData.postCode}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, postCode: event.target.value });
            }}
            onFocus={() => {
              sendClickTrack.AddressPop_AddrPostcode({ page_type: loginTrack(isLogin, addressList) });
            }}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    {formData.postCode && (
                      <IconButton onClick={() => {
                        setFormData({ ...formData, postCode: "" });
                      }}>
                        <AddressClear />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              },
              htmlInput: {
                maxLength: 20,
                minLength: 3,
              }
            }}
            sx={{ width: (isLogin && addressList && addressList?.length > 0) ? 250 : 716, marginLeft: "8px" }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Address or Postcode</div>
      <div className={styles.info}>
        Delivery options and delivery speeds may vary for different area
      </div>
      <div
        style={{ overflowY: "auto", maxHeight: "380px", paddingBottom: "68px", minHeight: "248px" }}
        className={styles["no-scrollbars"]}
      >
        {/* 地址列表 */}
        {(isLogin && addressList && addressList.length > 0) ? <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          {addressList && [{ type: "postcode", addressId: "" }, ...addressList].map((item, index) => [
            <Grid size={6} key={index}>
              <div
                className={curSelectIndex === index ? styles.flexLeft : styles.flexLeftGray}
                exptag={"exp|" + EXPOSURE_RECORD.AddressPop_Addr_Expo}
                data-exptag-config='{"useClick": false, "stay_duration": 0.3, "repeated":false, "area_rate": 0.01}'
                data-exptag-json-param={JSON.stringify({ page_type: loginTrack(isLogin, addressList), addrid: `${item?.addressId}`, index })}
                onClick={() => {
                  handleRadioChange(index);
                  if (item?.type !== "postcode") {
                    sendClickTrack.AddressPop_Addr_Select({ page_type: loginTrack(isLogin, addressList), addrid: `${item?.addressId}`, index });
                  }
                }}>
                <Radio
                  checked={curSelectIndex === index}
                  // onChange={(event) => {
                  //   handleRadioChange(index);
                  // }}
                  value="a"
                  name="radio-buttons"
                  style={{ padding: 0 }}
                  sx={{
                    color: "#E0E1E5",
                    "&.Mui-checked": {
                      color: "#CC0C1C",
                    },
                  }}
                  inputProps={{ "aria-label": "A" }}
                />
                {/* 地址卡片 这里新增postcode类型是为了在第一个item展示postcode */}
                {item.type === "postcode" ? cardPostCodeFloor() : cardFloor(item)}
              </div>
            </Grid>,
          ])}
        </Grid> : cardPostCodeFloor()}
      </div>
      <div className={isLogin ? styles.bottomFloat : styles.buttonContainer}>
        {isLogin && <Button
          className={styles.add}
          onClick={() => {
            setOpenCreateModal(true)
            sendClickTrack.AddressPop_Add_NewAddr({ page_type: loginTrack(isLogin, addressList) });
          }}
          style={{ opacity: addressList && addressList?.length >= 30 ? 0.4 : 1 }}
          disabled={addressList && addressList?.length >= 30 ? true : false}>
          <AddressAdd /> Add a new address
        </Button>}
        <Button
          className={styles.closeButton}
          onClick={() => {
            handleApply();
            sendClickTrack.AddressPop_Apply({ page_type: loginTrack(isLogin, addressList) });
          }}>
          Apply
        </Button>
      </div>
      {/* 创建地址 弹窗 */}
      <UKDialog
        open={openCreateModal}
        onClose={handleClose}
        showCloseBtn={true}
        sx={{
          "& .MuiDialog-paper": {
            width: "880px", // 设置自定义宽度
            Height: "596px",
            // padding: "0px",
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <Typography>
          <NewOrEditAddress handleClose={newCreateSaveClose} isHeaderModal={true} isModal={true} pageType={PAGE_TYPE.ADDRESS_LIB} />
        </Typography>
      </UKDialog>
    </div>
  );
};

export default AddressPostCodeList;
