import cn from "clsx";
import styles from "./reviewDetail.module.scss";
import CommentBottomDetailOpts from "./commentBottomDetailOpts";
import {
  publishReplyComment,
  handleDeleteReply,
  fetchUserReviewDetail,
} from "@/common-components-src/api/reviews/review.api";
import {
  anonymousData,
  SecondaryReply,
  PublishReplyReq,
  ReviewDetailItem,
} from "@/common-components-src/api/reviews/review.interface";
import CustomTextarea from "./customTextArea";
import { styled as muiStyled } from "@mui/material/styles";
import { useEffect, useState, PropsWithChildren } from "react";
// import { CUSTOM_DETAIL_OPTS_EVENT_REPLY, customElement } from "./const";
import { Button, DialogActions, DialogContent, DialogContentText, Avatar, DialogTitle } from "@mui/material";
import RatingDisplay from "./ratingDisplay";
import VideoPay  from "@/common-components-src/assets/icons/videoPay_top.svg";
import PreviewImageVideo from "@/common-components-src/components/previewImageVideo";
import { imageUtil } from "../utils/imageUtil";
import UKToast from "@/common-components-src/components/UKToast/index";
import UKDialog from "@/common-components-src/components/UKDialog/index";
import zIndex from "@mui/material/styles/zIndex";
import EVENT_TYPE from "@/common-components-src/const/eventType"
import eventEmitter from "@/common-components-src/js/utils/eventEmitterUtil"
import { sendClickTrack } from "@/tracks/25217";
import { sendClickTrack as reviewDetailSendClickTrack } from "@/tracks/25347";


interface Props extends PropsWithChildren<unknown> {
  lists: ReviewDetailItem[];
  skuId: string;
  onRefresh?: () => void;
  onClose?: () => void;
  onReqDetail?: () => void;
  onAnonymousChange?: (b: boolean,v: any) => void;
}

interface SecondaryProps {
  skuId: string;
  commentId: string;
  list: SecondaryReply[];
  customReplyList?: CustomReplyProps[];
  onReqDetail?: () => void;
}

interface CustomReplyProps {
  commentId: string;
  userNickName: string;
  userImage: string;
  commentDate: string;
  content: string;
  replyNickname: string;
  canDelete: boolean;
}

interface ReplyDataProps {
  skuId: string;
  commentId: string;
  item: SecondaryReply;
  onReplyChange?: (value: CustomReplyProps) => void;
  onReqDetail?: () => void;
}

interface CustomItemDataProps {
  item: CustomReplyProps;
}

export const SubmitButton = muiStyled(Button)(() => ({
  fontSize: "12px",
  padding: "0 8px",
  borderRadius: "4px",
  lineHeight: "28px",
  textAlign: "center",
  fontWeight: 400,
  color: "#fff",
  background: "linear-gradient(90deg, #FF475D 0%, #FF0F23 100%)",
  "&:hover": {
    background: "linear-gradient(90deg, #FF475D 0%, #FF0F23 100%)",
    boxShadow: "none",
  },
  "&.Mui-disabled": {
    opacity: 0.5,
    cursor: "no-drop",
    color: "#fff",
  },
}));

const CustomSecondaryReplyItem = ({ item }: CustomItemDataProps) => {
  return (
    <div className={cn(styles.child, styles["depth-2"])}>
      <div className={styles.childHeader}>
        <div className={styles.avatar}>
          <img
            src={imageUtil.concatImgUrlWithoutForceHttps(item.userImage, 140, 140, 70)}
            width={20}
            height={20}
            alt=""
          />
          <span>{item.userNickName}</span>
        </div>
        <div className={styles.dateTime}>{item.commentDate}</div>
      </div>
      <div className={styles.content}>
        <div className={styles.staticText}>
          {item.replyNickname && (
            <span className={styles.replyNickName}>
              Reply{" "}
              {item.replyNickname.length >= 20
                ? `${item.replyNickname?.slice(0, 19)}...`
                : item.replyNickname}
            </span>
          )}
          <span>{item.content}</span>
        </div>
      </div>
    </div>
  );
};

const avatarFn = (v: any) =>
  v
    ? v.startsWith("http://") || v.startsWith("https://") || v.startsWith("//")
      ? v
      : imageUtil.concatImgUrlWithoutForceHttps(v)
    : "";
const SecondaryReplyItem = ({
  item,
  commentId,
  onReplyChange,
  skuId,
  onReqDetail,
}: ReplyDataProps) => {
  const [isShowReply, setIsShowReply] = useState<boolean>();
  const [replyContent, setReplyContent] = useState<string>("");
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const onTextareaChange = (value: string) => {
    setReplyContent(value);
  };

  const onSecondaryReply = () => {
    setIsShowReply(!isShowReply);
    sendClickTrack.Productdetail_ReviewsFloor_CommentBtn_Click();
  };
  const onSecondaryReplySubmit = async (item: SecondaryReply) => {
    const { id: targetReplyId, parentReplyId, userNickName } = item;
    const res: any = await publishReplyComment({
      targetReplyId,
      parentReplyId,
      anonymousFlag: false,
      commentId,
      content: replyContent,
    });
    const { code } = res || {};
    const { saveResult, ...rest } = res?.data || {};
    if (saveResult) {
      onReplyChange?.({
        ...rest,
        replyNickname: userNickName,
        content: replyContent,
        canDelete: true,
      } as CustomReplyProps);
      setReplyContent("");
      setIsShowReply(false);
      onReqDetail?.();
    } else {
      UKToast.show({
        icon: "error",
        content: res?.msg,
      });
    }
    console.log("onSecondaryReplySubmit111", res);
  };
  const onReplyDelete = async () => {
    const res = await handleDeleteReply({ replyId: item.id, skuId });
    if (res?.data?.deleteResult) {
      setIsDelete(true);
      onReqDetail?.();
      // onDetailRefresh?.({ commentId, parentReplyId: 0 });
    } else {
      UKToast.show({
        icon: "error",
        content: `Failed to delete, please try again.`,
      });
    }
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  if (isDelete) {
    return null;
  }
  return (
    <div style={{ padding: "6px 0" }} className={cn(styles.child, styles["depth-2"])}>
      <div className={styles.childHeader}>
        <div className={styles.avatar}>
          <div className={styles.avatarBox}>
            <Avatar
              sizes="20px"
              src={avatarFn(item.userImage) || ""}
              alt=""
              sx={{
                width: 24,
                height: 24,
                bgcolor: "#E1E3E6",
                "& .MuiSvgIcon-root": {
                  width: "100%",
                  height: "100%",
                  fill: "#F0F1F2",
                },
              }}
            />
          </div>

          <span>{item.userNickName}</span>
          {/* <em>{item.commentDate}</em> */}
          {item?.tag && (
            <span
              className={cn(
                styles.tag,
                item?.tag == "From Joybuy" && styles.tagJoy,
                item?.tag == "Author" && styles.tagAuthor,
              )}
            >
              {item?.tag}
            </span>
          )}
        </div>
        {/* <div className={styles.dateTime}>{item.commentDate}</div> */}
      </div>
      <div className={styles.content}>
        <div className={styles.staticText}>
          {item.replyNickname && (
            <span className={styles.replyNickName}>
              Reply{" "}
              {item.replyNickname.length >= 20
                ? `${item.replyNickname?.slice(0, 19)}...`
                : item.replyNickname}
            </span>
          )}
          <span>{item?.content || ""}</span>
        </div>
        <div className={styles.replyBtn}>
          {item.canDelete && <span onClick={() => setDialogOpen(true)}>Delete</span>}
          <span onClick={() => onSecondaryReply()}>Reply</span>
        </div>
      </div>
      {isShowReply && (
        <div className={styles.commentReply}>
          <CustomTextarea
            value={replyContent}
            onChange={onTextareaChange}
            placeholder="You may add any supplementary information in this section."
          />
          <p className={styles.opt}>
            <button className={styles.submitbtn} onClick={() => replyContent && onSecondaryReplySubmit(item)}>
              Submit
            </button>
          </p>
        </div>
      )}
      <UKDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        showCloseBtn={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{zIndex: 2300}}
      >
        <DialogTitle/>
        <DialogContent style={{paddingTop:"20px"}}>
          <DialogContentText id="alert-dialog-description">
            Please confirm you want to delete your review?This action cannot be undone!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={onReplyDelete} autoFocus variant="contained">
            Delete
          </Button>
        </DialogActions>
      </UKDialog>
    </div>
  );
};
const SecondaryReplyList = ({
  list,
  customReplyList,
  commentId,
  skuId,
  onReqDetail,
}: SecondaryProps) => {
  const [innerCustomReplyList, setInnerCustomReplyList] = useState<CustomReplyProps[]>(
    customReplyList || [],
  );
  const onReplyChange = (value: CustomReplyProps) => {
    setInnerCustomReplyList([value, ...innerCustomReplyList]);
  };
  useEffect(() => {
    setInnerCustomReplyList(customReplyList || []);
  }, [customReplyList]);

  return (
    <div className={styles.secondaryReplyList}>
      {/* {innerCustomReplyList?.map((item: CustomReplyProps) => {
        return <CustomSecondaryReplyItem key={item.commentId} item={item} />;
      })} */}
      {[...innerCustomReplyList, ...list]?.map((item: any, index: number) => {
        return (
          <SecondaryReplyItem
            key={index}
            item={item}
            commentId={commentId}
            skuId={skuId}
            onReplyChange={onReplyChange}
            onReqDetail={onReqDetail}
          />
        );
      })}
    </div>
  );
};

// 评论详情弹框
export default function ReviewDetailList({ lists, skuId, onRefresh, onClose, onAnonymousChange }: Props) {
  const [isOpenReviewImage, setIsOpenReviewImage] = useState<boolean>(false);
  const [currentImageUrl, setCurrentImageUrl] = useState<string>("");
  const [replyContent, setReplyContent] = useState<string>("");
  const [customReplyList, setCustomReplyList] = useState<CustomReplyProps[]>([]);
  const [userLocalData, setUserLocalData] = useState<anonymousData>();

  const closeReviewImage = () => {
    setIsOpenReviewImage(false);
  };
  const onReqDetail = async (params: { parentReplyId: string | number; commentId: string }) => {
    const res = await fetchUserReviewDetail({
      skuId,
      ...params,
    });
    if (res?.code === "200") {
      const localCommentCount = res?.data?.replyList?.length || 0;
      eventEmitter.emit(EVENT_TYPE.CUSTOM_DETAIL_OPTS_EVENT_REPLY, {
        detail: {
          data: {
            [params?.commentId]: {
              commentCount: localCommentCount,
            },
          },
        },
      });
      // customElement.dispatchEvent(
      //   new CustomEvent(CUSTOM_DETAIL_OPTS_EVENT_REPLY, {
      //     detail: {
      //       data: {
      //         [params?.commentId]: {
      //           commentCount: localCommentCount,
      //         },
      //       },
      //     },
      //   }),
      // );
    }
  };
  const onReplySubmit = async (params: PublishReplyReq) => {
    const res: any = await publishReplyComment(params);
    const { code } = res || {};
    const { saveResult, ...rest } = res?.data || {};
    if (saveResult) {
      setCustomReplyList([
        {
          ...(rest as CustomReplyProps),
          content: replyContent,
          canDelete: true,
        },
        ...customReplyList,
      ]);
      setReplyContent("");
      onReqDetail({
        parentReplyId: params.parentReplyId || 0,
        commentId: params.commentId,
      });
    } else {
      UKToast.show({
        icon: "error",
        content: res?.msg,
      });
    }
  };

  const onTextareaChange = (value: string) => {
    setReplyContent(value);
  };
  const onAnonymousFn = (b: boolean, data: anonymousData) => {
    setUserLocalData(data);
    onAnonymousChange?.(b, data);
  };

  console.log("lists", lists);
  return (
    <>
      <div className={styles.reviewListWrapper}>
        {lists?.map((item: ReviewDetailItem, key: number) => {
          const {
            commentSku,
            commentDate,
            content,
            replyTreeCount,
            usefulVoteCount,
            usefulVoteResult,
            commentId,
            imageList,
            videoList,
            replyList,
            parentReplyId = 0,
          } = item;
          const userImage =
            userLocalData?.commentId === commentId
              ? userLocalData?.userImage || item.userImage
              : item.userImage;
          const userNickName =
            userLocalData?.commentId === commentId
              ? userLocalData?.nickName || item.userNickName
              : item.userNickName;
          return (
            <div className={styles.reviewsItemBlock} key={key}>
              <div className={styles.reviewsItem}>
                <div className={styles.reviewsAvatar}>
                  <Avatar
                    src={avatarFn(userImage) || ""}
                    alt=""
                    sx={{
                      bgcolor: "#E1E3E6",
                      "& .MuiSvgIcon-root": {
                        width: "100%",
                        height: "100%",
                        fill: "#F0F1F2",
                      },
                    }}
                  />
                  <>{/* propertyExt */}</>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                      <p className={styles.reviewsName}>{userNickName}</p>
                      <RatingDisplay value={Number(item.star)} hiddenScoreNum />
                    </div>
                    <div style={{ fontSize: "14px", color: "#888B94", margin: "0 5px" }}>
                      {item?.propertyExt}
                    </div>
                  </div>
                </div>
                <div className={cn(styles.basicFont, styles.reviewsMsg)}>
                  <div className={styles.reviewsDate}>
                    <span>{commentSku?.saleAttr}</span>
                    <span>{commentDate}</span>
                  </div>
                </div>
              </div>
              <p className={cn(styles.basicFont, styles.reviewsContent)}>{content}</p>
              <div className={styles.mediaZone}>
                {videoList &&
                  videoList.length > 0 &&
                  videoList?.map((v: any, index) => (
                    <div
                      key={index}
                      style={{ width: "140px", height: "140px", position: "relative" }}
                    >
                      {/* <VideoPlayer position="top" maskType="ordinary" title={newUserNickName} content={content} src={item.videoUrl ? item.videoUrl : ""} /> */}
                      <video
                        style={{
                          width: "140px",
                          height: "140px",
                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                        src={v.videoUrl ? v.videoUrl : ""}
                        onClick={() => {
                          reviewDetailSendClickTrack.Evaluate_CommentDetail_Picture({material_type: "2", skuid: `${skuId || '-100'}`, commentId: `${commentId || '-100'}` })
                          setIsOpenReviewImage(true);
                          setCurrentImageUrl(v.videoUrl);
                        }}
                      ></video>
                      <VideoPay style={{ position: "absolute", top: "10px", left: "10px" }} />
                    </div>
                  ))}
                {imageList?.map((_, index) => (
                  <img
                    onClick={() => {
                      reviewDetailSendClickTrack.Evaluate_CommentDetail_Picture({material_type: "1", skuid: `${skuId || '-100'}`, commentId: `${commentId || '-100'}` })
                      setIsOpenReviewImage(true);
                      setCurrentImageUrl(_.imageUrl);
                    }}
                    key={index}
                    src={imageUtil.concatImgUrlWithoutForceHttps(_.imageUrl, 140, 0, 100)}
                    alt=""
                  />
                ))}
                <PreviewImageVideo
                  isContent={true}
                  content={content}
                  nickName={item.userNickName}
                  urlList={[
                    ...(videoList || []).map((item: any) => ({ type: 0, url: item.videoUrl })),
                    ...(imageList || []).map((item: any) => ({ type: 1, url: item.imageUrl })),
                  ]}
                  open={isOpenReviewImage}
                  imageUrl={currentImageUrl}
                  closeView={closeReviewImage}
                />
                {/* 大图预览 */}
                {/* <ViewImage
                  isContent={true}
                  nickName={item.userNickName}
                  content={item.content}
                  open={isOpenReviewImage}
                  imageUrl={currentImageUrl}
                  urlList={imageList.map((_) => _.imageUrl)}
                  closeView={closeReviewImage}
                /> */}
              </div>
              <CommentBottomDetailOpts
                pageType="reviewDetail"
                commentItem={item}
                skuId={skuId}
                onRefresh={onRefresh}
                onDeleteCallback={onClose}
                onAnonymousChange={onAnonymousFn}
              />
              <div className={styles.commentReply}>
                <CustomTextarea
                  value={replyContent}
                  onChange={onTextareaChange}
                  placeholder="You may add any supplementary information in this section."
                />
                {/* <span className={styles.num}>1/1000</span> */}
                <p className={styles.opt}>
                  <button
                    className={styles.submitbtn}
                    onClick={() =>
                      replyContent &&
                      onReplySubmit({
                        commentId,
                        parentReplyId: 0,
                        content: replyContent,
                        anonymousFlag: false,
                      })
                    }
                  >
                    Submit
                  </button>
                </p>
              </div>
              <SecondaryReplyList
                list={replyList || []}
                customReplyList={customReplyList}
                commentId={commentId}
                skuId={skuId}
                onReqDetail={() =>
                  onReqDetail({
                    commentId,
                    parentReplyId: 0,
                  })
                }
              />
            </div>
          );
          // return renderReviewItem(item, key, false);
        })}
      </div>
      {/* 大图预览 */}
      {/* <IntlDialog
        onClose={closeReviewImage}
        open={isOpenReviewImage}
        title={null}
        content={<></>}
        width={800}
        rootStyles={{ background: "none", boxShadow: "none" }}
      /> */}
    </>
  );
}
