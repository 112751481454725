import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef } from "react";
import CookiesCloseIcon  from "@/common-components-src/assets/icons/cookiesCloseIcon.svg";
import { Button, DialogTitle, IconButton, DialogContent } from "@mui/material";
import { styled } from "@mui/system";
import UKDialog from "@/common-components-src/components/UKDialog/index.tsx";
import SettingList from "./components/settingList/index.tsx";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./index.module.scss";
import cookieUtil from "@/utils/cookieUtils";
import loginConfig from "@/constants/loginConfig";
import FooterData from "@/common-components-src/const/cookieRoot";
import cn from "clsx";



const CookiesManage = forwardRef((props, ref) => {
  const { CookiesSettingList } = FooterData;
  const [isShowMange, setIsShowMange] = useState<boolean>(false); // 默认不展示
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const settingListRef = useRef<{ getUseCookie: any}>();

  useEffect(() => {
    const cookie_consent = cookieUtil.getCookie("cookie_consent");
    const isShowCookiesManage = cookieUtil.getSessionStorage("isShowCookiesManage") || 1;
    setIsShowMange(!Boolean(cookie_consent) && !!Number(isShowCookiesManage));
  }, []);


  useImperativeHandle(ref, () => {
    return {
      setIsOpen: setIsOpenDialog,
    };
  });


  const handleClose = () => {
    setIsOpenDialog(false);
  };

  const handleConfirmChoies = () => {
    if (settingListRef.current) {
      const useCookie = settingListRef.current?.getUseCookie();
      cookieUtil.setCookie("cookie_consent", JSON.stringify(useCookie), 365, loginConfig.POSTFIX_DOMAIN);
      setIsShowMange(false);
      setIsOpenDialog(false);
    }
  };

  const handleRejectAll = () => {
    const rejectAllUseCookie: any = {};
    CookiesSettingList.forEach((item) => {
      rejectAllUseCookie[item.key] = 0;
    });
    cookieUtil.setCookie("cookie_consent", JSON.stringify(rejectAllUseCookie), 365, loginConfig.POSTFIX_DOMAIN);
    setIsShowMange(false);
  };

  const handleAllowAll = () => {
    const allowAllUseCookie: any = {};
    CookiesSettingList.forEach((item) => {
      allowAllUseCookie[item.key] = 1;
    });
    cookieUtil.setCookie("cookie_consent", JSON.stringify(allowAllUseCookie), 365, loginConfig.POSTFIX_DOMAIN);
    setIsShowMange(false);
  };

  const handleHideManage = () => {
    setIsShowMange(false);
    cookieUtil.setSessionStorage("isShowCookiesManage", 0);
  };

  return (
    <div className={cn(styles.cookiesWrapper, isShowMange ? styles.showCookiesManage : styles.hideCookiesManage)}>
      <div className={styles.mainWrapper}>
        <CookiesCloseIcon className={styles.close} onClick={handleHideManage} />
        <div className={styles.title}>Manage cookies</div>
        <div className={styles.content}>
        When you visit any website,it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you,
          your preferences or your device and is mostly used to make the site work as you expect it to.The information does not usually directly identify you, but
          it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies.{" "}
          <a className={styles.link} href="/help/cookie-policy" target="_self">
            More information
          </a>
        </div>
        <div className={styles.btnWrapper}>
          <Button
            variant="contained"
            disableElevation
            sx={{
              color: "#FFF",
              height: "32px",
              backgroundColor: "transparent",
              textTransform: "none",
              border: "1px solid #FFF",
              marginRight: "16px",
              lineHeight: "18px",
              padding: "6px 15px",
            }}
            onClick={() => setIsOpenDialog(true)}
          >
            Manage cookies
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={{
              color: "#FFF",
              height: "32px",
              backgroundColor: "transparent",
              textTransform: "none",
              border: "1px solid #FFF",
              marginRight: "16px",
              lineHeight: "18px",
              padding: "6px 15px",
            }}
            onClick={handleRejectAll}
          >
            Reject all
          </Button>
          <Button
            size="small"
            variant="contained"
            disableElevation
            sx={{
              color: "#",
              height: "32px",
              backgroundColor: "#CC0C1C",
              textTransform: "none",
              border: "none",
              lineHeight: "18px",
              padding: "6px 15px",
            }}
            onClick={handleAllowAll}
          >
            Allow all
          </Button>
        </div>
      </div>
      <UKDialog
        open={isOpenDialog}
        onClose={handleClose}
        showCloseBtn={false}
        sx={{
          "&": {
            zIndex: 2002,
          },
          "& .MuiDialog-paper": {
            width: "520px",
            maxWidth: "none",
            borderRadius: "12px",
            zIndex: 2003,
          },
          "& .css-1cennmq-MuiBackdrop-root-MuiDialog-backdrop": {
            zIndex: 2002,
          },
        }}
      >
        <DialogTitle
          sx={{
            height: "20px",
            padding: "0 24px",
            fontSize: "16px",
            lineHeight: "20px",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          Manage cookies
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <CustomerDialogContent>
          When you visit any website,it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you,
          your preferences or your device and is mostly used to make the siteworkasyou expect it to.The information does not usually directly identify you, but
          it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies.
          <a href="/help/cookie-policy" target="_self">
            More informations
          </a>
          <SettingList ref={settingListRef} />
        </CustomerDialogContent>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: "12px",
            boxSizing: "border-box",
            padding: "24px",
          }}
        >
          <Button
            size="small"
            variant="contained"
            disableElevation
            sx={{
              color: "#FFF",
              height: "32px",
              backgroundColor: "#CC0C1C",
              textTransform: "none",
              border: "none",
              lineHeight: "18px",
              fontSize: "14px",
              padding: "7px 25px",
              borderRadius: "4px",
            }}
            onClick={handleConfirmChoies}
          >
            Confirm my choices
          </Button>
        </div>
      </UKDialog>
    </div>
  );
});

const CustomerDialogContent = styled(DialogContent)({
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "18px",
  color: "#888B94",
  "& a": {
    color: "#1869F5",
    textDecoration: "underline",
  },
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#fff",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0, 0, 0, 0.14)",
    borderRadius: "4px",
    cursor: "pointer",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.28)",
  },
});

export default CookiesManage;
