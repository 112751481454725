import { cnHelper } from "./index";
// 每个路由指定className scope
export const CN_PRODUCT = cnHelper("product");

export const MESSAGE = {
  SERVER_ERROR: "Network Congestion.Please try again.",
  UNKNOWN_ERROR: `Oops! Something went wrong. We're working on resolving the issue. Please check back later.`,
};

export const currency = "£";

export const VISITOR_UUID = "VISITOR_UUID";
export const CURRENCY = "_currency";
export const LANG = "_lang";
export const COUNTRY = "_country";
export const TIMEZONE = "_timezone";
