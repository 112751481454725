export default {
  // 页面名称
  pageName: {
    me: "Me",
    myOrders: "My orders",
    messages: "Messages",
    coupons: "Coupons",
    myReviews: "My reviews",
    wishList: "Wishlist",
    addresses: "Addresses",
    browsingHistory: "Browsing history",
  },
  // 常用消息提示文案
  message: {
    deleteSuccessful: "Delete successful",
    networkError: "Oops! Network error, please try again.",
    systemError: "Oops! An error occurred, please try again.",
    noItems: "No items yet.",
  },
  // 常用按钮文案
  btnText: {
    done: "Done",
    delete: "Delete",
    edit: "Edit",
    addToCart: "Add to cart",
    tryAgain: "Try again",
  },
  // 商品状态
  productStatus: {
    outOfStock: "Out of stock",
    removed: "Removed",
  },
  // 商品营销文案
  marketingText: {
    flashSale: "Flash Sale",
    endsInTime: "Ends in {{time}}",
    newShopper: "New shoppers exclusive",
    itemOnly: "for 1 item only",
  },
  pageStatus: {
    error: "Loading up, please try again",
    loading: "Loading",
    nothing: "There's nothing more",
  },
};
