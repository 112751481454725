//处理传入的宽高
export const formatStyle = (style: string | number): string => {
  if (!style) {
    return ''
  }
  if (typeof style === 'number') {
    return `${style}px`;
  }

  if (typeof style === 'string') {
    // 如果已经是有效的 CSS 宽度格式，直接返回
    if (style.endsWith('px') || style.endsWith('%') || style === 'auto') {
      return style;
    }

    // 尝试将字符串转换为数字
    const numstyle = parseFloat(style);
    if (!isNaN(numstyle)) {
      return `${numstyle}px`;
    }
  }

  // 如果无法处理，返回默认值或抛出错误
  throw new Error('Invalid width format');
}

//处理标签样式
export const imgTipsStyle = (position: string) => {
  let style = {}
  switch (position) {
    case 'topLeft': style = { top: 0, left: 0 }
      break;
    case 'topRight': style = { top: 0, right: 0 }
      break;
    case 'bottomLeft': style = { bottom: 0, left: 0 }
      break;
    case 'bottomRight': style = { bottom: 0, right: 0 }
      break;
  }
  return style
};

//判断如果划线价小于等于优惠价格则不展示
export const comparePrice = (originalPrice: string, realPrice: string): boolean => {
  return Number(realPrice) < Number(originalPrice);
};

//商卡曝光参数处理
export const getExptag = (type: string, params: any, ExpType: string) => {
  if (params && params[ExpType]) {
    const { event_id, config, json_param, event_param } = params[ExpType]
    switch (type) {
      case 'exptag':
        return `exp|${event_id}`;
      case 'config':
        return JSON.stringify(config);
      case 'json-param':
        return JSON.stringify(json_param);
      case 'event-param':
        return JSON.stringify(event_param);
      default:
        return ''
    }
  }else{
    return ''
  }
}

//获取商品促销标签的文案；promotionType的含义：优惠券: 2  ,单品促销: 1, 总价促销: 10  ,时效标签: 3
export const getPromotionObj = (promotions: any) => {
  let promotionObj = {
    couponInfo: '-100',
    single_promotion: '-100',
    amount_promotion: '-100',
    time_label: '-100',
  }
  let single_promotion:string[] = [];
  let amount_promotion:string[] = [];
  let couponInfo:string[] = [];
  let time_label:string[] = [];
  if (promotions) {
    promotions.map((promotion: any) => {
      promotion = expandShowStyle(promotion);
      switch (promotion.promotionType) {
        case 1:
          single_promotion.push(`${promotion.text}${promotion?.endTime || ''}`)
          break;
        case 10:
          amount_promotion.push(`${promotion.text}${promotion?.endTime || ''}`)
          break;
        case 2:
          couponInfo.push(`${promotion.text}${promotion?.endTime || ''}`)
          break;
        case 3:
          time_label.push(`${promotion.text}${promotion?.endTime || ''}`)
          break;
      }
    });
    
    promotionObj.couponInfo = couponInfo.length > 0 ? couponInfo.join(';') : '-100'
    promotionObj.single_promotion = single_promotion.length > 0 ? single_promotion.join(';') : '-100'
    promotionObj.amount_promotion = amount_promotion.length > 0 ? amount_promotion.join(';') : '-100'
    promotionObj.time_label = time_label.length > 0 ? time_label.join(';') : '-100'
    return promotionObj
  }
  return promotionObj
}

const expandShowStyle = (obj: any) => {
  const showStyleObj = obj?.showStyle ? JSON.parse(obj?.showStyle) : {}
  return { ...obj, ...showStyleObj };
}
