// 埋点页面链接：https://stream.jd.com/ddrx/#/requirement/25422
// WARNING!!! 请勿手动修改此文件，如需修改请在子午线平台变更后，执行npm run track-init 25422 
import { eventTrackingUtils } from '@/utils/eventTrackingUtils'
export const PageId = Object.freeze({
  NeworderReceiverList: 'NeworderReceiverList'
})

interface IPVTrackParamsRecord {
  /** 地址组件-地址列表页 */
NeworderReceiverList: undefined
}

const sendPv = {
  /** PV-地址组件-地址列表页 */
NeworderReceiverList: () => {
    eventTrackingUtils.submitPV({page_id:PageId.NeworderReceiverList});
  },
}

interface IExpoTrackParamsRecord {
  /** 地址列表页自动上报埋点 */
NeworderReceiverList_AddressQuantityAuto: {
/** 地址总数  */
addqty: string,
/** 来源页面  */
from_page: string}
  /** 地址卡片曝光 */
NeworderReceiverList_Address_Card_Expo: {
/** 位置  */
index: number,
/** 地址id  */
addrid: string,
/** 是否设置默认地址  */
isdefaultaddr: string,
/** 来源页面  */
from_page: string}
  /** 编辑按钮曝光 */
NeworderReceiverList_Edit_Expo: {
/** 位置  */
index: number,
/** 地址id  */
addrid: string,
/** 是否设置默认地址  */
isdefaultaddr: string,
/** 来源页面  */
from_page: string}
  /** 默认地址设置曝光 */
NeworderReceiverList_Default_Expo: {
/** 位置  */
index: number,
/** 地址id  */
addrid: string,
/** 是否设置默认地址  */
isdefaultaddr: string,
/** 来源页面  */
from_page: string}
  /** 复制按钮曝光 */
NeworderReceiverList_Copy_Expo: {
/** 位置  */
index: number,
/** 地址id  */
addrid: string,
/** 是否设置默认地址  */
isdefaultaddr: string,
/** 来源页面  */
from_page: string}
  /** 删除按钮曝光 */
NeworderReceiverList_Delete_Expo: {
/** 来源页面  */
from_page: string}
  /** 新增地址按钮曝光 */
NeworderReceiverList_Add_Expo: {
/** 来源页面  */
from_page: string}
}

const EXPOSURE_RECORD: Readonly<Record<keyof IExpoTrackParamsRecord,string>> = Object.freeze({
/** 地址列表页自动上报埋点 */
  NeworderReceiverList_AddressQuantityAuto:'NeworderReceiverList_AddressQuantityAuto',
/** 地址卡片曝光 */
  NeworderReceiverList_Address_Card_Expo:'NeworderReceiverList_Address_Card_Expo',
/** 编辑按钮曝光 */
  NeworderReceiverList_Edit_Expo:'NeworderReceiverList_Edit_Expo',
/** 默认地址设置曝光 */
  NeworderReceiverList_Default_Expo:'NeworderReceiverList_Default_Expo',
/** 复制按钮曝光 */
  NeworderReceiverList_Copy_Expo:'NeworderReceiverList_Copy_Expo',
/** 删除按钮曝光 */
  NeworderReceiverList_Delete_Expo:'NeworderReceiverList_Delete_Expo',
/** 新增地址按钮曝光 */
  NeworderReceiverList_Add_Expo:'NeworderReceiverList_Add_Expo',
})

interface IClickTrackParamsRecord {
  /** 地址卡片点击 */
NeworderReceiverList_SelectAddress: {
/** 位置  */
index: number,
/** 地址id  */
addrid: string,
/** 是否设置默认地址  */
isdefaultaddr: string}
  /** 编辑按钮 */
NeworderReceiverList_Edit: {
/** 位置  */
index: number,
/** 地址id  */
addrid: string,
/** 是否设置默认地址  */
isdefaultaddr: string}
  /** 默认地址设置 */
NeworderReceiverList_Default: {
/** 位置  */
index: number,
/** 地址id  */
addrid: string,
/** 选中状态  */
chstatus: string}
  /** 复制按钮 */
NeworderReceiverList_Copy: {
/** 位置  */
index: number,
/** 地址id  */
addrid: string,
/** 是否设置默认地址  */
isdefaultaddr: string}
  /** 删除按钮 */
NeworderReceiverList_Delete: {
/** 来源页面  */
from_page: string}
  /** 新增地址按钮 */
NeworderReceiverList_Add: {
/** 来源页面  */
from_page: string}
}

const sendClickTrack = {
  /** 点击事件上报-地址卡片点击 */
NeworderReceiverList_SelectAddress: (json_param: IClickTrackParamsRecord['NeworderReceiverList_SelectAddress']) => {
    eventTrackingUtils.submitCL({event_id:'NeworderReceiverList_SelectAddress',json_param});
  },
  /** 点击事件上报-编辑按钮 */
NeworderReceiverList_Edit: (json_param: IClickTrackParamsRecord['NeworderReceiverList_Edit']) => {
    eventTrackingUtils.submitCL({event_id:'NeworderReceiverList_Edit',json_param});
  },
  /** 点击事件上报-默认地址设置 */
NeworderReceiverList_Default: (json_param: IClickTrackParamsRecord['NeworderReceiverList_Default']) => {
    eventTrackingUtils.submitCL({event_id:'NeworderReceiverList_Default',json_param});
  },
  /** 点击事件上报-复制按钮 */
NeworderReceiverList_Copy: (json_param: IClickTrackParamsRecord['NeworderReceiverList_Copy']) => {
    eventTrackingUtils.submitCL({event_id:'NeworderReceiverList_Copy',json_param});
  },
  /** 点击事件上报-删除按钮 */
NeworderReceiverList_Delete: (json_param: IClickTrackParamsRecord['NeworderReceiverList_Delete']) => {
    eventTrackingUtils.submitCL({event_id:'NeworderReceiverList_Delete',json_param});
  },
  /** 点击事件上报-新增地址按钮 */
NeworderReceiverList_Add: (json_param: IClickTrackParamsRecord['NeworderReceiverList_Add']) => {
    eventTrackingUtils.submitCL({event_id:'NeworderReceiverList_Add',json_param});
  },
}

export {
  EXPOSURE_RECORD,
  sendClickTrack,
  sendPv,
}
export type {
  IExpoTrackParamsRecord,
}