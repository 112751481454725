export default {
  AboutBasicList: [
    {
      id: 70,
      title: "About us",
      url: "http://www.joybuy.com/discover/about-us/70",
    },
    {
      id: 69,
      title: "Contact us",
      url: "http://www.joybuy.com/discover/contact-us/69",
    },
  ],
  LegalBasicList: [
    {
      id: 77,
      title: "Terms & Condition",
      url: "http://www.joybuy.com/help/terms-%26-condition/77",
    },
    {
      id: 74,
      title: "Privacy policy",
      url: "http://www.joybuy.com/help/privacy-policy/77",
    },
  ],
  ContactBasicList: [
    {
      id: 73,
      title: "Phone number: +443300438188",
    },
    {
      id: 72,
      title: "Email address: custom-service@joybuy.com",
    },
  ],
  Frequent_Ask_Questions: "https://joybuy.zendesk.com/hc/en-us",
  Instagram: "https://www.instagram.com/joybuy.official",
  Tiktok: "https://www.tiktok.com/@joybuy.official",
  Facebook: "https://www.facebook.com/Joybuy.official",
  Youtube: "https://www.youtube.com/@Joybuy.Official",
  Linktree: "https://linktr.ee/joybuy.official",
  googleDownload: "https://play.google.com/store/apps/details?id=com.joybuy.jdi",
  AppDownload: "https://apps.apple.com/app/joybuy/id6740224837",
};
