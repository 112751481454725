import cn from "clsx";
import IntlPopover from "./popover";
import styles from "./reviews.module.scss";
import styles2 from "./reviewDetail.module.scss";
import LoadingIcon from "@/common-components-src/components/loadingIcon/index";

import { DialogTitle, Typography } from "@mui/material";
import { MouseEvent, useEffect, useState } from "react";
import { anonymousData } from "@/common-components-src/api/reviews/review.interface";
// import { CUSTOM_DETAIL_OPTS_EVENT_ANONYMOUS, customElement } from "./const";
import { handleDeleteComment, handleAnonymous } from "@/common-components-src/api/reviews/review.api";
import { Button, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import UKToast from "@/common-components-src/components/UKToast/index";
import UKDialog from "@/common-components-src/components/UKDialog/index";
import EVENT_TYPE from "@/common-components-src/const/eventType"
import eventEmitter from "@/common-components-src/js/utils/eventEmitterUtil"
import MoreIcon  from "@/common-components-src/assets/icons/review-more.svg";
import { sendClickTrack as reviewDetailSendClickTrack } from "@/tracks/25347";

const typographyStyle = {
  fontSize: "14px",
  lineHeight: "44px",
  padding: "2px 16px",
  cursor: "pointer",
  minWidth: "140px",
  whiteSpce: "nowrap",
  "&:hover": { color: "#990915" },
};

interface Props {
  anonymous: boolean;
  params?: any;
  pageType?: string;
  expConfig?: any;
  onRefresh?: () => void;
  onDeleteCallback?: () => void;
  onAnonymous?: (is: boolean, data: anonymousData) => void;
}
export default function MenuOpts({
  anonymous,
  params,
  pageType,
  expConfig,
  onRefresh,
  onAnonymous,
  onDeleteCallback,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [isAnonymous, setIsAnonymouse] = useState(anonymous);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { skuId, commentId } = params || {};
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
    switch (pageType) {
      case "reviewDetail":
        reviewDetailSendClickTrack.Evaluate_CommentDetail_Card_Btn({button_name: "3", commentId: `${commentId}` || "-100", skuid: `${skuId}` || "-100"})
        break;
    
      default:
        break;
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const onOptAnonymous = async () => {
    const res = await handleAnonymous({ skuId, commentId, anonymous: isAnonymous ? 0 : 1 });
    if (res?.data?.setResult) {
      UKToast.show({
        icon: "success",
        content: `Switched successfully`,
      });
      const { nickName, userImage } = res?.data || {};
      eventEmitter.emit(EVENT_TYPE.CUSTOM_DETAIL_OPTS_EVENT_ANONYMOUS, {
        detail: {
          data: {
            [commentId]: {
              isAnonymous: !isAnonymous,
              nickName,
              userImage,
            },
          },
        },
      });
      // customElement.dispatchEvent(
      //   new CustomEvent(CUSTOM_DETAIL_OPTS_EVENT_ANONYMOUS, {
      //     detail: {
      //       data: {
      //         [commentId]: {
      //           isAnonymous: !isAnonymous,
      //           nickName,
      //           userImage,
      //         },
      //       },
      //     },
      //   }),
      // );
      handlePopoverClose?.();
      setIsAnonymouse(!isAnonymous);
      onAnonymous?.(!isAnonymous, { nickName, userImage, commentId });
      setDialogOpen(false);
    } else {
      UKToast.show({
        icon: "error",
        content: `Oops! Something went wrong. Please try again.`,
      });
    }
  };
  const onConfirmDelete = async () => {
    setIsLoading(true);
    const { skuId, commentId } = params;
    const res: any = await handleDeleteComment({
      skuId,
      commentId,
    });
    if (res && res.success) {
      if (res?.data?.deleteResult) {
        setDialogOpen(false);
        onDeleteCallback?.();
      } else {
        UKToast.show({
          icon: "error",
          content: `Failed to delete, please try again.`,
        });
      }
      handlePopoverClose();
      onRefresh?.();
      setIsLoading(false);
    } else {
      setIsLoading(false);
      UKToast.show({
        icon: "error",
        content: res.msg || `Failed to delete, please try again.`,
      });
    }
  };
  const onOpenDeleteDialog = () => {
    setDialogOpen(true);
    handlePopoverClose();
  };
  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? "simple-popover" : "";
  const handleAnonymousEvent = (e: any) => {
    const { detail } = e;
    if (detail?.data?.[commentId]) {
      const { isAnonymous: isNewAnonymous, nickName, userImage } = detail?.data?.[commentId] || {};
      setIsAnonymouse(isNewAnonymous);
      onAnonymous?.(isNewAnonymous, { commentId, nickName, userImage });
    }
  };
  // const handleDeleteEvent = (e: Event) => {
  //   const { detail } = e as CustomEvent;
  //   if (detail?.data?.[commentId]) {
  //     onDeleteCallback?.();
  //   }
  // };
  useEffect(() => {
    eventEmitter.on(EVENT_TYPE.CUSTOM_DETAIL_OPTS_EVENT_ANONYMOUS, handleAnonymousEvent);
    return () => {
        eventEmitter.off(EVENT_TYPE.CUSTOM_DETAIL_OPTS_EVENT_ANONYMOUS, handleAnonymousEvent);
    };
  }, []);
  useEffect(() => {
    setIsAnonymouse(anonymous);
  }, [anonymous]);
  return (
    <>
      <div className={cn(styles.btn, styles2.btn)} aria-describedby={id} onClick={handleClick}>
        <MoreIcon className={cn(styles.moreIcon, styles.mar, styles2.moreIcon, styles2.mar)}></MoreIcon>
      </div>
      <IntlPopover id={id} open={popoverOpen} anchorEl={anchorEl} onClose={handlePopoverClose}>
        <Typography sx={typographyStyle} className="report-block" 
          exptag={expConfig?.delete?.expTag}
          data-exptag-json-param={JSON.stringify(expConfig?.delete?.data)}
          data-exptag-config={expConfig?.delete?.config}
          onClick={()=> {
            switch (pageType) {
              case "reviewDetail":
                reviewDetailSendClickTrack.Evaluate_CommentDetail_More_Layer({title: "2", commentId: `${commentId}` || "-100", skuid: `${skuId}` || "-100"})
                break;
            
              default:
                break;
            }
            onOpenDeleteDialog();
          }}
        >
          Delete
        </Typography>
      {/* 
        <Typography sx={typographyStyle} className="report-block" onClick={onOptAnonymous}>
          {isAnonymous ? "Set non-anonymous" : "Set as anonymous"}
        </Typography> */}
      </IntlPopover>

      <UKDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        showCloseBtn={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{zIndex: 2300}}
      >
        <DialogTitle/>
        <DialogContent style={{paddingTop:"20px"}}>
          <DialogContentText id="alert-dialog-description">
            Please confirm you want to delete your review?This action cannot be undone!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} variant="outlined">
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            startIcon={isLoading ? <LoadingIcon /> : null}
            onClick={onConfirmDelete}
            autoFocus
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </UKDialog>
    </>
  );
}
