// 埋点页面链接：https://stream.jd.com/ddrx/#/requirement/25281
// WARNING!!! 请勿手动修改此文件，如需修改请在子午线平台变更后，执行npm run track-init 25281 
import { eventTrackingUtils } from '@/utils/eventTrackingUtils'
export const PageId = Object.freeze({
  SettleAccounts_OrderNew: 'SettleAccounts_OrderNew'
})

interface IPVTrackParamsRecord {
  /** 填写订单页 */
  SettleAccounts_OrderNew: {
    /** 页面来源id  */
    source: string
  }
}

const sendPv = {
  /** PV-填写订单页 */
  SettleAccounts_OrderNew: (page_param: IPVTrackParamsRecord['SettleAccounts_OrderNew']) => {
    eventTrackingUtils.submitPV({ page_id: PageId.SettleAccounts_OrderNew, page_param });
  },
}

interface IExpoTrackParamsRecord {
  /** 顶部地址楼层曝光 */
  SettleAccounts_OrderNew_AddressExpo: undefined
  /** 结算商品楼层曝光 */
  SettleAccounts_OrderNew_ItemsExpo: {
    /** 楼层类型  */
    floorType: string,
    /** 是否有查看更多入口 0-否,1-是 */
    is_more: | '0' | '1', items: Array<{
      /** 商品id  */
      skuid: string,
      /** 商品数量  */
      pdCnt: number,
      /** 第一价格金额  */
      firprice: number,
      /** 第二价格金额  */
      secprice: number,
      /** 第一价格分类 1-新人价,2-重逢价,3-粉丝价,4-闪购价,5-学生价,6-企业价,7-拼购价,8-家庭价,9-plus价,10-合约价,11-到手价,12-店铺会员价-L1会员,13-店铺会员价-L2会员,14-店铺会员价-L3会员,15-店铺会员价-L4会员,16-店铺会员价-L4会员,17-1号店会员价,18-电子书价,19-sam价,20-门店价,21-进口新客价,22-企业新人价,23-专属价,24-首单新人价,26-秒杀价,27-秒杀专享价,28-城市专享价,29-临期特卖,30-商场同款,31-商品定制价,32-亲子价,33-超市新人价,34-预售价,35-EDLP,36-砍后价,37-拼团价,38-陪伴价,39-xx人拼购价（使用7）,40-x件单价约xx,41-xx件单价,42-天天低价,43-低于30天均价,44-京东价,45-企业会员价,46-补贴价,47-套装价,48-区间价,49-百补补贴价,50-百补日常价,51-租赁价,52-划线价,53-拍卖当前价,54-起拍价,73-定期购预估总价,74-订金,75-区域专享价,76-星巴克会员价,77-医药商品单盒价,78-内购价,79-小时达新人到手价,-100-兜底值,0-小时购主要价格,81-直播间价格,82-神价,90-开普勒价,83-原始价,91-试用价,92-百补多人团价,93-秒送兑换券价（0元）,94-预售券后价,85-店铺会员到手价,86-plus会员价,87-渠道价,95-单促价,96-儿童价,97-婴儿价,98-火车票价格,99-直播专享价,100-直播专享价到手价,101-直播专享券到手价,102-预估新人价,103-未登录到手价,104-国补到手价,105-未领国补到手价,106-家政0元先享价 */
      firpricetype: | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21' | '22' | '23' | '24' | '26' | '27' | '28' | '29' | '30' | '31' | '32' | '33' | '34' | '35' | '36' | '37' | '38' | '39' | '40' | '41' | '42' | '43' | '44' | '45' | '46' | '47' | '48' | '49' | '50' | '51' | '52' | '53' | '54' | '73' | '74' | '75' | '76' | '77' | '78' | '79' | '-100' | '0' | '81' | '82' | '90' | '83' | '91' | '92' | '93' | '94' | '85' | '86' | '87' | '95' | '96' | '97' | '98' | '99' | '100' | '101' | '102' | '103' | '104' | '105' | '106',
      /** 第二价格类型 1-新人价,2-重逢价,3-粉丝价,4-闪购价,5-学生价,6-企业价,7-拼购价,8-家庭价,9-plus价,10-合约价,11-到手价,12-店铺会员价-L1会员,13-店铺会员价-L2会员,14-店铺会员价-L3会员,15-店铺会员价-L4会员,16-店铺会员价-L5会员,17-1号店会员价,18-电子书价,19-sam价,20-门店价,21-进口新客价,22-企业新人价,23-专属价,24-首单新人价,26-秒杀价,27-秒杀专享价,28-城市专享价,29-临期特卖,30-商场同款,31-商品定制价,32-亲子价,33-超市新人价,34-预售价,35-EDLP,36-砍后价,37-拼团价,38-陪伴价,40-X件单价约xx,41-xx件单价,42-天天低价,43-低于30天均价,44-京东价,45-企业会员价,46-补贴价,47-套装价,48-区间价,49-百补补贴价,50-百补日常价,51-租赁价,52-划线价,53-拍卖当前价,54-起拍价,73-定期购预估总价,74-订金（多阶段支付）,75-区域专享价,76-星巴克会员价,77-医药商品单盒价,78-内购价,79-小时达新人到手价,80-单规格价格,81-直播间价格,82-神价,83-原始价,90-开普勒价,91-试用价,84-第一件XX元/前n件XX元,92-百补多人团价,93-秒送兑换券价（0元）,94-预售券后价,95-单促价,96-儿童价,97-婴儿价,98-火车票价格,99-直播专享价,100-直播专享价到手价,101-直播专享券到手价,102-预估新人价,103-未登录到手价,104-国补到手价,105-国补拉新价,106-家政0元先享价 */
      secpricetype: | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21' | '22' | '23' | '24' | '26' | '27' | '28' | '29' | '30' | '31' | '32' | '33' | '34' | '35' | '36' | '37' | '38' | '40' | '41' | '42' | '43' | '44' | '45' | '46' | '47' | '48' | '49' | '50' | '51' | '52' | '53' | '54' | '73' | '74' | '75' | '76' | '77' | '78' | '79' | '80' | '81' | '82' | '83' | '90' | '91' | '84' | '92' | '93' | '94' | '95' | '96' | '97' | '98' | '99' | '100' | '101' | '102' | '103' | '104' | '105' | '106',
      /** 分堆  */
      order_items: string,
      /** 最快送达时间  */
      fastDeliveryTime: string
    }>
  }
  /** 支付方式楼层曝光 */
  SettleAccounts_OrderNew_PayWayExpo: undefined
  /** 优惠券楼层曝光 */
  SettleAccounts_OrderNew_CouponExpo: {
    /** 结算_入口文案  */
    text: string,
    /** 是否有券  */
    isCoupon: string,
    /** 优惠券id  */
    couponId: string,
    /** 优惠券批次号  */
    batchId: string,
    /** 优惠券使用门槛  */
    use_threshold: string,
    /** 券类型 1-普通券,2-新人券 */
    couponType: | '1' | '2',
    /** 优惠券金额  */
    couponSave: string
  }
  /** 明细楼层曝光 */
  SettleAccounts_OrderNew_DetailExpo: {
    /** 订单总价  */
    itemTotal: string,
    /** 总优惠  */
    totalDiscount: string,
    /** 小计  */
    subTotal: string,
    /** 运费  */
    freightfee: number,
    /** 促销金额  */
    promotions: string,
    /** 节省金额  */
    save: string,
    /** 折扣金额  */
    discount: string
  }
  /** 发票地址楼层曝光 */
  SettleAccounts_OrderNew_BillAddressExpo: undefined
  /** 无货提示弹窗曝光 */
  SettleAccounts_OrderNew_UnstockExpo: {
    /** 商品id  */
    skuid: Array<string>
  }
  /** 0元单提示条曝光 */
  SettleAccounts_OrderNew_ZeroTipExpo: undefined
}

const EXPOSURE_RECORD: Readonly<Record<keyof IExpoTrackParamsRecord, string>> = Object.freeze({
  /** 顶部地址楼层曝光 */
  SettleAccounts_OrderNew_AddressExpo: 'SettleAccounts_OrderNew_AddressExpo',
  /** 结算商品楼层曝光 */
  SettleAccounts_OrderNew_ItemsExpo: 'SettleAccounts_OrderNew_ItemsExpo',
  /** 支付方式楼层曝光 */
  SettleAccounts_OrderNew_PayWayExpo: 'SettleAccounts_OrderNew_PayWayExpo',
  /** 优惠券楼层曝光 */
  SettleAccounts_OrderNew_CouponExpo: 'SettleAccounts_OrderNew_CouponExpo',
  /** 明细楼层曝光 */
  SettleAccounts_OrderNew_DetailExpo: 'SettleAccounts_OrderNew_DetailExpo',
  /** 发票地址楼层曝光 */
  SettleAccounts_OrderNew_BillAddressExpo: 'SettleAccounts_OrderNew_BillAddressExpo',
  /** 无货提示弹窗曝光 */
  SettleAccounts_OrderNew_UnstockExpo: 'SettleAccounts_OrderNew_UnstockExpo',
  /** 0元单提示条曝光 */
  SettleAccounts_OrderNew_ZeroTipExpo: 'SettleAccounts_OrderNew_ZeroTipExpo',
})

interface IClickTrackParamsRecord {
  /** 顶部地址楼层点击 */
  SettleAccounts_OrderNew_Address: undefined
  /** 结算商品楼层点击 */
  SettleAccounts_OrderNew_Items: {
    /** 楼层类型  */
    floorType: string,
    /** 是否有查看更多入口 0-否,1-是 */
    is_more: | '0' | '1',
    /** 点击位置  */
    clickPos: string, items: Array<{
      /** 商品id  */
      skuid: string,
      /** 商品数量  */
      pdCnt: number,
      /** 第一价格金额  */
      firprice: number,
      /** 第二价格金额  */
      secprice: number,
      /** 第一价格分类 1-新人价,2-重逢价,3-粉丝价,4-闪购价,5-学生价,6-企业价,7-拼购价,8-家庭价,9-plus价,10-合约价,11-到手价,12-店铺会员价-L1会员,13-店铺会员价-L2会员,14-店铺会员价-L3会员,15-店铺会员价-L4会员,16-店铺会员价-L4会员,17-1号店会员价,18-电子书价,19-sam价,20-门店价,21-进口新客价,22-企业新人价,23-专属价,24-首单新人价,26-秒杀价,27-秒杀专享价,28-城市专享价,29-临期特卖,30-商场同款,31-商品定制价,32-亲子价,33-超市新人价,34-预售价,35-EDLP,36-砍后价,37-拼团价,38-陪伴价,39-xx人拼购价（使用7）,40-x件单价约xx,41-xx件单价,42-天天低价,43-低于30天均价,44-京东价,45-企业会员价,46-补贴价,47-套装价,48-区间价,49-百补补贴价,50-百补日常价,51-租赁价,52-划线价,53-拍卖当前价,54-起拍价,73-定期购预估总价,74-订金,75-区域专享价,76-星巴克会员价,77-医药商品单盒价,78-内购价,79-小时达新人到手价,-100-兜底值,0-小时购主要价格,81-直播间价格,82-神价,90-开普勒价,83-原始价,91-试用价,92-百补多人团价,93-秒送兑换券价（0元）,94-预售券后价,85-店铺会员到手价,86-plus会员价,87-渠道价,95-单促价,96-儿童价,97-婴儿价,98-火车票价格,99-直播专享价,100-直播专享价到手价,101-直播专享券到手价,102-预估新人价,103-未登录到手价,104-国补到手价,105-未领国补到手价,106-家政0元先享价 */
      firpricetype: | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21' | '22' | '23' | '24' | '26' | '27' | '28' | '29' | '30' | '31' | '32' | '33' | '34' | '35' | '36' | '37' | '38' | '39' | '40' | '41' | '42' | '43' | '44' | '45' | '46' | '47' | '48' | '49' | '50' | '51' | '52' | '53' | '54' | '73' | '74' | '75' | '76' | '77' | '78' | '79' | '-100' | '0' | '81' | '82' | '90' | '83' | '91' | '92' | '93' | '94' | '85' | '86' | '87' | '95' | '96' | '97' | '98' | '99' | '100' | '101' | '102' | '103' | '104' | '105' | '106',
      /** 第二价格类型 1-新人价,2-重逢价,3-粉丝价,4-闪购价,5-学生价,6-企业价,7-拼购价,8-家庭价,9-plus价,10-合约价,11-到手价,12-店铺会员价-L1会员,13-店铺会员价-L2会员,14-店铺会员价-L3会员,15-店铺会员价-L4会员,16-店铺会员价-L5会员,17-1号店会员价,18-电子书价,19-sam价,20-门店价,21-进口新客价,22-企业新人价,23-专属价,24-首单新人价,26-秒杀价,27-秒杀专享价,28-城市专享价,29-临期特卖,30-商场同款,31-商品定制价,32-亲子价,33-超市新人价,34-预售价,35-EDLP,36-砍后价,37-拼团价,38-陪伴价,40-X件单价约xx,41-xx件单价,42-天天低价,43-低于30天均价,44-京东价,45-企业会员价,46-补贴价,47-套装价,48-区间价,49-百补补贴价,50-百补日常价,51-租赁价,52-划线价,53-拍卖当前价,54-起拍价,73-定期购预估总价,74-订金（多阶段支付）,75-区域专享价,76-星巴克会员价,77-医药商品单盒价,78-内购价,79-小时达新人到手价,80-单规格价格,81-直播间价格,82-神价,83-原始价,90-开普勒价,91-试用价,84-第一件XX元/前n件XX元,92-百补多人团价,93-秒送兑换券价（0元）,94-预售券后价,95-单促价,96-儿童价,97-婴儿价,98-火车票价格,99-直播专享价,100-直播专享价到手价,101-直播专享券到手价,102-预估新人价,103-未登录到手价,104-国补到手价,105-国补拉新价,106-家政0元先享价 */
      secpricetype: | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21' | '22' | '23' | '24' | '26' | '27' | '28' | '29' | '30' | '31' | '32' | '33' | '34' | '35' | '36' | '37' | '38' | '40' | '41' | '42' | '43' | '44' | '45' | '46' | '47' | '48' | '49' | '50' | '51' | '52' | '53' | '54' | '73' | '74' | '75' | '76' | '77' | '78' | '79' | '80' | '81' | '82' | '83' | '90' | '91' | '84' | '92' | '93' | '94' | '95' | '96' | '97' | '98' | '99' | '100' | '101' | '102' | '103' | '104' | '105' | '106',
      /** 分堆  */
      order_items: string,
      /** 最快送达时间  */
      fastDeliveryTime: string
    }>
  }
  /** 结算商品楼层数量增加点击 */
  SettleAccounts_OrderNew_AddItems: {
    /** 楼层类型  */
    floorType: string, items: Array<{
      /** 商品id  */
      skuid: string,
      /** 商品数量  */
      pdCnt: number,
      /** 第一价格金额  */
      firprice: number,
      /** 第二价格金额  */
      secprice: number,
      /** 第一价格分类 1-新人价,2-重逢价,3-粉丝价,4-闪购价,5-学生价,6-企业价,7-拼购价,8-家庭价,9-plus价,10-合约价,11-到手价,12-店铺会员价-L1会员,13-店铺会员价-L2会员,14-店铺会员价-L3会员,15-店铺会员价-L4会员,16-店铺会员价-L4会员,17-1号店会员价,18-电子书价,19-sam价,20-门店价,21-进口新客价,22-企业新人价,23-专属价,24-首单新人价,26-秒杀价,27-秒杀专享价,28-城市专享价,29-临期特卖,30-商场同款,31-商品定制价,32-亲子价,33-超市新人价,34-预售价,35-EDLP,36-砍后价,37-拼团价,38-陪伴价,39-xx人拼购价（使用7）,40-x件单价约xx,41-xx件单价,42-天天低价,43-低于30天均价,44-京东价,45-企业会员价,46-补贴价,47-套装价,48-区间价,49-百补补贴价,50-百补日常价,51-租赁价,52-划线价,53-拍卖当前价,54-起拍价,73-定期购预估总价,74-订金,75-区域专享价,76-星巴克会员价,77-医药商品单盒价,78-内购价,79-小时达新人到手价,-100-兜底值,0-小时购主要价格,81-直播间价格,82-神价,90-开普勒价,83-原始价,91-试用价,92-百补多人团价,93-秒送兑换券价（0元）,94-预售券后价,85-店铺会员到手价,86-plus会员价,87-渠道价,95-单促价,96-儿童价,97-婴儿价,98-火车票价格,99-直播专享价,100-直播专享价到手价,101-直播专享券到手价,102-预估新人价,103-未登录到手价,104-国补到手价,105-未领国补到手价,106-家政0元先享价 */
      firpricetype: | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21' | '22' | '23' | '24' | '26' | '27' | '28' | '29' | '30' | '31' | '32' | '33' | '34' | '35' | '36' | '37' | '38' | '39' | '40' | '41' | '42' | '43' | '44' | '45' | '46' | '47' | '48' | '49' | '50' | '51' | '52' | '53' | '54' | '73' | '74' | '75' | '76' | '77' | '78' | '79' | '-100' | '0' | '81' | '82' | '90' | '83' | '91' | '92' | '93' | '94' | '85' | '86' | '87' | '95' | '96' | '97' | '98' | '99' | '100' | '101' | '102' | '103' | '104' | '105' | '106',
      /** 第二价格类型 1-新人价,2-重逢价,3-粉丝价,4-闪购价,5-学生价,6-企业价,7-拼购价,8-家庭价,9-plus价,10-合约价,11-到手价,12-店铺会员价-L1会员,13-店铺会员价-L2会员,14-店铺会员价-L3会员,15-店铺会员价-L4会员,16-店铺会员价-L5会员,17-1号店会员价,18-电子书价,19-sam价,20-门店价,21-进口新客价,22-企业新人价,23-专属价,24-首单新人价,26-秒杀价,27-秒杀专享价,28-城市专享价,29-临期特卖,30-商场同款,31-商品定制价,32-亲子价,33-超市新人价,34-预售价,35-EDLP,36-砍后价,37-拼团价,38-陪伴价,40-X件单价约xx,41-xx件单价,42-天天低价,43-低于30天均价,44-京东价,45-企业会员价,46-补贴价,47-套装价,48-区间价,49-百补补贴价,50-百补日常价,51-租赁价,52-划线价,53-拍卖当前价,54-起拍价,73-定期购预估总价,74-订金（多阶段支付）,75-区域专享价,76-星巴克会员价,77-医药商品单盒价,78-内购价,79-小时达新人到手价,80-单规格价格,81-直播间价格,82-神价,83-原始价,90-开普勒价,91-试用价,84-第一件XX元/前n件XX元,92-百补多人团价,93-秒送兑换券价（0元）,94-预售券后价,95-单促价,96-儿童价,97-婴儿价,98-火车票价格,99-直播专享价,100-直播专享价到手价,101-直播专享券到手价,102-预估新人价,103-未登录到手价,104-国补到手价,105-国补拉新价,106-家政0元先享价 */
      secpricetype: | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21' | '22' | '23' | '24' | '26' | '27' | '28' | '29' | '30' | '31' | '32' | '33' | '34' | '35' | '36' | '37' | '38' | '40' | '41' | '42' | '43' | '44' | '45' | '46' | '47' | '48' | '49' | '50' | '51' | '52' | '53' | '54' | '73' | '74' | '75' | '76' | '77' | '78' | '79' | '80' | '81' | '82' | '83' | '90' | '91' | '84' | '92' | '93' | '94' | '95' | '96' | '97' | '98' | '99' | '100' | '101' | '102' | '103' | '104' | '105' | '106',
      /** 分堆  */
      order_items: string,
      /** 最快送达时间  */
      fastDeliveryTime: string
    }>
  }
  /** 支付方式楼层点击 */
  SettleAccounts_OrderNew_PayWay: {
    /** 支付方式  */
    payWay: string,
    /** 渠道名称  */
    channelName: string
  }
  /** 优惠券楼层点击 */
  SettleAccounts_OrderNew_Coupon: {
    /** 结算_入口文案  */
    text: string,
    /** 是否有券  */
    isCoupon: string,
    /** 优惠券id  */
    couponId: string,
    /** 优惠券批次号  */
    batchId: string,
    /** 优惠券使用门槛  */
    use_threshold: string,
    /** 券类型 1-普通券,2-新人券 */
    couponType: | '1' | '2',
    /** 优惠券金额  */
    couponSave: string
  }
  /** 发票地址楼层点击 */
  SettleAccounts_OrderNew_BillAddress: undefined
  /** 支付按钮点击 */
  SettleAccounts_OrderNew_Submit: {
    /** 楼层类型  */
    floorType: string,
    /** 支付方式  */
    payWay: string, items: Array<{
      /** 商品id  */
      skuid: string,
      /** 商品数量  */
      pdCnt: number,
      /** 第一价格金额  */
      firprice: number,
      /** 第二价格金额  */
      secprice: number,
      /** 第一价格分类 1-新人价,2-重逢价,3-粉丝价,4-闪购价,5-学生价,6-企业价,7-拼购价,8-家庭价,9-plus价,10-合约价,11-到手价,12-店铺会员价-L1会员,13-店铺会员价-L2会员,14-店铺会员价-L3会员,15-店铺会员价-L4会员,16-店铺会员价-L4会员,17-1号店会员价,18-电子书价,19-sam价,20-门店价,21-进口新客价,22-企业新人价,23-专属价,24-首单新人价,26-秒杀价,27-秒杀专享价,28-城市专享价,29-临期特卖,30-商场同款,31-商品定制价,32-亲子价,33-超市新人价,34-预售价,35-EDLP,36-砍后价,37-拼团价,38-陪伴价,39-xx人拼购价（使用7）,40-x件单价约xx,41-xx件单价,42-天天低价,43-低于30天均价,44-京东价,45-企业会员价,46-补贴价,47-套装价,48-区间价,49-百补补贴价,50-百补日常价,51-租赁价,52-划线价,53-拍卖当前价,54-起拍价,73-定期购预估总价,74-订金,75-区域专享价,76-星巴克会员价,77-医药商品单盒价,78-内购价,79-小时达新人到手价,-100-兜底值,0-小时购主要价格,81-直播间价格,82-神价,90-开普勒价,83-原始价,91-试用价,92-百补多人团价,93-秒送兑换券价（0元）,94-预售券后价,85-店铺会员到手价,86-plus会员价,87-渠道价,95-单促价,96-儿童价,97-婴儿价,98-火车票价格,99-直播专享价,100-直播专享价到手价,101-直播专享券到手价,102-预估新人价,103-未登录到手价,104-国补到手价,105-未领国补到手价,106-家政0元先享价 */
      firpricetype: | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21' | '22' | '23' | '24' | '26' | '27' | '28' | '29' | '30' | '31' | '32' | '33' | '34' | '35' | '36' | '37' | '38' | '39' | '40' | '41' | '42' | '43' | '44' | '45' | '46' | '47' | '48' | '49' | '50' | '51' | '52' | '53' | '54' | '73' | '74' | '75' | '76' | '77' | '78' | '79' | '-100' | '0' | '81' | '82' | '90' | '83' | '91' | '92' | '93' | '94' | '85' | '86' | '87' | '95' | '96' | '97' | '98' | '99' | '100' | '101' | '102' | '103' | '104' | '105' | '106',
      /** 第二价格类型 1-新人价,2-重逢价,3-粉丝价,4-闪购价,5-学生价,6-企业价,7-拼购价,8-家庭价,9-plus价,10-合约价,11-到手价,12-店铺会员价-L1会员,13-店铺会员价-L2会员,14-店铺会员价-L3会员,15-店铺会员价-L4会员,16-店铺会员价-L5会员,17-1号店会员价,18-电子书价,19-sam价,20-门店价,21-进口新客价,22-企业新人价,23-专属价,24-首单新人价,26-秒杀价,27-秒杀专享价,28-城市专享价,29-临期特卖,30-商场同款,31-商品定制价,32-亲子价,33-超市新人价,34-预售价,35-EDLP,36-砍后价,37-拼团价,38-陪伴价,40-X件单价约xx,41-xx件单价,42-天天低价,43-低于30天均价,44-京东价,45-企业会员价,46-补贴价,47-套装价,48-区间价,49-百补补贴价,50-百补日常价,51-租赁价,52-划线价,53-拍卖当前价,54-起拍价,73-定期购预估总价,74-订金（多阶段支付）,75-区域专享价,76-星巴克会员价,77-医药商品单盒价,78-内购价,79-小时达新人到手价,80-单规格价格,81-直播间价格,82-神价,83-原始价,90-开普勒价,91-试用价,84-第一件XX元/前n件XX元,92-百补多人团价,93-秒送兑换券价（0元）,94-预售券后价,95-单促价,96-儿童价,97-婴儿价,98-火车票价格,99-直播专享价,100-直播专享价到手价,101-直播专享券到手价,102-预估新人价,103-未登录到手价,104-国补到手价,105-国补拉新价,106-家政0元先享价 */
      secpricetype: | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21' | '22' | '23' | '24' | '26' | '27' | '28' | '29' | '30' | '31' | '32' | '33' | '34' | '35' | '36' | '37' | '38' | '40' | '41' | '42' | '43' | '44' | '45' | '46' | '47' | '48' | '49' | '50' | '51' | '52' | '53' | '54' | '73' | '74' | '75' | '76' | '77' | '78' | '79' | '80' | '81' | '82' | '83' | '90' | '91' | '84' | '92' | '93' | '94' | '95' | '96' | '97' | '98' | '99' | '100' | '101' | '102' | '103' | '104' | '105' | '106',
      /** 分堆  */
      order_items: string,
      /** 最快送达时间  */
      fastDeliveryTime: string,
      /** 是否库存紧张 0-否,1-是 */
      is_shortstock: | '0' | '1'
    }>
  }
  /** 无货提示弹窗点击 */
  SettleAccounts_OrderNew_Unstock: undefined
  /** 优惠券楼层Tab点击 */
  SettleAccounts_OrderNew_CouponLayerTab: {
    /** tab名称  */
    tabName: string, coupon: Array<{
      /** 优惠券id  */
      couponId: string,
      /** 优惠券批次号  */
      batchId: string,
      /** 优惠券使用门槛  */
      use_threshold: string,
      /** 券类型 1-普通券,2-新人券 */
      couponType: | '1' | '2',
      /** 券场景  */
      couponTag: string,
      /** 优惠券金额  */
      couponSave: string
    }>
  }
  /** 优惠券楼层搜索按钮点击 */
  SettleAccounts_OrderNew_CouponLayerSearch: {
    /** 优惠券id  */
    couponId: string,
    /** 搜索结果  */
    searchResult: string,
    /** 失败码  */
    code: string,
    /** 优惠券批次号  */
    batchId: string,
    /** 优惠券使用门槛  */
    use_threshold: string,
    /** 券类型 1-普通券,2-新人券 */
    couponType: | '1' | '2',
    /** 优惠券金额  */
    couponSave: string
  }
}

const sendClickTrack = {
  /** 点击事件上报-顶部地址楼层点击 */
  SettleAccounts_OrderNew_Address: () => {
    eventTrackingUtils.submitCL({ event_id: 'SettleAccounts_OrderNew_Address' });
  },
  /** 点击事件上报-结算商品楼层点击 */
  SettleAccounts_OrderNew_Items: (json_param: IClickTrackParamsRecord['SettleAccounts_OrderNew_Items']) => {
    eventTrackingUtils.submitCL({ event_id: 'SettleAccounts_OrderNew_Items', json_param });
  },
  /** 点击事件上报-结算商品楼层数量增加点击 */
  SettleAccounts_OrderNew_AddItems: (json_param: IClickTrackParamsRecord['SettleAccounts_OrderNew_AddItems']) => {
    eventTrackingUtils.submitCL({ event_id: 'SettleAccounts_OrderNew_AddItems', json_param });
  },
  /** 点击事件上报-支付方式楼层点击 */
  SettleAccounts_OrderNew_PayWay: (json_param: IClickTrackParamsRecord['SettleAccounts_OrderNew_PayWay']) => {
    eventTrackingUtils.submitCL({ event_id: 'SettleAccounts_OrderNew_PayWay', json_param });
  },
  /** 点击事件上报-优惠券楼层点击 */
  SettleAccounts_OrderNew_Coupon: (json_param: IClickTrackParamsRecord['SettleAccounts_OrderNew_Coupon']) => {
    eventTrackingUtils.submitCL({ event_id: 'SettleAccounts_OrderNew_Coupon', json_param });
  },
  /** 点击事件上报-发票地址楼层点击 */
  SettleAccounts_OrderNew_BillAddress: () => {
    eventTrackingUtils.submitCL({ event_id: 'SettleAccounts_OrderNew_BillAddress' });
  },
  /** 点击事件上报-支付按钮点击 */
  SettleAccounts_OrderNew_Submit: (json_param: IClickTrackParamsRecord['SettleAccounts_OrderNew_Submit']) => {
    eventTrackingUtils.submitCL({ event_id: 'SettleAccounts_OrderNew_Submit', json_param });
  },
  /** 点击事件上报-无货提示弹窗点击 */
  SettleAccounts_OrderNew_Unstock: () => {
    eventTrackingUtils.submitCL({ event_id: 'SettleAccounts_OrderNew_Unstock' });
  },
  /** 点击事件上报-优惠券楼层Tab点击 */
  SettleAccounts_OrderNew_CouponLayerTab: (json_param: IClickTrackParamsRecord['SettleAccounts_OrderNew_CouponLayerTab']) => {
    eventTrackingUtils.submitCL({ event_id: 'SettleAccounts_OrderNew_CouponLayerTab', json_param });
  },
  /** 点击事件上报-优惠券楼层搜索按钮点击 */
  SettleAccounts_OrderNew_CouponLayerSearch: (json_param: IClickTrackParamsRecord['SettleAccounts_OrderNew_CouponLayerSearch']) => {
    eventTrackingUtils.submitCL({ event_id: 'SettleAccounts_OrderNew_CouponLayerSearch', json_param });
  },
}

export {
  EXPOSURE_RECORD,
  sendClickTrack,
  sendPv,
}
export type {
  IExpoTrackParamsRecord,
}