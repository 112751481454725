"use client"
import React, { useEffect, useRef, useState } from "react";
import to from "await-to-js";
import ProductList from "@/common-components-src/js/productList";
import ArrowRight from "@/common-components-src/assets/icons/arrow-right.svg";
import style from "./style.module.scss";
import newColorAxiosInstance from "@/common-components-src/api/new-color-axiosInstance"
import UKPageLoading from "@/common-components-src/components/UKPageLoading/index";
interface trackParamsType {
  RecomSku_Expo?: string
  RecomSku_Tab_Expo?: string
  RecomSku_Addcart_Expo?: string
  productClick?: (json_param: any) => void
  tabClick?: (json_param: any) => void
  addCartClick?: (json_param: any) => void
  extraParams?: Record<string, any>
}
interface productTrackParamsType {
  RecomSku_Expo?: string
  RecomSku_Addcart_Expo?: string
  productClick?: (json_param: any) => void
  addCartClick?: (json_param: any) => void
  tab_name?: string,
}
interface RecommendProps {
  sceneCode?: string //场景编码
  subSceneCode?: string //副场景编码
  mainSkus?: string //主sku 
  bulkPrice?: string //购物车凑单场景需要。 根据购物车返回的tab作为key入参例如
  c3Id?: string //三级类目id sceneCode=product_detail_page的时候需要下发，值从商祥服务端获取
  addCartFn?: Function
  onAddCartSuccess?: Function // 商卡--加车成功后--回调
  trackParams?: trackParamsType //埋点传参
}
const Recommend = ({
  sceneCode = 'home_page',
  mainSkus = '',
  bulkPrice = '',
  c3Id = '',
  addCartFn,
  onAddCartSuccess,
  trackParams,
}: RecommendProps) => {
  const [tabs, setTabs] = useState([]);
  const activeTab = useRef({
    slotId: '',
    slotName: 'Recommended for you'
  });
  const [localInfo, setLocalInfo] = useState({});
  const [page, setPage] = useState(1);
  const [products, setProducts] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [stopGetProduct, setStopGetProduct] = useState(false);//是否停止获取数据
  const [shouldShow, setShouldShow] = useState(true) // 是否显示整个推荐模块
  const productTrackParams = useRef<productTrackParamsType>({
    RecomSku_Expo: trackParams?.RecomSku_Expo,
    RecomSku_Addcart_Expo: trackParams?.RecomSku_Addcart_Expo,
    productClick: trackParams?.productClick,
    addCartClick: trackParams?.addCartClick,
    ...trackParams?.extraParams,
  });//获取埋点参数

  //获取推荐商品接口
  const getRecommendation = () => {
    const params = {
      page,
      pageSize: 10,
      sceneCode,
      subSceneCode: activeTab.current.slotId,
      mainSkus,
      bulkPrice,
      c3Id
    }
    return newColorAxiosInstance
      .post(`guide_recommendation_get`, undefined, { params: { ...params } })
      .then((res: any) => res.data.data);
  };

  //获取推荐tabList接口
  const getRecommendTabList = () => {
    const params = {
      sceneCode,
    }
    return newColorAxiosInstance
      .post(`guide_recommendation_getSlotList`, undefined, { params: { ...params } })
      .then((res: any) => res.data.data);
  };

  //初始化获取tablist
  const initTab = async () => {
    setLoading(true);
    try {
      const [err, data] = await to(getRecommendTabList());
      if (err) {
        return
      } else {
        if (data && data.slotScopeVOS && data.slotScopeVOS.length > 0)
          setTabs(data?.slotScopeVOS.map((x: any, i: number) => {
            x.slotId = x.slotId || ''
            if (i === 0) {
              activeTab.current = {
                slotId: x.slotId,
                slotName: x.slotName,
              }
            }
            return x
          }))
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  }

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const [err, data] = await to(getRecommendation());
      if (err) {
        setProducts([]);
      } else {
        const { paragraphs = [], localInfo } = data || {};

        if (paragraphs.length) {
          setProducts((prev: any) => (page === 1 ? [...paragraphs] : [...prev, ...paragraphs]));
          setStopGetProduct(paragraphs.length < 10);
        } else {
          setStopGetProduct(true);
        }
        //判断第一个tab的第一页没有数据，则隐藏推荐模块
        setShouldShow(!(page === 1 && activeTab.current.slotId === '' && paragraphs.length === 0))
        setLocalInfo(localInfo)
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleMore = () => {
    setPage((prev) => prev + 1);
  };
  const handleTabClick = (item: any) => {
    if (trackParams?.tabClick) {
      trackParams?.tabClick({ tab_name: item.slotName, p: '-100', broker_info: '-100' })
    }
    if (activeTab.current.slotId === item.slotId) return;
    activeTab.current = item;
    setProducts([]); // 清空当前产品列表
    setPage(1); // 重置页码
    setStopGetProduct(false);
    fetchProducts(); // 获取新 Tab 的产品
  }

  const getTabExpoJSonParam = (tab_name: string) => {
    const JSonParam = {
      broker_info: '-100',
      tab_name,
      p: '-100'
    }
    return JSON.stringify(JSonParam)
  }

  useEffect(() => {
    if (page > 1) {
      fetchProducts(); // 当页码变化时加载更多产品
    }
  }, [page]);

  useEffect(() => {
    initTab()
    fetchProducts()
  }, []);

  //判断是Recommended for you 这个tabs如果是空则不展示推荐
  if (tabs.length === 0 || !shouldShow) return null;

  return (
    <div className={style.jdi_recommend_wrapper}>
      <div className={style.tab_headers}>
        <div className={style.tab_header_wrapper}>
          {tabs.map((tab: any) => (
            <div
              key={tab.slotId}
              exptag={`exp|${trackParams?.RecomSku_Tab_Expo}`}
              data-exptag-config={
                JSON.stringify({
                  useClick: false, // 是否用点击接口,默认使用曝光接口   exptag-config-useClick
                  stay_duration: 0.3, // 曝光时长    exptag-config-stay_duration
                  repeated: false, // 是否可重复曝光  exptag-config-repeated
                  area_rate: 0.5  // 漏出比例  exptag-config-area_rate
                })
              }
              data-exptag-json-param={getTabExpoJSonParam(tab.slotName)}
              className={`${style.tab_header} ${activeTab.current.slotId === tab.slotId ? style.active : ""}`}
              onClick={() => handleTabClick(tab)}
            >
              {tab.slotName}
            </div>
          ))}
        </div>
      </div>
      <ProductList
        localInfo={localInfo}
        products={products}
        addCartFn={addCartFn}
        onAddCartSuccess={onAddCartSuccess}
        productTrackParams={{ ...productTrackParams.current, tab_name: activeTab.current.slotName }}
      />
      {
        loading ?
          <UKPageLoading className={style.loading} text="Loading..." />
          :
          stopGetProduct ?
            <div className={style.reached}>
              You&#39;ve reached the end
            </div> :
            <button className={style.more_btn} onClick={handleMore}>
              View more
              <ArrowRight style={{ marginLeft: 2 }} width={14} height={14} />
            </button>
      }
    </div>
  );
};

export default Recommend;
