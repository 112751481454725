import "../index.scss";
import { useState } from "react";
import MessageList from "./messageList";
import CloseIcon  from "../assets/close-icon.svg";
import VectorIcon  from "@/common-components-src/assets/icons/vector-up-icon.svg";

interface Props{
  type: number,
  title: string,
  onBack: (isModify: boolean) => void;
  onClose: () => void;
}

export default function MessageDetail({ type, title, onBack, onClose }: Props) {
  const [isModify, setIsModify] = useState<boolean>(false);
  
  return (
    <div className="message-detail message-frame">
      <div className="detail-title">
        <VectorIcon className="vector cursor hover" onClick={()=>{onBack(isModify)}} />
        <span>{title}</span>
        <CloseIcon className="cursor hover" onClick={onClose}/>
      </div>
      <div className="message-container">
        <MessageList type={type} onModify={()=>{setIsModify(true)}} />
      </div>
    </div>
  );
};
