/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import { Button, TextField, MenuItem, InputAdornment, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import to from "await-to-js";
import { giftQuery } from "@/common-components-src/api/gift/gift.api";
import { NewGiftCouponDto } from "@/common-components-src/api/gift/gift.interface";
import {
  queryPostCode,
} from "@/common-components-src/api/address/address.api";
import AddressClear from "@/common-components-src/js/address/icons/AddressClear.svg";
import CloseIcon from "@/common-components-src/assets/icons/CloseIcon.svg";
import { setArea, curIsLocal } from "@/utils/addressUtils";
import { curPostCodeOrAddress } from "@/common-components-src/js/utils/addressRequestUtils";
import { getAddressStore } from ".././address/AddressStore";
import JDILogin from "@/common-components-src/js/newlogin/index.newlogin";
import callAfterLogin from "@/common-components-src/js/utils/callAfterLogin";
import TruncatedName from "./components/truncated";
import { EXPOSURE_RECORD, sendClickTrack } from "@/tracks/25579";
import styles from "./index.module.scss";

interface DataItemProps {
  loadCurrentOrder?: (params?: any) => void;
  isRegisterLogin?: boolean; //如果是注册登录入口，返回的优惠券信息打开弹窗
  isOpenModal?: boolean; //个人中心地址入口
  closeModalCallback?: () => void; //弹窗关闭的回调方法
}

const GiftModal: React.FC<DataItemProps> = ({ closeModalCallback }) => {
  const addressStore = getAddressStore();
  const [couponList, setCouponList] = useState<NewGiftCouponDto[] | null>(null);
  const [isLogin, setIsLogin] = useState(false);
  const [isLocal, setIsLocal] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isFisrstAnim, setIsFisrstAnim] = useState(false); // 第一步动画
  const [isCoupons, setIsCoupons] = useState(false); // 第二步展示优惠券
  const [userInfo, setUserInfo] = useState<any>(); //用户信息
  const [activityTitle, setActivityTitle] = useState("");//弹窗 title

  const [formData, setFormData] = useState({
    postCode: "",
  });
  const [errors, setErrors] = useState({
    postCodeError: "",
  });

  useEffect(() => {
    loginStatus();
    getCurLocalStatus();
  }, []);

  const loginStatus = async () => {
    const { isLoggedIn, userInfo } = await JDILogin.getInstance().getUserInfo();
    setUserInfo(userInfo);
    setIsLogin(isLoggedIn);
    getGift(userInfo);
  };

  const getCurLocalStatus = async () => {
    const isLocal = await curIsLocal();
    setIsLocal(isLocal);
  };

  const openFirstAnim = () => {
    // 这里开始执行动画
    setIsFisrstAnim(true);
    setTimeout(() => {
      setIsFisrstAnim(false);
      setIsCoupons(true);
      saveDate();
    }, 1000);
  };

  const getGift = async (user: any) => {
    const [err, result] = await to(giftQuery());
    if (err) {
      setCouponList(null);
    } else {
      console.log("result---------GiftModal", result);
      if (result?.data?.activityTitle) {
        setActivityTitle(result?.data?.activityTitle);
      }
      const list = result?.data?.couponList;
      if (list && list.length > 0 && !isLocalCouponModel(user)) {
        setIsOpenModal(true);
        openFirstAnim();
        setCouponList(list);
      } else {
        setCouponList(null);
      }
    }
  };

  const isLocalCouponModel = (user: any) => {
    if (user?.pin) {
      return false;
    }
    // 获取当前日期
    const currentDate = new Date().toISOString().split("T")[0]; // YYYY-MM-DD
    const lastShown = localStorage.getItem('giftModalShown');
    // 如果日期不同，清除缓存
    if (lastShown) {
      const date = JSON.parse(lastShown)?.date;
      const pin = JSON.parse(lastShown)?.pin;
      console.log("lastShown111111111111", lastShown, date, user?.pin, pin, pin !== user?.pin);
      if (date !== currentDate) {
        localStorage.removeItem('giftModalShown');
        return false;
      }
      return true;
    }
    return false;
  };

  const countryCodes = [
    {
      value: "USD",
      label: "UK",
    }
  ];

  const handlePostCodeBlur = async () => {
    if (!formData || !formData.postCode) {
      setErrors({
        ...errors,
        postCodeError: "Please enter your postcode."
      });
      return;
    }
    const postCodeValue = formData.postCode.replace(/\s+/g, ''); // 去掉所有空格
    const postCodeRegex = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;
    if (!postCodeRegex.test(postCodeValue)) {
      setErrors({
        ...errors,
        postCodeError: "Please enter a valid postcode, such as: SW1A 1AA, EC1A 1BB"
      });
      return;
    }
    setErrors({
      ...errors,
      postCodeError: ""
    });
    requestPostCodeApply(postCodeValue);
  };

  const requestPostCodeApply = async (postCodeValue: string) => {
    // 请求postcode校验接口
    if (!postCodeValue) {
      setErrors({
        ...errors,
        postCodeError: "Please enter your postcode."
      });
      return;
    }
    // 先将formData中的postCode字段的值转为大写
    const [err, result] = await to(
      queryPostCode({ postCode: postCodeValue ? postCodeValue.toUpperCase() : postCodeValue }),
    );
    if (result?.code === "200" && result?.data) {
      setFormData({ ...formData, postCode: result?.data?.postCode });
      setErrors({ ...errors, postCodeError: "" });
      // 同时更新缓存
      setArea(result?.data)
      // 未登录场景下 postcode是一样的
      const postCode = await curPostCodeOrAddress();
      addressStore.setPostCode(postCode);
      addressStore.setDetailPostCode(postCode);
    } else {
      if (result?.msg) {
        setErrors({ ...errors, postCodeError: result?.msg });
      }
    }
  }

  const saveDate = () => {
    // 记录到 localStorage，包括当前日期
    console.log("lastShown111111111111userInfo-----", userInfo);
    const currentDate = new Date().toISOString().split("T")[0]; // YYYY-MM-DD
    localStorage.setItem('giftModalShown', JSON.stringify({ shown: true, date: currentDate, pin: userInfo?.pin }));
  }

  const handleClose = () => {
    saveDate();
    setIsOpenModal(false);
  };

  const gotoLogin = () => {
    handleClose();
    callAfterLogin(async () => {
      const [err, result] = await to(giftQuery());
      console.log("result---------GiftModal", result);
    });
  };

  const track = () => {
    const coupons: any[] = [];
    if (couponList && couponList.length > 0) {
      couponList.forEach((item: any) => {
        coupons.push({
          couponId: item?.couponId,
          batchId: item?.batchId || "-100",
          couponTag: "0", // 0 新人礼包
          use_threshold: item?.quota,
          couponType: item?.couponType === 1 ? "1" : "2",
          couponSave: item?.discount,
        })
      })
    }
    return {
      coupon: coupons,
      is_login: isLogin ? "1" as const : "0" as const,
    };
  };

  const cardPostCodeFloor = () => {
    return (
      <div style={{ display: "flex" }}>
        <TextField
          id="outlined-select-currency"
          select
          sx={{ width: 100, background: "#fff", borderRadius: "4px", height: 40 }}
          // label="Post Code"
          size="small"
          slotProps={{
            input: {
              readOnly: true,
              startAdornment: (
                <img
                  width={18}
                  height={12}
                  style={{ marginRight: "4px" }}
                  src="https://st.joy-sourcing.com/website/other/avatar.png"
                  alt="icon" // 添加 alt 属性以提高可访问性
                />
              ),
            },
          }}
          value={countryCodes[0].value}
        >
          {countryCodes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="outlined-basic"
          label="Enter Postcode"
          variant="outlined"
          size="small"
          onBlur={handlePostCodeBlur}
          error={!!errors.postCodeError}
          helperText={errors.postCodeError}
          value={formData.postCode}
          onFocus={() => {
            sendClickTrack.NewCustomerGift_GiftLayer({ postcode: formData?.postCode, clickPos: "1", ...track()})
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFormData({ ...formData, postCode: event.target.value });
          }}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  {formData.postCode && (
                    <IconButton onClick={() => {
                      setFormData({ ...formData, postCode: "" });
                      setErrors({
                        ...errors,
                        postCodeError: ""
                      });
                    }}>
                      <AddressClear />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            },
            htmlInput: {
              maxLength: 20
            }
          }}
          sx={{ width: 310, marginLeft: "8px", background: "#fff", borderRadius: "4px" }}
        />
      </div>
    );
  };

  return (
    <>
      {isOpenModal && <div className={styles.container}>
        {isFisrstAnim && <div
          className={styles.giftFirst}
        // className={`${styles.giftFirst} ${isAnimating? styles.giftFirstAnim : ''}`}
        ></div>}
        {!isFisrstAnim && isCoupons && <div
          className={styles.box}
          style={{ height: isLocal ? "500px" : "548px" }}
          exptag={"exp|" + EXPOSURE_RECORD.NewCustomerGift_GiftLayerExpo}
          data-exptag-config='{"useClick": false, "stay_duration": 0.3, "repeated":false, "area_rate": 0.01}'
          data-exptag-json-param={JSON.stringify(track())}
        >
          <span className={styles.title}>{activityTitle}</span>
          {!isLocal && cardPostCodeFloor()}
          {/* 优惠券数量 */}
          <div className={styles.boxVouchers} style={{ height: errors.postCodeError ? "300px" : "338px" }}>
            {couponList && couponList.length > 0 && couponList.map((coupon, index) => (
              <div className={styles.voucher}
                style={{
                  border: `0.5px solid ${coupon?.couponType === 2 ? "#ACE4B4" : "#ff9fa7"}`,
                  borderLeft: `4px solid ${coupon?.couponType === 2 ? "#ACE4B4" : "#ff9fa7"}`,
                  minHeight: `${coupon?.couponType === 2 ? "77px" : "96px"}`,
                }}
                key={index}>
                <div className={styles.nominal}>
                  <span style={{ color: coupon?.couponType === 2 ? "#269636" : "#CC0C1C" }}>£{coupon.discount}</span>
                  <span className={styles.leftOff} style={{ color: coupon?.couponType === 2 ? "#269636" : "#CC0C1C" }}>
                    {coupon?.couponType === 2 ? "Shipping off" : "Off"}</span>
                  {coupon?.couponType !== 2 &&
                    <span className={styles.order}>Over £{coupon.quota}
                    </span>
                  }
                </div>
                <div className={styles.dayFlex}>
                  <span className={styles.day}>Valid until {coupon.endTime}</span>
                </div>
                {coupon?.couponType !== 2 &&
                  <>
                    <div className={styles.line}></div>
                    <TruncatedName text={coupon?.limitStr} index={index} track={{ postcode: formData?.postCode, clickPos: "1", ...track()}}/>
                  </>}
              </div>
            ))}
          </div>
          <div className={styles.bottomFloat}>
            <Button className={styles.buttonCheck} onClick={() => {
              isLogin ? handleClose() : gotoLogin();
              sendClickTrack.NewCustomerGift_GiftLayer({ postcode: formData?.postCode, clickPos: "2", ...track()})
            }}>
              Go shopping
            </Button>
            {!isLogin && <div className={styles.tips}>
              Coupons confirmed after sign in
            </div>}
          </div>
          <CloseIcon className={isLocal ? styles.close : styles.closeTop} onClick={() => {
            handleClose();
            sendClickTrack.NewCustomerGift_GiftLayer({ postcode: formData?.postCode, clickPos: "0", ...track()})
          }} />
        </div>}
      </div>}
    </>
  );
};

export default GiftModal;
