import { useEffect, useState } from 'react';
import { IWindow } from "@/common-components-src/types/global";

export function useAppI18n() {
  const [t, setT] = useState<((key: string, options?: any) => string) | null>(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const appI18n = (window as unknown as IWindow).appI18n;
      if (appI18n) {
        setT(() => appI18n.t);
      }
    }
  }, []);

  return { t };
}
