// 埋点页面链接：https://stream.jd.com/ddrx/#/requirement/25421
// WARNING!!! 请勿手动修改此文件，如需修改请在子午线平台变更后，执行npm run track-init 25421 
import { eventTrackingUtils } from '@/utils/eventTrackingUtils'
interface IExpoTrackParamsRecord {
  /** 页尾邮件订阅曝光 */
Footer_Email_Subscription_Expo: undefined
  /** 页尾应用商店曝光 */
Footer_AppStore_Expo: undefined
  /** 页尾社媒分享曝光 */
Footer_Social_Media_Expo: undefined
}

const EXPOSURE_RECORD: Readonly<Record<keyof IExpoTrackParamsRecord,string>> = Object.freeze({
/** 页尾邮件订阅曝光 */
  Footer_Email_Subscription_Expo:'Footer_Email_Subscription_Expo',
/** 页尾应用商店曝光 */
  Footer_AppStore_Expo:'Footer_AppStore_Expo',
/** 页尾社媒分享曝光 */
  Footer_Social_Media_Expo:'Footer_Social_Media_Expo',
})

interface IClickTrackParamsRecord {
  /** 页尾邮件订阅点击 */
Footer_Email_Subscription: undefined
  /** 页尾应用商店点击 */
Footer_AppStore: {
/** 按钮名称  */
button_name: string}
  /** 页尾社媒分享点击 */
Footer_Social_Media: {
/** 按钮名称  */
button_name: string}
}

const sendClickTrack = {
  /** 点击事件上报-页尾邮件订阅点击 */
Footer_Email_Subscription: () => {
    eventTrackingUtils.submitCL({event_id:'Footer_Email_Subscription'});
  },
  /** 点击事件上报-页尾应用商店点击 */
Footer_AppStore: (json_param: IClickTrackParamsRecord['Footer_AppStore']) => {
    eventTrackingUtils.submitCL({event_id:'Footer_AppStore',json_param});
  },
  /** 点击事件上报-页尾社媒分享点击 */
Footer_Social_Media: (json_param: IClickTrackParamsRecord['Footer_Social_Media']) => {
    eventTrackingUtils.submitCL({event_id:'Footer_Social_Media',json_param});
  },
}

export {
  EXPOSURE_RECORD,
  sendClickTrack,
}
export type {
  IExpoTrackParamsRecord,
}