// 1=订单消息（服务消息） 2=账号消息（系统消息）3=促销消息（活动消息）
export enum MessageTypeEnum {
  orders = 1,
  mentions = 2,
  campaign = 3,
  services = 4,
  aftersale = 5,
  pricedrop = 6,
  flashsale = 7,
}

export enum MessageSubTypeEnum {
  ORDER_PAY_COMPLETED = "1001", //订单支付完成
  ORDER_SHIPPED = "1002", //订单已发货
  ORDER_COMPLETE = "1003",  //订单完成
  ORDER_CANCEL = "1004",  //订单取消
  ORDER_CANCEL_FAILED = "1005", //订单取消失败
  PRESALE_CANCEL_SUCCESS = "2001",  //售前取消退款成功
  AFTERSALE_CANCEL_SUCCESS = "2002",  //售后取消退款成功
  AFTERSALE_APPLY_SUCCESS = "2003",  //售后申请成功
  AFTERSALE_APPLY_FAIL = "2004",  //售后申请失败
  HAPPY = "6001", //月黑风高
  PRICE_DROP = "7001", //加车收藏降价
  ACCOUNT = "8001", //账户
  REVIRE = "8002",  //评价
  CAMPAIGN = "9001",  //促销
}

export interface MessageItem {
  msgId: string;
  simgPath: string;
  title: string;
  formatTime: string;
  content: string;
  acountType: number;
  accountName: string;
  redPoint: boolean;
  extraAttribute?: {
    msgSubType: string;
    sImgPath: string;
    icon: string;
    tipHtml: string;
    orderId: number;
    landPageUrl: string;
    landPageUrlForM: string;
    promoId: string;
    skuId: string;
    skuName: string;
    commentId: string;
    parentReplyId: string | number;
  };
}

export interface MessageListResponse {
  data: MessageItem[];
  total: number;
}

export interface DeleteResultRes {
  data: boolean;
}

export interface MessagePreviewResponse {
  topListMessageMap: Record<number, MessageTitleItem>;
  messageList: MessageTitleItem[];
}

export interface MessageTitleItem {
  noticeNum: number;
  msgEntityVo: MessageItem;
  msgEntityVoList: MessageItem[];
  redPoint: boolean;
  accountType: number;
  replaceContent: string;
}

export interface MessageEnterace {
  unReadedCount: number;
  isRedPoint: boolean;
}
