import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef } from "react";
import { Button, DialogTitle, IconButton, DialogContent } from "@mui/material";
import { styled } from "@mui/system";
import UKDialog from "@/common-components-src/components/UKDialog/index";
import SettingList from "./components/settingList/index";
import CloseIcon from "@mui/icons-material/Close";
import FooterData from "@/common-components-src/const/cookieRoot";
import loginConfig from "@/constants/loginConfig";
import { getOrSetSwitches } from "@/common-components-src/api/root/root.api";
import cookieUtil from "@/utils/cookieUtils";
import JDILogin from "@/common-components-src/js/newlogin/index.newlogin";

const PersonalisedManage = forwardRef((props, ref) => {
  const { PersonalisedSettingList } = FooterData;
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [isLogin, setIsLogin] = useState<boolean>(false); // 是否登录
  const settingListRef = useRef<{ getPersonalised: any }>();

  useEffect(() => {
    getLoginStatus();
  }, []);

  useImperativeHandle(ref, () => ({
    setIsOpen: setIsOpenDialog,
  }));

  const getLoginStatus = async () => {
    const { isLoggedIn } = await JDILogin.getInstance().getUserInfo();
    setIsLogin(isLoggedIn);
  };

  const handleClose = () => {
    setIsOpenDialog(false);
  };

  const handleConfirmChoies = async () => {
    if (settingListRef.current) {
      const personalised = settingListRef.current?.getPersonalised();
      const scenes: any[] = [];
      PersonalisedSettingList.forEach((item, index) => {
        scenes.push({ scene: index, switchStatus: personalised[item.key] });
      });
      setIsOpenDialog(false);
      if (isLogin) {
        await getOrSetSwitches({ scenes });
      } else {
        cookieUtil.setCookie("personalised_consent", JSON.stringify(personalised), 365, loginConfig.POSTFIX_DOMAIN);
      }
    }
  };

  return (
    <UKDialog
      open={isOpenDialog}
      onClose={handleClose}
      showCloseBtn={false}
      sx={{
        "&": {
          zIndex: 2002,
        },
        "& .MuiDialog-paper": {
          width: "520px",
          maxWidth: "none",
          borderRadius: "12px",
          zIndex: 2003,
        },
        "& .css-1cennmq-MuiBackdrop-root-MuiDialog-backdrop": {
          zIndex: 2002,
        },
      }}
    >
      <DialogTitle
        sx={{
          height: "20px",
          padding: "0 24px",
          fontSize: "16px",
          lineHeight: "20px",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        Personalised settings
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <CustomerDialogContent>
        We are excited to provide you with a personalized shopping experience and interaction. By simply adjusting the toggle below, you can customize the type
        of recommender system to receive non-personalized content and information. Feel free to change this anytime you wish. You can find further details in
        our{" "}
        <a href="/help/privacy-policy" target="_self">
          privacy policy.
        </a>
        <SettingList ref={settingListRef} isLogin={isLogin} />
      </CustomerDialogContent>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: "12px",
          boxSizing: "border-box",
          padding: "24px",
        }}
      >
        <Button
          size="small"
          variant="contained"
          disableElevation
          sx={{
            color: "#FFF",
            height: "32px",
            backgroundColor: "#CC0C1C",
            textTransform: "none",
            border: "none",
            lineHeight: "18px",
            fontSize: "14px",
            padding: "7px 25px",
            borderRadius: "4px",
          }}
          onClick={handleConfirmChoies}
        >
          Confirm my choices
        </Button>
      </div>
    </UKDialog>
  );
});

const CustomerDialogContent = styled(DialogContent)({
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "18px",
  color: "#888B94",
  "& a": {
    color: "#1869F5",
    textDecoration: "underline",
  },
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#fff",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0, 0, 0, 0.14)",
    borderRadius: "4px",
    cursor: "pointer",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.28)",
  },
});

export default PersonalisedManage;
