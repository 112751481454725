import ProductCard_1 from './components/type1/index'
import ProductCard_2 from './components/type2/index'
import ProductCard_3 from './components/type3/index'

import { productData_1, productData_2 } from './index.interface'

interface PropsType {
  productData: productData_1 | productData_2
}

export default function ProductCardV2({ productData }: PropsType) {
  const { type } = productData

  //首页营销通用商卡
  if (type === '1') {
    return (
      <ProductCard_1 productData={productData}></ProductCard_1>
    )
    //feed流、落地页等
  } else if (type === '2') {
    return (
      <ProductCard_2 productData={productData}></ProductCard_2>
    )
    //搜索
  } else if (type === '3') {
    return (
      <ProductCard_3 productData={productData}></ProductCard_3>
    )
  } else {
    return (
      <div>error</div>
    )
  }

}
