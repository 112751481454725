// 埋点页面链接：https://stream.jd.com/ddrx/#/requirement/25321
// WARNING!!! 请勿手动修改此文件，如需修改请在子午线平台变更后，执行npm run track-init 25321 
import { eventTrackingUtils } from '@/utils/eventTrackingUtils'
interface IExpoTrackParamsRecord {
  /** 新建地址按钮曝光 */
NeworderReceiverList_NewAddress_Expo: {
/** 来源页面  */
from_page: string}
}

const EXPOSURE_RECORD: Readonly<Record<keyof IExpoTrackParamsRecord,string>> = Object.freeze({
/** 新建地址按钮曝光 */
  NeworderReceiverList_NewAddress_Expo:'NeworderReceiverList_NewAddress_Expo',
})

interface IClickTrackParamsRecord {
  /** 新建地址保存按钮 */
NeworderReceiverList_NewAddress: {
/** 来源页面  */
from_page: string}
}

const sendClickTrack = {
  /** 点击事件上报-新建地址保存按钮 */
NeworderReceiverList_NewAddress: (json_param: IClickTrackParamsRecord['NeworderReceiverList_NewAddress']) => {
    eventTrackingUtils.submitCL({event_id:'NeworderReceiverList_NewAddress',json_param});
  },
}

export {
  EXPOSURE_RECORD,
  sendClickTrack,
}
export type {
  IExpoTrackParamsRecord,
}