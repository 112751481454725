import ErrorFlyIcon from "@/common-components-src/assets/icons/error-fly-icon.png";
import styles from "./style.module.scss";

type Props = {
  // className: string;
  errorMessge?: string;
  style: any;
  // onEmit: () => void;
};

export const ErrorTryAgain = ({ style = {}, errorMessge = "Error, please try again" }: Props) => {
  return (
    <div className={styles.errorContainer} style={style}>
      <img src={ErrorFlyIcon.src} width={120} height={120} />
      <p className={styles.showMessage}>{errorMessge}</p>
      <div
        className={styles.btn}
        onClick={() => {
          location.reload();
        }}
      >
        Try again
      </div>
    </div>
  );
};
