import "../index.scss"
import { useEffect, useRef, useState } from "react";
import UKLoadingIcon from "@/common-components-src/components/UKLoadingIcon";
import UKPageNothing from "@/common-components-src/components/UKPageNothing";
import UKPageLoading from "@/common-components-src/components/UKPageLoading";
import { CommonItem, CampaignItem, PriceDropItem } from "./messageItem";
import { MessageItem, MessageTypeEnum, MessageSubTypeEnum } from "@/common-components-src/api/message/message.interface";
import { getMsgListApi, handleBatchDeleteApi } from "@/common-components-src/api/message/message.api";
import UKPageError from "@/common-components-src/components/UKPageError";
import to from "await-to-js";
import UKToast from "@/common-components-src/components/UKToast/index";
import { TitleConstData } from "./titleItem";

export default function MessageList({ type, onModify }: {type: number, onModify: () => void}) {

  const [isMsgLoading, setIsMsgLoading] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget
    if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
      handleMessageLoading();
    }
  };

  const handleMessageLoading = async () => {
    if(currentPage >= totalPage || isMsgLoading){
      return
    }
    setIsMsgLoading(true);
    await fetchMsgList(true);
    setIsMsgLoading(false);
  }

  const scrollToBottom = () => {
    if(divRef.current && divRef.current.parentElement){
      divRef.current.parentElement.scrollTop = divRef.current.parentElement.scrollHeight;
    }
  }

  const pageSize = 10;
  const [error, setError] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState<MessageItem[]>([]);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

  const initMessage = async () => {
    setIsPageLoading(true);
    await fetchMsgList();
    setIsPageLoading(false);
  };

  const fetchMsgList = async (isLoading: boolean = false) => {
    const curPage = isLoading ? currentPage + 1 : 1;
    const [error, res] = await to(getMsgListApi({
      accountType: type,
      pageSize: pageSize,
      currentPage: curPage,
    }));
    if(error){
      setError(true);
      setData([]);
    }
    else if(res && res.code == "200"){
      const { data: dataList = [], total } = res.data || {};
      const msgData = isLoading ? [...data, ...dataList] : dataList;
      setError(false);
      setData(msgData);
      setCurrentPage(curPage);
      setTotalPage((total || 0) / pageSize);
    }
    else{
      UKToast.show({
        content: res?.msg || "Oops! Something went wrong. Please check back later.",
        icon: "warning",
      });
    }
  }

  const handleMessageDelete = (index: number, msgId: string) => {
    if(index == 0){
      onModify();
    }
    onMessageDelete(msgId);
  }

  const onMessageDelete = async (msgId: string) => {
    const res = await handleBatchDeleteApi({ msgId });
    if (res) {
      UKToast.show({
        content: "Successfully deleted",
        icon: "success",
      });
      setData([]);
      initMessage();
    }
    else{
      UKToast.show({
        content: "An error occured while trying to delete, please try again.",
        icon: "warning",
      });
    }
  };

  useEffect(() => {
    initMessage();
  }, [type]);

  useEffect(() => {
    if(isMsgLoading){
      scrollToBottom();
    }
  }, [isMsgLoading]);

  if (error) {
    return <UKPageError
      style={{ marginTop: "180px" }}
      clickTryAgain={() => initMessage()}
      text="Loading up, please try again"
      btnText="Try again"
    />
  }
  
  if(isPageLoading){
    return <UKPageLoading style={{height: "100%"}}/>
  }

  if(!data || !data?.length){
    return <UKPageNothing style={{marginTop: "250px"}} />
  }

  return (
    <div ref={divRef} className="message-list" onScroll={handleScroll}>
      { data?.map((item: MessageItem, index: number) => {
          const params = {
            msgType: TitleConstData[type].title,
            msgId: item?.msgId || "",
            orderId: String(item?.extraAttribute?.orderId) || "",
            promId: item?.extraAttribute?.promoId || "",
            pos: index,
          }
          const data = { item: item, type: type, params: params, onDelete: (msgId: string)=>{handleMessageDelete(index, msgId)} }
          const msgType = item?.extraAttribute?.msgSubType;
          if(msgType){
            switch(msgType){
              case MessageSubTypeEnum.CAMPAIGN:
                return <CampaignItem key={index} data={data}/>
              case MessageSubTypeEnum.PRICE_DROP:
                return <PriceDropItem key={index} data={data}/>
              default:
                return <CommonItem key={index} data={data}/>
            }
          }
          else{
            switch(type){
              case MessageTypeEnum.campaign:
                return <CampaignItem key={index} data={data}/>
              case MessageTypeEnum.pricedrop:
                return <PriceDropItem key={index} data={data}/>
              default:
                return <CommonItem key={index} data={data}/>
            }
          }
      })}
      { isMsgLoading && (
        <div className="loading">
          <UKLoadingIcon className="loading-icon" type="black" />
        </div>
      )}
      { currentPage >= totalPage && data && data.length >= pageSize && (
        <div className="loading reach-bottom">
          {"You've reached the end"}
        </div>
      )}
    </div>
  );
}
