/**
 * formatPrice: 格式化价格为带有货币符号的字符串。
 * formatPrice(1000) -> "£1,000.00"
 * @param amount - 价格的数值
 * @param currency - 货币符号，默认为 GBP
 * @param currencyDisplay - 货币符号的显示方式，默认为 symbol，也可以用code
 * @returns 格式化后的货币字符串
 */
export function formatPrice(amount: number, currency = "GBP", currencyDisplay: "symbol" | "narrowSymbol" | "code" | "name" = "symbol") {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency,
    currencyDisplay,
  }).format(amount);
}

/**
 * 将数值格式化为带千位分隔符和两位小数的字符串，不含货币符号。
 *
 * @param {number} amount - 要格式化的数值金额。
 * @returns {string} 格式化后的字符串，包含千位分隔符并固定为两位小数（例如："1,234.56"）。
 *
 * @example
 * formatPriceWithoutSymbol(1234.5);   // "1,234.50"
 * formatPriceWithoutSymbol(1234567);  // "1,234,567.00"
 */
export function formatPriceWithoutSymbol(amount: number) {
  return new Intl.NumberFormat("en-GB", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
}

/**
 * 将数值格式化为带千位分隔符和两位小数的字符串，不含货币符号。
 *
 * @param {number} n - 要格式化的数字。
 * @returns {string} 格式化后的字符串，包含千位分隔符（例如："11,234"）。
 *
 * @example
 * formatInteger(1234);   // "1,234"
 * formatInteger(1234567);  // "1,234,567"
 */
export function formatInteger(n: number): string {
  return new Intl.NumberFormat("en-GB", {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(n);
}

/**
 * 将金额四舍五入到两位小数
 * roundToTwoDecimals(19.999) -> 20.00
 * @param amount - 需要四舍五入的金额
 * @returns 四舍五入后的金额
 */
export function roundToTwoDecimals(amount: number) {
  return Math.round(amount * 100) / 100;
}
