import * as T from "@/common-components-src/api/login/index.interface";
import newColorAxiosInstance from "@/common-components-src/api/new-color-axiosInstance";

export const getCart = async (requestData: any): Promise<T.Response<string>> => {
  return newColorAxiosInstance
    .post<T.Response<any>>("trade_cart_get", undefined, { params: { ...requestData }})
    .then((response: any) => response.data)
    .catch((err: any) => err)
};

export const getPageAllData = async (requestData: any): Promise<T.Response<string>> => {
  return newColorAxiosInstance
    .post<T.Response<any>>("guide_homepage_getPageAllData", undefined, { params: { ...requestData }})
    .then((response: any) => response.data)
    .catch((err: any) => err)
};

export const cms_rootCateListAll = async (requestData: any): Promise<T.Response<any>> => {
  return newColorAxiosInstance
    .get<T.Response<any>>("guide_catalog_getRootCates", { params: { ...requestData }})
    .then((response) => response.data)
    .catch((err: any) => err)
};

export const getHotWordsApi = async (requestData: any): Promise<T.Response<any>> => {
  return newColorAxiosInstance
    .post<T.Response<any>>("guide_search_getHotWords",undefined, { params: { ...requestData }})
    .then((response) => response.data)
    .catch((err: any) => err)
};


export const getSuggestWordsApi = async (requestData: any): Promise<T.Response<any>> => {
  return newColorAxiosInstance
    .post<T.Response<any>>("guide_search_getSuggestWords",undefined, { params: { ...requestData }})
    .then((response) => response.data)
    .catch((err: any) => err)
};
